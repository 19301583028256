import { useSelector } from 'react-redux';

import { RootState } from '../store/configureStore';
import { useEffect, useState } from 'react';

export function getSelectorByKey<K extends keyof RootState>(key: K): RootState[K] {
  return useSelector((state: RootState) => state[key]);
}

export const useIsMobileSafari = () => {
  const [isMobileSafariOrChrome, setIsMobileSafariOrChrome] = useState(false);

  useEffect(() => {
    const ua = navigator.userAgent || navigator.vendor || (window as any).opera;

    // Check if the device is iOS
    const isIOS = /iPad|iPhone|iPod/.test(ua) && !(window as any).MSStream;

    // Check if the browser is Safari but not Chrome, Opera, Edge, or Firefox on iOS
    const isSafari = isIOS && /Safari/.test(ua) && !/CriOS|OPiOS|EdgiOS|FxiOS/.test(ua);

    // Check if the browser is Chrome on iOS
    const isChrome = isIOS && /CriOS/.test(ua);

    // Check for iPad running iOS 13+ which returns "MacIntel" in navigator.platform
    const isIPad = isIOS && navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;

    // Check if the device is actually a touch device
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    // Final determination of Mobile Safari or Chrome
    setIsMobileSafariOrChrome((isSafari || isChrome || isIPad) && isTouchDevice);
  }, []);

  return isMobileSafariOrChrome;
};
