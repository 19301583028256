import { MyImages } from 'cores/components';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import { generatePathHelpForHomePage } from 'helper/path';
import { POST_TAB_REQUEST } from 'constants/tabs';
import Switch from 'react-switch';
import { hexToRgba } from 'helper/rgba';
import { SPOT_QRTABLE } from 'constants/spot';
type TOrderSummaryHeaderProps = {
  isPaid: boolean;
  setIsPaid: (value: boolean) => void;
  spotType: number;
  paidOrdersCount: number;
  unPaidOrdersCount: number;
};
const OrderSummaryHeader = ({
  isPaid,
  setIsPaid,
  spotType,
  paidOrdersCount,
  unPaidOrdersCount,
}: TOrderSummaryHeaderProps) => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const dispatch = useAppDispatch();
  const { outletId, orgId } = useParams();
  const { detail } = useAppSelector((state) => state.organization);
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';

  const { t } = useTranslation();
  const backtoHome = () => {
    dispatch({ type: POST_TAB_REQUEST, payload: 'order' });

    if (outletId && orgId) {
      const path = generatePathHelpForHomePage('/', { outletId, orgId }, search);
      navigate(path.pathname + path.search);
    }
  };
  return (
    <div
      className="order-summary-header"
      style={{
        padding: '10px 15px',
      }}
    >
      {/* <div className="flex-row space-between">
        <MyImages src="/assets/icons/back.svg" onClick={() => navigate(-1)} />
        <MyImages src="/assets/icons/menu-hamburger.svg" />
      </div> */}
      <div className="mt-28 flex-row space-between">
        <div
          className="order-summary-label"
          style={{
            display: 'flex',
            gap: '10px',
          }}
          onClick={() => backtoHome()}
        >
          <img src="/assets/icons/back.svg" alt="" />
          {t('order_history')}
        </div>
        <div className="flex-row column-gap-7 add-order" onClick={() => backtoHome()} style={{ color: brandColor }}>
          {/* <MyImages src="/assets/icons/plus-circle.svg" /> */}
          <Icon icon="el:plus-sign" width={20} />
          {t('add_order')}
        </div>
      </div>
      {spotType === Number(SPOT_QRTABLE) && unPaidOrdersCount + paidOrdersCount > 0 && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '15px',
          }}
        >
          <div
            style={{
              height: '3.5vh',
              width: '85%',
              backgroundColor: '#ededed',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',

              alignItems: 'center',
              gap: '2px',
            }}
          >
            {unPaidOrdersCount > 0 && (
              <div
                onClick={() => setIsPaid(false)}
                style={{
                  backgroundColor: !isPaid ? brandColor : '#ededed',
                  height: '100%',
                  width: `${paidOrdersCount > 0 ? '50%' : '100%'}`,
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: !isPaid ? 'white' : '#5f5f5f',
                  transition: 'all 0.3s',
                  fontSize: '1rem',
                  fontWeight: '550',
                  cursor: 'pointer',
                }}
              >
                Unpaid
              </div>
            )}
            {paidOrdersCount > 0 && (
              <div
                onClick={() => setIsPaid(true)}
                style={{
                  backgroundColor: isPaid ? brandColor : '#ededed',
                  height: '100%',
                  width: `${unPaidOrdersCount > 0 ? '50%' : '100%'}`,
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: isPaid ? 'white' : '#5f5f5f',
                  fontSize: '1rem',
                  fontWeight: '550',
                  cursor: 'pointer',
                  transition: 'all 0.3s',
                }}
              >
                Paid
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderSummaryHeader;
