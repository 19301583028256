import { MyImages } from 'cores/components';
import { Helmet } from 'react-helmet-async';

const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <title>@nTnQR</title>
        <meta property="og:title" content={'@nTnQR'} />
        <meta name="twitter:title" content={'@nTnQR'} />
        <link rel="apple-touch-icon" sizes="180x180" href="/assets/favicons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/assets/favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/assets/favicons/favicon-16x16.png" />
        <link rel="manifest" href="/assets/favicons/site.webmanifest" />{' '}
      </Helmet>

      <div className="not-found-wrapper">
        <div className="center">
          <MyImages className="image-404" src="/assets/images/404.svg" />
        </div>
        <div className="mt-40">
          <div className="somethings-went-wrong-text">Oops! Something went wrong.</div>
          <p className="description mt-12">
            We couldn’t find the page you were looking for. Why not try to scan the QR Code again.
          </p>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
