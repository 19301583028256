import { FILTER_CHANGE_ON_CATEGORY, FILTER_SEARCH_BY_NAME_CHANGE } from 'constants/Filter';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import Navigation from '../../../components/partials/Navigation/index';
import { InputComponent } from '../../../cores/components';
import { MyImages } from '../../../cores/components/MyImage/index';
import { useAppSelector } from '../../../hooks/redux/index';
import { RootState } from '../../../store/configureStore';
import { generatePathHelpForHomePage, getSpotId } from 'helper/path';
import ImageSlider from 'cores/components/ImageSlider';
import { useTranslation } from 'react-i18next';
import FilterCategory from './filter-category';
import { Icon } from '@iconify/react';
import { getSpotById } from 'helper/spot';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  SPOT_DELIVERY,
  SPOT_HOTEL_ROOM_SERVICE,
  SPOT_MENU_ONLY,
  SPOT_PICKUP_TYPE,
  SPOT_QRTABLE,
  SPOT_RESERVATION_PREORDER,
  SPOT_TABLE_RESERVATION,
} from 'constants/spot';
import ReactGA from 'react-ga4';
import NavigationButtons from './navigationButtons';
import CallForWaiter from 'components/partials/CallForWaiter';
import AskForBilledPayment from 'components/partials/AskForBillPayment';
import LanguageSetting from 'components/partials/LanguageSetting';
import FilterCategorySelect from './filter-category-select';
import CategoryDrawer from './category-drawer';
import _ from 'lodash';
import WifiDetails from 'components/partials/WifiDetails';
import SearchItems from 'components/partials/SearchItems';
import MenuShortcutDrawer from './menu-shortcut-drawer';

export interface IProps {
  isPreOrderInfoOpen?: boolean;
  changeSearchName?: (query: string) => void;
  clickSliderHappened?: () => void;
  setWifiDetailsModal?: () => void;
  setCategory?: (param: string) => void;
  setSelectedTab: (param: string) => void;
  categoryDisplayed: string;
  selectedTab: string;
  openSearchBox?: boolean;
  setOpenSearchBox?: (value: boolean) => void;
}

const Header = (props: IProps) => {
  const dispatch = useDispatch();
  const { outletId, orgId } = useParams();
  const { detail } = useAppSelector((state) => state.organization);
  const { deliveryFee, deliveryDistance } = useAppSelector((state) => state.carts);
  const { list } = useAppSelector((state) => state.outlets);
  const { selected } = useAppSelector((state: RootState) => state.tabSelected);
  const [selectedTab, setSelectedTab] = useState('');
  const navigate = useNavigate();
  const search = useLocation().search;
  useEffect(() => {
    setSelectedTab(selected);
  }, [selected]);

  const outlet = Array.isArray(list) ? list.find((i) => i.id === outletId) : null;
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';

  const { name } = useAppSelector((state: RootState) => state.filter);
  const [category, setCategoryFilter] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [filterName, setFilterName] = useState(name || '');
  const [showSearch, setShowSearch] = useState(false);
  const spotId = getSpotId();
  const spot = getSpotById(spotId);
  const [openBillPayment, setOpenBillPayment] = useState(false);
  const [openLanguage, setOpenLanguage] = useState(false);

  const images = outlet?.headerImages || [];
  const { t } = useTranslation();
  const [scrolled, setScrolled] = useState(false);
  const promotionGroup = useAppSelector((state) => state.menu.list);
  const groupMenus = _.groupBy(promotionGroup, 'promotion.name');
  // const filteredGroupedMenus = Object.fromEntries(Object.entries(groupMenus).filter(([key]) => key !== 'undefined'));
  const filteredGroupedMenus = Object.entries(groupMenus)
    .filter(([key]) => key !== 'undefined')
    .map(([key, value]) => key);
  const groupedMenu = useAppSelector((state: RootState) => state.menu.grouped);
  const [openCategoryDrawer, setOpenCategoryDrawer] = useState(false);

  const [openCallforService, setOpenCallForService] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openMenuShortcut, setOpenMenuShortcut] = useState(false);
  const [openWifi, setOpenWifi] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const previousOrder =
    groupedMenu && groupedMenu.length > 0
      ? groupedMenu.sort((a, b) => {
          if (a.category === 'Previously Ordered - ធ្លាប់បញ្ជាទិញពីមុន') {
            if (a.menu.length > 0 && b.menu.length > 0 && a.menu[0].itemCategory && b.menu[0].itemCategory) {
              return a.menu[0].itemCategory.sequence - b.menu[0].itemCategory.sequence;
            }
          }
          return 0;
        })
      : null;
  const goToOrderHistory = () => {
    if (outletId && orgId) {
      const path = generatePathHelpForHomePage('/order-summary', { outletId, orgId }, search);
      navigate(path.pathname + path.search);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // for smooth scrolling
    });
  };
  useEffect(() => {
    if (!openSearch) {
      window.onscroll = function () {
        checkScrolled();
      };
    }
  }, [filterName, selected, openSearch, searchValue]);
  useEffect(() => {
    if (props.openSearchBox) {
      setOpenSearch(true);
    }
  }, [props.openSearchBox, openSearch]);
  function checkScrolled() {
    const header = document.getElementById('sticky-header');
    const offSetHeightAdditional =
      spot && (spot.type == SPOT_QRTABLE || spot.type == SPOT_HOTEL_ROOM_SERVICE)
        ? outlet?.wiFis && outlet?.wiFis.length > 0
          ? 175
          : 100
        : spot && spot.type == SPOT_MENU_ONLY
        ? 0
        : 105;
    if (header) {
      if (searchValue.length === 0) {
        if (window.scrollY > header.offsetHeight + offSetHeightAdditional) {
          setScrolled(true);
          header.classList.add('fixed', 'fix-top');
          header.style.minHeight = '137px';
        } else {
          setScrolled(false);
          header.classList.remove('fixed', 'fix-top');
          if (spot && spot.type == SPOT_QRTABLE) {
            // header.style.minHeight = '20vh';
          } else {
            // header.style.minHeight = '137px';
          }
        }
      } else {
        setScrolled(true);

        header.classList.add('fixed', 'fix-top');
        header.style.minHeight = '137px';
        header.style.backgroundColor = 'white';
      }
    }
  }

  function outletDetails() {
    const { outletId } = useParams();

    let item1 = Array.isArray(list) ? list.find((i) => i.id === outletId) : null;
    return item1;
  }
  const searchMenuByNameChange = (query: string) => {
    if (query) {
      ReactGA.event({
        category: `Searched for ${query}`,
        action: `Searched for ${query}`,
        transport: 'xhr', // optional, beacon/xhr/image
      });
    }
    const { changeSearchName, setCategory } = props;
    setCategoryFilter('');
    setFilterName(query);
    if (changeSearchName) {
      changeSearchName(query);
    }
    dispatch({ type: FILTER_CHANGE_ON_CATEGORY, payload: [] });
    dispatch({ type: FILTER_SEARCH_BY_NAME_CHANGE, payload: query });
  };

  useEffect(() => {
    if (category) {
      const { setCategory } = props;
      if (setCategory) {
        setCategory(category);
        setSelectedCategory(category);
      }

      dispatch({ type: FILTER_CHANGE_ON_CATEGORY, payload: [category] });
    } else {
      const { setCategory } = props;
      if (setCategory) {
        setCategory('');
      }
      dispatch({ type: FILTER_CHANGE_ON_CATEGORY, payload: [] });
    }
  }, [category]);

  useEffect(() => {
    if (!showSearch) {
      searchMenuByNameChange('');
    }
  }, [showSearch]);

  useEffect(() => {
    setSelectedCategory(props.categoryDisplayed);
  }, [props.categoryDisplayed]);
  function triggerSearch() {
    if (filterName.length > 0) {
      searchMenuByNameChange(filterName);
    }
  }

  return (
    <>
      <div
        id="restaurant-profile-information"
        className="restaurant-profile-header-container"
        style={{
          maxHeight:
            spot && spot.type == SPOT_MENU_ONLY
              ? openSearch
                ? '35px'
                : '20px'
              : outlet?.wiFis && outlet?.wiFis.length > 0
              ? openSearch
                ? '35px'
                : '175px'
              : openSearch
              ? '35px'
              : '120px',
          backgroundColor: `${searchValue ? 'white' : 'transparent'}`,
          marginBottom: openSearch ? '0px' : spot && spot.type == SPOT_MENU_ONLY ? '35px' : undefined,
        }}
      >
        {openBillPayment && <AskForBilledPayment onClose={() => setOpenBillPayment(false)} />}

        {!openSearch && (
          <>
            {images.length > 0 ? (
              <>
                <ImageSlider images={images} brandColor={brandColor} />
              </>
            ) : (
              <div className="restaurant-wallpaper" style={{ height: '200px' }}>
                <MyImages className="wallpaper" src={detail?.bannerImage?.imageUrl} />
              </div>
            )}
            <div className="overview-container">
              <div className="overview-card ">
                <div className="overview-card-content">
                  <div className="overview-card-content-row">
                    <img
                      src={detail?.image?.imageUrl}
                      alt="outlet-icon"
                      className="overview-card-content-row-outlet-image"
                    />
                    <span className="overview-card-content-row-outlet-title">{outlet?.name}</span>
                  </div>
                  <div className="overview-card-border-bottom"></div>
                </div>
                <div className="overview-card-content-column">
                  <div
                    className="overview-card-content-row-item overview-card-content-border"
                    onClick={() => setOpenLanguage(true)}
                  >
                    <div className="overview-card-content-item">
                      <img src="/assets/icons/overview/globe.svg" alt="" className="content-item-icon" />
                      <span className="content-item-label">{t('menu_language')}</span>
                    </div>
                    <img src="/assets/icons/overview/arrowright.svg" alt="" className="content-item-right-icon" />
                  </div>
                  {spot &&
                    (spot.type == SPOT_QRTABLE || spot.type == SPOT_HOTEL_ROOM_SERVICE) &&
                    outlet?.wiFis &&
                    outlet?.wiFis.length > 0 && (
                      <div
                        className="overview-card-content-row-item overview-card-content-border"
                        onClick={() => setOpenWifi(true)}
                      >
                        <div className="overview-card-content-item">
                          <img src="/assets/icons/overview/wifi.svg" alt="" className="content-item-icon" />
                          <span className="content-item-label">{t('wifi_password')}</span>
                        </div>
                        <img src="/assets/icons/overview/arrowright.svg" alt="" className="content-item-right-icon" />
                      </div>
                    )}
                  {spot && spot.type == SPOT_QRTABLE && (
                    <div
                      className="overview-card-content-row-item overview-card-content-border"
                      onClick={() => setOpenCallForService(true)}
                    >
                      <div className="overview-card-content-item">
                        <img src="/assets/icons/overview/bell.svg" alt="" className="content-item-icon" />
                        <span className="content-item-label">{t('call_service')}</span>
                      </div>
                      <img src="/assets/icons/overview/arrowright.svg" alt="" className="content-item-right-icon" />
                    </div>
                  )}
                  {spot && spot.type != SPOT_MENU_ONLY && (
                    <div className="overview-card-content-row-item" onClick={() => goToOrderHistory()}>
                      <div className="overview-card-content-item">
                        <img src="/assets/icons/overview/receipt.svg" alt="" className="content-item-icon" />
                        <span className="content-item-label">{t('order_history')}</span>
                      </div>
                      <img src="/assets/icons/overview/arrowright.svg" alt="" className="content-item-right-icon" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        <div
          id="sticky-header"
          className="sticky-content restaurant-information  "
          style={{
            boxShadow: scrolled || openSearch ? '0px 2px 4px rgba(0, 0, 0, 0.1)' : 'none',
            minHeight: '167px',
            backgroundColor: `${scrolled || openSearch ? 'white' : 'transparent'}`,
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '15px',
          }}
        >
          {detail && (
            <div
              style={{
                display: scrolled || searchValue || openSearch ? 'flex' : 'none',
                flexDirection: 'row',
                gap: '10px',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <div
                  style={{
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    borderRadius: '20%',
                    padding: '5px',
                  }}
                  onClick={() => {
                    scrollToTop();
                  }}
                >
                  <img
                    className="profile-image-sticky sticky-content"
                    id="logo"
                    loading="lazy"
                    src="/assets/icons/overview/home.svg"
                  />
                </div>
                <span
                  style={{
                    fontSize: '18px',
                    fontWeight: '600',
                    paddingTop: '6px',
                  }}
                >
                  {outletDetails()?.name}
                </span>
              </div>

              <span
                style={{
                  paddingTop: '6px',
                }}
                onClick={() => {
                  setOpenMenuShortcut(true);
                }}
              >
                <Icon icon="mingcute:more-1-fill" width="26" height="26" style={{ color: 'black' }} />
              </span>
            </div>
          )}
          {spot && spot.type != SPOT_TABLE_RESERVATION && spot.type != SPOT_RESERVATION_PREORDER && (
            <div
              style={{
                width: '100%',
              }}
            >
              {/* {scrolled && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: '15px',
                    alignItems: 'flex-start',
                  }}
                >
                  <FilterCategorySelect
                    setCategory={setCategoryFilter}
                    selectedCategory={selectedCategory}
                    selectedTab={props.selectedTab}
                    spot={spot}
                    setOpenCategoryDrawer={setOpenCategoryDrawer}
                    grouped={groupedMenu}
                    groupMenus={groupMenus}
                  />
                  <div
                    className="menu-filter-box"
                    style={{
                      padding: '14px',
                    }}
                    onClick={() => {
                      setOpenSearch(true);
                    }}
                  >
                    <Icon icon="octicon:search-16" width={18} />
                  </div>
                </div>
              )} */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '15px',
                  alignItems: 'flex-start',
                }}
              >
                {
                  !openSearch ? (
                    <>
                      <FilterCategorySelect
                        setCategory={setCategoryFilter}
                        selectedCategory={selectedCategory}
                        selectedTab={props.selectedTab}
                        spot={spot}
                        setOpenCategoryDrawer={setOpenCategoryDrawer}
                        grouped={groupedMenu}
                        groupMenus={groupMenus}
                      />
                      <div
                        className="menu-filter-box"
                        style={{
                          padding: '14px',
                        }}
                        onClick={() => {
                          if (props.setOpenSearchBox) {
                            props.setOpenSearchBox(true);
                            localStorage.setItem('isBack', 'true');
                            setOpenSearch(true);
                            localStorage.setItem('menuListingScrollPosition', window.scrollY.toString());
                          }
                        }}
                      >
                        <Icon icon="octicon:search-16" width={18} />
                      </div>
                    </>
                  ) : (
                    // {openSearch && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        border: '1px solid #ccc',
                        borderRadius: '15px',
                        padding: '5px 17px ',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      {/* <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                      > */}
                      <Icon icon="ic:outline-search" width={24} />
                      <input
                        type="text"
                        placeholder="Search menu"
                        value={searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value), searchMenuByNameChange(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (e.target.value.length === 0 && props.setOpenSearchBox) {
                            setOpenSearch(false);
                            props.setOpenSearchBox(false);
                          }
                        }}
                        autoFocus
                        style={{
                          padding: '10px',
                          border: 'none',
                          outline: 'none',
                          width: '100%',
                          fontSize: '16px',
                        }}
                      />
                      {/* </div> */}
                      <Icon
                        icon="carbon:close-filled"
                        width="18"
                        height="18"
                        style={{ color: 'rgba(34, 38, 45, 0.3)' }}
                        onClick={() => {
                          if (props.setOpenSearchBox) {
                            setOpenSearch(false);
                            props.setOpenSearchBox(false);
                            setSearchValue('');
                            searchMenuByNameChange('');
                          }
                        }}
                      />
                    </div>
                  )
                  // )}
                }
              </div>
            </div>
          )}
        </div>

        {/* {openSearch && (
          <SearchItems
            onClosed={() => {
              setOpenSearch(false);
              if (props.setOpenSearchBox) props.setOpenSearchBox(false);
            }}
            outletId={outletId ?? ''}
            spotId={spotId ?? ''}
            searchMenuByNameChange={(value: string) => searchMenuByNameChange(value)}
          />
        )} */}
        {openCallforService && (
          <CallForWaiter
            onClosed={() => setOpenCallForService(false)}
            outletId={outletId ?? ''}
            spotId={spotId ?? ''}
          />
        )}
        {openBillPayment && <AskForBilledPayment onClose={() => setOpenBillPayment(false)} />}
        {openLanguage && <LanguageSetting onClose={() => setOpenLanguage(false)} />}
        {openWifi && (
          <div className="pop-up" style={{ top: '0' }}>
            <WifiDetails outletDetails={outlet} closedWifi={() => setOpenWifi(false)} />
          </div>
        )}
        {openCategoryDrawer && (
          <CategoryDrawer
            onClose={() => setOpenCategoryDrawer(false)}
            grouped={groupedMenu}
            groupMenus={groupMenus}
            setCategory={setCategoryFilter}
            selectedCategory={selectedCategory}
            specialDeals={filteredGroupedMenus}
            previousOrders={previousOrder}
          />
        )}
        {openMenuShortcut && (
          <MenuShortcutDrawer
            onClose={() => setOpenMenuShortcut(false)}
            goToOrderHistory={goToOrderHistory}
            openMenuLanguage={() => setOpenLanguage(true)}
            openCallWaiter={() => setOpenCallForService(true)}
            openWifi={() => setOpenWifi(true)}
            outlet={outlet}
          />
        )}
      </div>
    </>
  );
};

export default Header;
