import { CART_REQUEST_EMPTY } from 'constants/carts';
import { sumBy } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { calculateTotalSumOfOrders, getLatestOrder } from '../../helper/order-summary';
import { generatePathHelpForHomePage, getSpotId } from '../../helper/path';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/index';
import { RootState } from '../../store/configureStore';
import { CreateOrderResponse } from 'interfaces/models/order';
import { Item } from 'interfaces/models/carts';
import { PaymentMethods, socialMedias } from 'interfaces/models/outlets';
import { MyImages } from 'cores/components';
import ChargesSection from 'components/partials/ChargesSection';
import { FooterLogo } from 'components/partials/FooterLogo';
import DownloadReceipt from 'cores/components/DownloadReceipt';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet-async';

export const PickupOrderSummary = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const route = useParams();
  const { outletId, orgId } = route;
  const { t } = useTranslation();
  const latestOrder = getLatestOrder();
  const totalPrice = sumBy(latestOrder, 'price');
  const { detail } = useAppSelector((state: RootState) => state.organization);
  const { list } = useAppSelector((state: RootState) => state.outlets);
  const spotId = getSpotId();
  const filteredList = latestOrder.filter((item) => item.outletId === outletId && item.outletSpotId === spotId);

  const totalFee = filteredList[0].discountedPrice !== 0 ? filteredList[0].discountedPrice : filteredList[0].price;
  const totalOrderAmount = latestOrder[0].items.reduce((totalAmount, item) => {
    // Calculate the item's total price (including quantity and addons)
    const basePrice = item.discountedPrice !== null && item.discountedPrice !== 0 ? item.discountedPrice : item.price;
    const itemPrice = (basePrice + (item.addOns?.reduce((acc, addon) => acc + addon.price, 0) || 0)) * item.quantity;
    const total = totalAmount + itemPrice;
    // const charge = total >= 30 ? 0 : latestOrder[0].deliveryCharge;
    return total;
  }, 0);
  const totalFee2 = filteredList[0].items.map((item: Item, index: number) => {
    const discountedPrice = item.discountedPrice || 0;
    const totalPrice = discountedPrice !== 0 ? discountedPrice : item.price;

    let totalAddOnsPrice = 0;
    if (item.addOns && item.addOns.length > 0) {
      totalAddOnsPrice = item.addOns.reduce((sum, addOn) => sum + addOn.price, 0);
    }

    const finalPrice = totalPrice + totalAddOnsPrice;
    return finalPrice;
  });
  const discountedPriceSum =
    filteredList.length > 0
      ? filteredList.reduce((total, item) => {
          const price = item.discountedPrice !== 0 ? item.discountedPrice : item.price;
          return total + price;
        }, 0)
      : 0;
  const socialMedias = outletDetails()?.socialMedias;
  const paymentMethods = outletDetails()?.paymentMethods;
  const name = outletDetails()?.name;
  const location = outletDetails()?.location;

  const clickMakeOtherOrder = () => {
    const { outletId, orgId } = route;
    dispatch({ type: CART_REQUEST_EMPTY });
    if (outletId && orgId) {
      const path = generatePathHelpForHomePage('/', { outletId, orgId }, search);
      navigate(path.pathname + path.search);
    }
  };
  const totalSumOfOrders = calculateTotalSumOfOrders(filteredList);

  function outletDetails() {
    let details = list.find((i) => i.id === filteredList[0].outletId);
    return details;
  }

  const showSocials = (name: string, url: string) => {
    switch (name) {
      case 'Facebook':
        return <MyImages width="30px" src="/assets/icons/facebook.svg" onClick={() => window.open(url, '_blank')} />;
      case 'Instagram':
        return <MyImages width="30px" src="/assets/icons/instagram.svg" onClick={() => window.open(url, '_blank')} />;
      case 'Tripadvisor':
        return <MyImages width="30px" src="/assets/icons/tripadvisor.svg" onClick={() => window.open(url, '_blank')} />;
      case 'Google':
        return <MyImages width="30px" src="/assets/icons/google-fill.svg" onClick={() => window.open(url, '_blank')} />;
      case 'Tiktok':
        return <MyImages width="30px" src="/assets/icons/telegram.svg" onClick={() => window.open(url, '_blank')} />;
      case 'Telegram':
        return <MyImages width="30px" src="/assets/icons/tiktok.svg" onClick={() => window.open(url, '_blank')} />;

      default:
        return '';
    }
  };

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname + window.location.search}`,
      title: `${detail?.name} -  Pickup Order Summary`,
    });
    return () => {
      dispatch({ type: CART_REQUEST_EMPTY });
    };
  }, []);

  return (
    <>
      {detail && detail.name && (
        <Helmet>
          <title>{detail?.name}@nTnQR</title>
          <meta property="og:title" content={detail?.name} />
          <meta name="twitter:title" content={detail?.name} />
          {detail.image && detail.image.imageUrl ? (
            <link rel="icon" href={`${detail.image.imageUrl}`} />
          ) : (
            <>
              <link rel="apple-touch-icon" sizes="180x180" href="/assets/favicons/apple-touch-icon.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/assets/favicons/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/assets/favicons/favicon-16x16.png" />
              <link rel="manifest" href="/assets/favicons/site.webmanifest" />{' '}
            </>
          )}
        </Helmet>
      )}
      <div>
        <div className="content-wrapper">
          <div className="content-card">
            <div className="restaurant-profile">
              <img loading="lazy" src={detail?.image.imageUrl} alt={detail?.image.imageUrl} />
            </div>
            <section className="section" style={{ paddingTop: '48px' }}>
              <h2 className="title">{t('order_sent')}</h2>
              <div className="date">Order #{filteredList[0] ? filteredList[0].orderTypeSequence : 'N/A'}</div>
              <div className="date mt-5">{moment().format('Do MMMM YYYY - hh:mm')}</div>
              <div className="socials mt-20">
                {socialMedias && socialMedias.length > 0 ? (
                  <>
                    <span>{t('connect_with_us')}:</span>
                    <div className="flex-row column-gap-10 center">
                      {socialMedias.map((item: socialMedias, index: number) => {
                        return (
                          <div key={index} className="mt-10">
                            {showSocials(item.name, item.url)}
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </section>
            <section className="section">
              <div className="flex-row space-between total-amount-text">
                <div>{t('order_summary')}</div>
              </div>
              <div className="flex-col pt-16">
                <div className="flex-row space-between" style={{ paddingBottom: 10, paddingTop: 10 }}>
                  <div className="col">{t('order_from')}</div>
                  <div>{name}</div>
                </div>
              </div>
              <div className="flex-col pt-16">
                <div className="flex-row space-between" style={{ paddingBottom: 10, paddingTop: 10 }}>
                  <div className="col">{t('pickup_time')}</div>
                  <div>
                    {new Date(filteredList[0]?.preferedDeliveryDateTime).getHours().toString().padStart(2, '0')}:
                    {new Date(filteredList[0]?.preferedDeliveryDateTime).getMinutes().toString().padStart(2, '0')}
                  </div>
                </div>
              </div>
              <div className="flex-col pt-16">
                <div className="flex-row space-between" style={{ paddingBottom: 10, paddingTop: 10 }}>
                  <div className="col">{t('pickup_address')}</div>
                  <div>{location?.address}</div>
                </div>
              </div>
            </section>
            <section className="section">
              <div className="flex-row center">
                {paymentMethods &&
                  paymentMethods.length > 0 &&
                  paymentMethods.map((item: PaymentMethods, index: number) => {
                    return (
                      <div key={index} className="mt-10">
                        {item.name === 'KHQR' ? (
                          <>
                            <div className="flex-row center" style={{ marginBottom: '10px' }}>
                              <p className="bold">{'KHQR'}</p>
                            </div>
                            <div className="flex-row center" style={{ marginBottom: '10px' }}>
                              <MyImages width="120px" src={item.icon.imageUrl} />
                            </div>
                            <div className="flex-row center" style={{ marginBottom: '5px' }}>
                              <p className="bold">{item.accountName}</p>
                            </div>
                            <div className="flex-row center" style={{ marginBottom: '5px' }}>
                              <p className="bold">{item.accountNumber}</p>
                            </div>
                            <div className="flex-row center" style={{ marginBottom: '30px' }}>
                              <p className="bold">$ {totalOrderAmount.toFixed(2)}</p>
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
              </div>
              <div className="flex-row space-between total-amount-text">
                <div>{t('order_details')}</div>
              </div>
              <div className="flex-col pt-23">
                {filteredList[0].items.map((item: Item, index: number) => {
                  const discountedPrice = item.discountedPrice || 0;
                  const totalPrice = discountedPrice !== 0 ? discountedPrice : item.price;

                  let totalAddOnsPrice = 0;
                  if (item.addOns && item.addOns.length > 0) {
                    totalAddOnsPrice = item.addOns.reduce((sum, addOn) => sum + addOn.price, 0);
                  }

                  const finalPrice = (totalPrice + totalAddOnsPrice) * item.quantity;

                  return (
                    <div
                      key={index}
                      className="flex-row space-between"
                      style={{ paddingBottom: 10, alignItems: 'flex-start' }}
                    >
                      <div className="col">
                        <div>
                          {item.quantity}x {item.name}
                        </div>
                        {item.addOns?.map((a, index) => (
                          <div key={index}>- {a.name}</div>
                        ))}
                      </div>
                      <div style={{ whiteSpace: 'nowrap' }}>$ {finalPrice.toFixed(2)}</div>
                    </div>
                  );
                })}
              </div>
              <ChargesSection totalPrice={totalFee} />
              <div className="flex-col pt-23">
                <DownloadReceipt />
              </div>
            </section>
          </div>
          <div style={{ marginTop: '20px' }}>
            <FooterLogo />
          </div>
        </div>
      </div>
    </>
  );
};
