type PromotionProps = {
  type: number;
  value: number;
  price: number;
  color: string;
  rating: number;
  isLandscapeNoImage?: boolean;
};

export function BadgePromotion({ promotion }: { promotion: PromotionProps }) {
  const defaultImage = '/assets/icons/fire.png';
  const promoImage = '/assets/icons/promo.png';
  return (
    <>
      {promotion.isLandscapeNoImage ? (
        <>
          <div
            style={{
              padding: '4px 7px 4px 7px',
              color: 'white',
              borderRadius: '20px',
              fontSize: '12px',
              height: '24px',
              maxWidth: '80px',
              backgroundColor: `${promotion?.color}`,
              fontWeight: '550',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span
              style={{
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              <img loading="lazy" src={defaultImage} alt={'fire'} width={15} />
              <p>Popular</p>
            </span>
          </div>
          <div
            style={{
              padding: '4px 7px 4px 7px',
              color: 'white',
              borderRadius: '20px',
              fontSize: '12px',
              height: '24px',
              maxWidth: '80px',
              backgroundColor: `${promotion?.color}`,
              fontWeight: '550',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              {promotion.type === 0 && promotion.value + '% OFF'}
              {promotion.type === 1 && '$' + promotion.value + ' OFF'}
              {promotion.type === 2 && (
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '2px',
                  }}
                >
                  <img loading="lazy" src={promoImage} alt={'fire'} width={15} />
                  <p>Promo</p>
                </span>
              )}
            </span>
          </div>
        </>
      ) : (
        <>
          {promotion.rating >= 5 && (
            <div
              style={{
                position: 'absolute',
                left: '7px',
                top: '7px', // Add 'px' to specify the unit
                padding: '4px 7px 4px 7px',
                color: 'white',
                borderRadius: '20px',
                fontSize: '12px',
                height: '24px',
                maxWidth: '80px',
                backgroundColor: `${promotion?.color}`,
                fontWeight: '550',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span
                style={{
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '5px',
                }}
              >
                <img loading="lazy" src={defaultImage} alt={'fire'} width={15} />
                <p>Popular</p>
              </span>
            </div>
          )}
          <div
            style={{
              position: 'absolute',
              left: '7px',
              top: `${promotion.rating >= 5 ? '35px' : '7px'}`, // Add 'px' to specify the unit
              padding: '4px 7px 4px 7px',
              color: 'white',
              borderRadius: '20px',
              fontSize: '12px',
              height: '24px',
              maxWidth: '80px',
              backgroundColor: `${promotion?.color}`,
              fontWeight: '550',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              {promotion.type === 0 && promotion.value + '% OFF'}
              {promotion.type === 1 && '$' + promotion.value + ' OFF'}
              {promotion.type === 2 && (
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '2px',
                  }}
                >
                  <img loading="lazy" src={promoImage} alt={'fire'} width={15} />
                  <p>Promo</p>
                </span>
              )}
            </span>
          </div>
        </>
      )}
    </>
  );
}
