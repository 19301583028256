export enum PaymentType {
  CASH = 'Cash',
  CC = 'Credit Card',
  QR = 'KHQR',
  ABA = 'ABA Payway',
  BILL = 'Bill to room',
  CASHWITHNOTE = 'Cash with Note',
  PAYONLINE = 'Pay Online',
  VISA_MASTER = 'Credit/Debit Card (Offline)',
  ABAAKHPP = 'Scan ABA KHQR',
  ACLBKHPP = 'Acleda Bank',
  SBPLKHPP = 'Sathapana iBanking',
  WECHAT = 'WeChat Pay',
  ALIPAY = 'Alipay',
  CARDONLINE = 'Credit/Debit Card (Online)',
}

export enum PaymentName {
  CASH = 'Cash',
  CC = 'Credit Card',
  QR = 'KHQR',
  ABA = 'ABA Payway',
  BILL = 'Bill to room',
  CASHWITHNOTE = 'Cash with Note',
  PAYONLINE = 'Pay Online',
  VISA_MASTER = 'Credit/Debit Card (Offline)',
  ABAAKHPP = 'Scan ABA KHQR',
  ACLBKHPP = 'Acleda Bank',
  SBPLKHPP = 'Sathapana iBanking',
  WECHAT = 'WeChat Pay',
  ALIPAY = 'Alipay',
  CARDONLINE = 'Credit/Debit Card (Online)',
}

export enum PaymentOption {
  ABAAKHPP = 'abapay_khqr',
  WECHAT = 'wechat',
  ALIPAY = 'alipay',
  CARDONLINE = 'cards',
}
export enum OnlinePaymentMethods {
  abapay_khqr = 'Scan ABA KHQR',
  wechat = 'WeChat Pay',
  alipay = 'Alipay',
  cards = 'Credit/Debit Card (Online)',
}
