import { getOrganizationInformation } from 'actions/organization';
import { getAllSpot } from 'actions/spot';
import { CART_REQUEST_EMPTY } from 'constants/carts';
import { MyImages } from 'cores/components';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAllOrganization } from 'services/organization';

const SelectOrgPage = () => {
  const dispatch = useAppDispatch();
  const [organizations, setOrganizations] = useState<any[]>([]);
  const outlets = useAppSelector((state) => state.outlets.list);
  const spot = useAppSelector((state) => state.spot.list);
  const [selectedOrg, setSelectedOrg] = useState<string>('');
  const [selectedOutlet, setselectedOutlet] = useState<string>('');
  const [selectedSpot, setSelectedSpot] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: CART_REQUEST_EMPTY });

    fetchAllOrganization().then((res) => {
      setOrganizations(res);
    });
    if (selectedOrg) {
      dispatch(getOrganizationInformation(selectedOrg));
    }
    if (selectedOutlet) {
      dispatch(getAllSpot(selectedOutlet));
    }
  }, [selectedOrg, selectedOutlet]);

  const navigateToMenu = () => {
    if (selectedSpot) {
      navigate(`/org/${selectedOrg}/outlet/${selectedOutlet}?spotId=${selectedSpot}`);
    }
  };

  return (
    <div className="not-found-wrapper">
      <div className="center">
        <MyImages className="image-404" style={{ width: '200px' }} src="/assets/images/ntnlogo.png" />
      </div>
      <div>
        {/* <div className="somethings-went-wrong-text">Oops! Something went wrong.</div> */}
        <p className="description mt-12">Please select an organization to continue.</p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
              width: '100%',
            }}
          >
            <div>
              <select
                name="organization"
                id="organization"
                style={{
                  width: '80vw',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  fontSize: '16px',
                }}
                value={selectedOrg}
                onChange={(e) => setSelectedOrg(e.target.value)}
              >
                <option value="" disabled>
                  Select Organization
                </option>
                {organizations.map((org) => (
                  <option key={org.id} value={org.id}>
                    {org.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <div>
              <select
                disabled={!selectedOrg}
                name="organization"
                id="organization"
                style={{
                  width: '80vw',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  fontSize: '16px',
                }}
                value={selectedOutlet}
                onChange={(e) => setselectedOutlet(e.target.value)}
              >
                <option value="" disabled>
                  Select Outlet
                </option>
                {outlets.map((outlet) => (
                  <option key={outlet.id} value={outlet.id}>
                    {outlet.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <select
                disabled={!selectedOutlet || !selectedOrg}
                name="organization"
                id="organization"
                style={{
                  width: '80vw',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  fontSize: '16px',
                }}
                value={selectedSpot}
                onChange={(e) => setSelectedSpot(e.target.value)}
              >
                <option value="" disabled>
                  Select Service
                </option>
                {spot.map((spot) => (
                  <option key={spot.id} value={spot.id}>
                    {spot.spotName}
                  </option>
                ))}
              </select>

              <button
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  fontSize: '16px',
                  marginTop: '20px',
                  backgroundColor: '#000',
                  color: '#fff',
                }}
                disabled={!selectedSpot}
                onClick={navigateToMenu}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectOrgPage;
