import { FiveStar } from 'components/partials/FiveStar';
import { MyImages } from 'cores/components';
import { getLatestMenuForReviews } from 'helper/order-summary';
import { orderBy, set } from 'lodash';
import { createRef, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { getAllMenuByOutletId, submitFeedBackMenu } from '../../actions/menu/index';
import { submitFeedBackForOutlet } from '../../actions/outlets/index';
import { generatePathHelpForHomePage } from '../../helper/path';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/index';
import { Item } from '../../interfaces/models/carts';
import { FeedBackMenuParam } from '../../interfaces/models/feedback';
import { OutletFeedBackParam } from '../../interfaces/models/outlets';
import { FooterLogo } from 'components/partials/FooterLogo';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const RatingYourFood = () => {
  const { outletId } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const route = useParams();
  const dispatch = useAppDispatch();
  const [starMapped, setStarMapped] = useState<number[]>([]);
  const [experience, setExperience] = useState(0);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorList, setErrorList] = useState<Boolean[]>([]);
  const [errorMerchantReview, setErrorMerchantReview] = useState(false);
  const refs = useRef(new Array());
  const merchantRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { detail } = useAppSelector((state) => state.organization);
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const menus = getLatestMenuForReviews();
  const [isOutletFeedback, setIsOutletFeedback] = useState(false);
  refs.current = menus.map((element, i) => refs.current[i] ?? createRef()) || [];

  useEffect(() => {
    if (outletId) {
      dispatch(getAllMenuByOutletId(outletId));
    }
    if (localStorage.getItem('OUTLET_FEEDBACK') && localStorage.getItem('OUTLET_FEEDBACK') === '1') {
      setIsOutletFeedback(true);
    }
  }, [menus.length, starMapped]);

  useEffect(() => {
    const firstInvalid = errorList.findIndex((e) => e);
    if (firstInvalid != null && firstInvalid != -1) {
      refs.current[firstInvalid].current.scrollIntoView({ behavior: 'smooth' });
    } else if (errorMerchantReview) {
      merchantRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errorList, errorMerchantReview]);

  function clickSkip() {
    const { outletId, orgId } = route;
    if (outletId && orgId) {
      if (!isOutletFeedback) {
        navigate(generatePathHelpForHomePage('/thank-you', { outletId, orgId }, search));
      } else {
        navigate(generatePathHelpForHomePage('/', { outletId, orgId }, search));
        localStorage.removeItem('OUTLET_FEEDBACK');
      }
    }
  }

  function whenStarChange(rating: number, index: number) {
    starMapped[index] = rating;
    setStarMapped(starMapped);
  }

  async function submitForm() {
    const { outletId } = route;

    setErrorMerchantReview(false);
    if (starMapped.length != menus.length || (outletId && experience == 0)) {
      setErrorList(menus.map((e, i) => (!starMapped[i] ? true : false)));
      if (outletId && experience == 0) {
        setErrorMerchantReview(true);
      }
      return '';
    }

    if (starMapped.length && menus.length) {
      setLoading(true);
      const menuFeedBackParam: FeedBackMenuParam[] = menus.map((menu: Item, idx: number) => ({
        menuId: menu.menuId,
        descrption: '',
        rate: starMapped[idx],
      }));

      await Promise.resolve(dispatch(submitFeedBackMenu(menuFeedBackParam)));
    }

    if (outletId && experience) {
      const param: OutletFeedBackParam = {
        rate: experience,
        description: description,
        outletId,
      };
      await Promise.resolve(dispatch(submitFeedBackForOutlet(param)));
    }
    setLoading(false);
    if (isOutletFeedback) toast.success('Thank you for your feedback!');
    clickSkip();
  }

  return (
    <>
      {detail && detail.name && (
        <Helmet>
          <title>{detail?.name}@nTnQR</title>
          <meta property="og:title" content={detail?.name} />
          <meta name="twitter:title" content={detail?.name} />
          {detail.image && detail.image.imageUrl ? (
            <link rel="icon" href={`${detail.image.imageUrl}`} />
          ) : (
            <>
              <link rel="apple-touch-icon" sizes="180x180" href="/assets/favicons/apple-touch-icon.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/assets/favicons/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/assets/favicons/favicon-16x16.png" />
            </>
          )}

          <link rel="manifest" href="/assets/favicons/site.webmanifest" />
        </Helmet>
      )}
      <div>
        <div className="rating-header">
          <div className="flex-row space-between">
            <div></div>
            <div className="skip-text" onClick={clickSkip} style={{ color: brandColor }}>
              {isOutletFeedback ? t('back') : t('skip')}
            </div>
          </div>
          <div className="title pt-28">{t('rate_experience')}</div>
        </div>
        {menus.map((item: Item, index) => (
          <section
            ref={refs.current[index]}
            className={`rating-section ${errorList[index] ? 'error' : ''}`}
            key={item.menuId}
          >
            <div className="thumbnail-landscape-wrapper transparent">
              <div className="position-relative">
                <div className="overlay-image"></div>
                <MyImages className="thumbnail-image" src={item.imageUrl} />
              </div>
              <div className="thumbnail-right mt-11" style={{ justifyContent: 'unset' }}>
                <div>{item.name}</div>
                <div className="flex-row column-gap-15 mt-11">
                  <FiveStar onClick={(rating: number) => whenStarChange(rating, index)} />
                </div>
              </div>
            </div>
          </section>
        ))}

        <section ref={merchantRef} className={`rating-section ${errorMerchantReview ? 'error' : ''}`}>
          <div className="rating-title">{t('share_overall')}</div>
          <div className="flex-row center column-gap-15 mt-11">
            <FiveStar onClick={setExperience} />
          </div>
          <textarea
            className="rating-textarea mt-19"
            onChange={(e: any) => setDescription(e.target.value)}
            placeholder={t('share_feedback')}
          ></textarea>
        </section>

        <section className="rating-button" onClick={submitForm}>
          <button
            className="flex-row align-center center btn primary"
            style={{ backgroundColor: brandColor, borderColor: brandColor, boxShadow: `0px 4px 4px ${brandColor}40` }}
          >
            {loading && <img width={20} src="/assets/icons/loading-spinner.svg" alt="" />}
            <span className="ml-6">{t('submit_feedback')}</span>
          </button>
        </section>
        <section style={{ paddingBottom: '20px' }}>
          <FooterLogo />
        </section>
      </div>
    </>
  );
};

export default RatingYourFood;
