import { CART_REQUEST_EMPTY } from 'constants/carts';
import { sumBy } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getLatestOrder } from '../../helper/order-summary';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/index';
import { RootState } from '../../store/configureStore';
import { Item } from 'interfaces/models/carts';
import { PaymentMethods, socialMedias } from 'interfaces/models/outlets';
import { MyImages } from 'cores/components';
import ChargesSection from 'components/partials/ChargesSection';
import { FooterLogo } from 'components/partials/FooterLogo';
import DownloadReceipt from 'cores/components/DownloadReceipt';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import DeliveryCountDownImages from 'components/partials/DeliveryCountDownImages';
import { CreateOrderResponse } from 'interfaces/models/order';
import { generatePathHelpForHomePage, getSpotId } from 'helper/path';
import { checkTransactionByTransactionId } from 'services/payment';
import { ADD_PAID_ORDERS } from 'constants/order';
import CryptoJS from 'crypto-js';
import { POST_TAB_REQUEST } from 'constants/tabs';
import { Icon } from '@iconify/react';
import { Helmet } from 'react-helmet-async';

export const DeliveryOrderSumary = () => {
  const { t } = useTranslation();
  const route = useParams();
  const { outletId, orgId } = route;
  const spotId = getSpotId();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const latestOrder = getLatestOrder();
  const { detail } = useAppSelector((state: RootState) => state.organization);
  const { list } = useAppSelector((state: RootState) => state.outlets);
  const totalOrderAmount = latestOrder[0].items.reduce((totalAmount, item) => {
    const basePrice = item.discountedPrice !== null && item.discountedPrice !== 0 ? item.discountedPrice : item.price;
    const itemPrice = (basePrice + (item.addOns?.reduce((acc, addon) => acc + addon.price, 0) || 0)) * item.quantity;
    const total = totalAmount + itemPrice;
    return total;
  }, 0);
  const getTransactionIdFromLocalStorage = () => {
    return localStorage.getItem('TRANSACTION_ID');
  };
  const getDeliveryTime = () => {
    return localStorage.getItem('deliveryInMinutes');
  };
  const filteredList = latestOrder.filter((item) => item.outletId === outletId && item.outletSpotId === spotId);
  const { paidOrders } = useAppSelector((state) => state.orders);
  const filteredUnPaidOrders: CreateOrderResponse[] = filteredList
    .map((item) => {
      if (!paidOrders.includes(item.id)) {
        return { ...item };
      } else {
        return null;
      }
    })
    .filter((item): item is CreateOrderResponse => item !== null);
  const socialMedias = outletDetails()?.socialMedias;
  const paymentMethods = outletDetails()?.paymentMethods;
  const name = outletDetails()?.name;
  const phone = outletDetails()?.contact?.phoneNumber ?? '';
  const email = outletDetails()?.contact?.email ?? '';
  const averagePreparationTime = outletDetails()?.averagePreparationTime;
  const orderCreatedTime = new Date(latestOrder[0].createdAt);
  const averagePreparationTimeAnd =
    averagePreparationTime !== null &&
    averagePreparationTime &&
    averagePreparationTime?.minutes + Number(getDeliveryTime());

  const newDate =
    averagePreparationTimeAnd !== null &&
    averagePreparationTimeAnd &&
    new Date(orderCreatedTime.getTime() + averagePreparationTimeAnd * 60000);

  console.log(newDate);
  const deliveryAddress = latestOrder[0].deliveryContact?.address;
  const dateObj = new Date(latestOrder[0].deliveryDateTime);

  // const deliveryTime = dateObj.toString();
  const deliveryTime = newDate ? newDate.toString() : dateObj.toString();
  function outletDetails() {
    let details = list.find((i) => i.id === latestOrder[0].outletId);
    return details;
  }

  const showSocials = (name: string, url: string) => {
    switch (name) {
      case 'Facebook':
        return <MyImages width="30px" src="/assets/icons/facebook.svg" onClick={() => window.open(url, '_blank')} />;
      case 'Instagram':
        return <MyImages width="30px" src="/assets/icons/instagram.svg" onClick={() => window.open(url, '_blank')} />;
      case 'Tripadvisor':
        return <MyImages width="30px" src="/assets/icons/tripadvisor.svg" onClick={() => window.open(url, '_blank')} />;
      case 'Google':
        return <MyImages width="30px" src="/assets/icons/google-fill.svg" onClick={() => window.open(url, '_blank')} />;
      case 'Tiktok':
        return <MyImages width="30px" src="/assets/icons/telegram.svg" onClick={() => window.open(url, '_blank')} />;
      case 'Telegram':
        return <MyImages width="30px" src="/assets/icons/tiktok.svg" onClick={() => window.open(url, '_blank')} />;

      default:
        return '';
    }
  };

  useEffect(() => {
    if (getTransactionIdFromLocalStorage() !== null) {
      checkTransaction(getTransactionIdFromLocalStorage() as string);
    }
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname + window.location.search}`,
      title: `${detail?.name}/Delivery Order Summary`,
    });
    return () => {
      dispatch({ type: CART_REQUEST_EMPTY });
    };
  }, []);
  const checkTransaction = (id: string) => {
    const reqTime = +new Date();
    const merchantId: string | undefined = (window as any).REACT_APP_MERCHANT_ID as string;
    const apiKey: string | undefined = (window as any).REACT_APP_API_KEY_PAYMENT as string;
    const tranId = id;
    const hmac = CryptoJS.HmacSHA512(reqTime + merchantId + tranId, apiKey);
    const signature = CryptoJS.enc.Base64.stringify(hmac);
    checkTransactionByTransactionId({
      req_time: reqTime.toString(),
      merchant_id: merchantId,
      tran_id: tranId,
      hash: signature,
    }).then((res: any) => {
      const response = JSON.parse(res);
      if (response) {
        if (response.status === 0) {
          filteredUnPaidOrders.map((order) => {
            updateOrder(order);
          });
        }
      }
    });
  };
  const updateOrder = (order: Partial<CreateOrderResponse>) => {
    console.log('🚀 ~ updateOrder ~ order:', order);
    dispatch({
      type: ADD_PAID_ORDERS,
      payload: { id: order.id, order },
    });
  };
  const clickMakeOtherOrder = () => {
    if (getTransactionIdFromLocalStorage() !== null) {
      checkTransaction(getTransactionIdFromLocalStorage() as string);
    }
    dispatch({ type: POST_TAB_REQUEST, payload: 'order' });
    dispatch({ type: CART_REQUEST_EMPTY });
    if (outletId && orgId) {
      const path = generatePathHelpForHomePage('/', { outletId, orgId }, search);
      navigate(path.pathname + path.search);
    }
  };
  return (
    <>
      {detail && detail.name && (
        <Helmet>
          <title>{detail?.name}@nTnQR</title>
          <meta property="og:title" content={detail?.name} />
          <meta name="twitter:title" content={detail?.name} />
          {detail.image && detail.image.imageUrl ? (
            <link rel="icon" href={`${detail.image.imageUrl}`} />
          ) : (
            <>
              <link rel="apple-touch-icon" sizes="180x180" href="/assets/favicons/apple-touch-icon.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/assets/favicons/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/assets/favicons/favicon-16x16.png" />
            </>
          )}

          <link rel="manifest" href="/assets/favicons/site.webmanifest" />
        </Helmet>
      )}
      <div>
        <div className="content-wrapper">
          <div className="content-card">
            <div className="restaurant-profile">
              <img loading="lazy" src={detail?.image.imageUrl} alt={detail?.image.imageUrl} />
            </div>
            <section
              className="section"
              style={{
                paddingTop: '48px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <h2 className="title">{t('order_sent')}</h2>
              <div className="date">
                {t('order')} #{latestOrder[0] ? latestOrder[0].orderTypeSequence : 'N/A'}
              </div>
              <div className="date mt-5">{moment().format('Do MMMM YYYY - hh:mm')}</div>

              {/* <img width="100vw" src={'/assets/images/grocery.gif'} /> */}
              <DeliveryCountDownImages deliveryTime={deliveryTime} createdTime={orderCreatedTime.toString()} />
            </section>

            <section className="section">
              <div className="flex-row space-between total-amount-text">
                <div>{t('order_summary')}</div>
              </div>
              <div className="flex-col pt-16">
                <div className="flex-row space-between" style={{ paddingBottom: 10, paddingTop: 10 }}>
                  <div className="col">{t('order_from')}</div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                    }}
                  >
                    <span>{name}</span>
                  </div>
                </div>
              </div>
              <div className="flex-col">
                {phone && (
                  <div
                    className="flex-row space-between"
                    style={{ paddingBottom: 10, marginTop: '-5px', fontStyle: 'italic', fontSize: '14px' }}
                  >
                    <div className="col">Phone Number:</div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                      }}
                    >
                      <span>+855 {phone}</span>
                    </div>
                  </div>
                )}
                {email && (
                  <div
                    className="flex-row space-between"
                    style={{ paddingBottom: 10, marginTop: '-5px', fontStyle: 'italic', fontSize: '14px' }}
                  >
                    <div className="col">E-mail:</div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                      }}
                    >
                      <span>{email}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex-col pt-16">
                <div className="flex-row space-between" style={{ paddingBottom: 10, paddingTop: 10 }}>
                  <div className="col">{t('delivery_time')}</div>
                  <div>{moment(deliveryTime).format('LLL')}</div>
                </div>
              </div>

              <div className="flex-col pt-16">
                <div className="flex-row space-between" style={{ paddingBottom: 10, paddingTop: 10, gap: '5px' }}>
                  <div className="col" style={{ whiteSpace: 'nowrap' }}>
                    {t('delivery_address')}
                  </div>
                  <div style={{ textAlign: 'right' }}>{deliveryAddress}</div>
                </div>
              </div>
            </section>
            <section className="section">
              <div className="flex-row center">
                {paymentMethods &&
                  paymentMethods.length > 0 &&
                  paymentMethods.map((item: PaymentMethods, index: number) => {
                    return (
                      <div key={index} className="mt-10">
                        {item.name === 'KHQR' ? (
                          <>
                            <div className="flex-row center" style={{ marginBottom: '10px' }}>
                              <p className="bold">{'KHQR'}</p>
                            </div>
                            <div className="flex-row center" style={{ marginBottom: '10px' }}>
                              <MyImages width="120px" src={item.icon.imageUrl} />
                            </div>
                            <div className="flex-row center" style={{ marginBottom: '5px' }}>
                              <p className="bold">{item.accountName}</p>
                            </div>
                            <div className="flex-row center" style={{ marginBottom: '5px' }}>
                              <p className="bold">{item.accountNumber}</p>
                            </div>
                            <div className="flex-row center" style={{ marginBottom: '30px' }}>
                              <p className="bold">
                                ${' '}
                                {totalOrderAmount >= 30
                                  ? totalOrderAmount.toFixed(2)
                                  : (totalOrderAmount + latestOrder[0].deliveryCharge).toFixed(2)}
                              </p>
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
              </div>
              <div className="flex-row space-between total-amount-text">
                <div>{t('order_details')}</div>
              </div>
              <div className="flex-col pt-23">
                {latestOrder[0].items.map((item: Item, index: number) => {
                  const basePrice =
                    item.discountedPrice !== null && item.discountedPrice !== 0 ? item.discountedPrice : item.price;
                  const totalPrice =
                    (basePrice + (item.addOns?.reduce((acc, addon) => acc + addon.price, 0) || 0)) * item.quantity;
                  return (
                    <div
                      key={index}
                      className="flex-row space-between"
                      style={{ paddingBottom: 10, alignItems: 'flex-start' }}
                    >
                      <div className="col">
                        <div>
                          <span style={{ fontWeight: '600' }}>{item.quantity}X</span> {item.name}
                        </div>
                        {item.addOns?.map((addon, index) => (
                          <div key={index}>- {addon.name}</div>
                        ))}
                      </div>
                      <div style={{ whiteSpace: 'nowrap' }}>$ {totalPrice.toFixed(2)}</div>{' '}
                      {/* Ensure two decimal places */}
                    </div>
                  );
                })}
              </div>

              <ChargesSection totalPrice={totalOrderAmount} deliveryCharge={latestOrder[0].deliveryCharge} />
            </section>

            <section className="section">
              <div className="socials mt-20">
                <h3>{name}</h3>
                {socialMedias && socialMedias.length > 0 ? (
                  <>
                    <span>Connect with us on:</span>
                    <div className="flex-row column-gap-10 center">
                      {socialMedias.map((item: socialMedias, index: number) => {
                        return (
                          <div key={index} className="mt-10">
                            {showSocials(item.name, item.url)}
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <div
                  onClick={() => clickMakeOtherOrder()}
                  style={{
                    fontWeight: '400',
                    fontSize: '14px',
                    color: '#909296',
                    alignItems: 'center',
                    display: 'flex',
                    marginTop: '15px',
                    gap: '5px',
                  }}
                >
                  <Icon icon="icon-park-solid:back" />
                  <span>{t('return_to_home')}</span>
                </div>
              </div>
            </section>
          </div>
          <div className="flex-col pt-23">
            <DownloadReceipt />
          </div>
          <div style={{ marginTop: '20px' }}>
            <FooterLogo />
          </div>
        </div>
      </div>
    </>
  );
};
