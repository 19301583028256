import { callingWaiter } from 'actions/outlets';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface TypeProps {
  onClosed: () => void;
  outletId: string;
  spotId: string;
}

const CallForWaiter = (props: TypeProps) => {
  const dispatch = useAppDispatch();
  const [countdown, setCountdown] = useState(999999);
  const [inputValue, setInputValue] = useState('');
  const { detail } = useAppSelector((state) => state.organization);
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const { t } = useTranslation();
  const drawerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    const handleClickOutside = (event: MouseEvent) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
        props.onClosed();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    const intervalId = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    if (countdown === 0) {
      clearInterval(intervalId);
      props.onClosed();
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('mousedown', handleClickOutside);
      clearInterval(intervalId);
    };
  }, [countdown, props]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  }; // get the input from the input feild

  const handleCallButtonClicked = () => {
    dispatch(
      callingWaiter(props.spotId, props.outletId, {
        type: 'Question',
        message: `${inputValue === '' ? 'Assistance Required.' : inputValue} `,
        paymentType: '',
      })
    );
    props.onClosed();
  };

  return (
    <div className="bottom-sheet-wrapper">
      <div className="bottom-sheet-content content" style={{ overflow: 'unset' }} ref={drawerRef}>
        <p className="confirmation-paragraph mt-28">{t('how_can_we_help_you')}</p>
        <input className="call-for-waiter-input" placeholder={t('a_note_to_staff')} onChange={handleInputChange} />
        <div
          className="menu-filter-wrapper"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
            gap: '10px',
            marginTop: '20px',
          }}
        >
          <button
            className="btn primary"
            onClick={props.onClosed}
            style={{ backgroundColor: brandColor, boxShadow: `0px 4px 4px ${brandColor}40`, borderColor: brandColor }}
          >
            {t('cancel')}
          </button>
          <button
            className="btn primary"
            onClick={handleCallButtonClicked}
            style={{ backgroundColor: brandColor, boxShadow: `0px 4px 4px ${brandColor}40`, borderColor: brandColor }}
          >
            {t('call')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CallForWaiter;
