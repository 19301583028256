import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { OutletMenuItem } from 'interfaces/models/menu';
import { useEffect, useState } from 'react';

import { RootState } from '../../../store/configureStore';
import ThumbnailLandscape from './thumbnail-landscape';
import { useTranslation } from 'react-i18next';
import { FILTER_SEARCH_BY_NAME_CHANGE } from 'constants/Filter';

type FilterListingProps = {
  setOpenSearchBox: (value: boolean) => void;
  setFullMenu: (value: boolean) => void;
};
const FilterListing = (props: FilterListingProps) => {
  const { name, category } = useAppSelector((state: RootState) => state.filter);
  const { list } = useAppSelector((state: RootState) => state.menu);
  const [menu, setMenu] = useState<OutletMenuItem[]>([]);
  const { detail } = useAppSelector((state) => state.organization);
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    filterMenu();
  }, [name?.length, category.length, menu.length]);

  const filterMenu = () => {
    localStorage.setItem('isBack', 'true');
    let menu = list;

    if (category.length) {
      menu = list.filter((item: OutletMenuItem) => {
        if (category.includes('Others')) {
          return !item.itemCategory || !item.itemCategory.name || category.includes(item.itemCategory.name);
        }

        return item.itemCategory && item.itemCategory.name && category.includes(item.itemCategory.name);
      });
    }
    if (name) {
      menu = list.filter((item: OutletMenuItem) => {
        const includedName = item.name.toLowerCase().includes(name.toLocaleLowerCase());
        const includedCategory =
          item.itemCategory !== null &&
          item.itemCategory &&
          item.itemCategory.name.toLowerCase().includes(name.toLocaleLowerCase());
        const includedDescription =
          item.description &&
          item.description !== null &&
          item.description.toLowerCase().includes(name.toLocaleLowerCase());
        return includedName || includedCategory || includedDescription;
      });
    }

    setMenu(menu);
  };
  function setFullMenu() {
    dispatch({ type: FILTER_SEARCH_BY_NAME_CHANGE, payload: '' });
  }

  return (
    <div className="filter-title mt-48 text-ellipsis">
      {name && (
        <>
          {t('search_results_for')} “<span className="keyword">{name}</span>” . <br />
          <div
            style={{
              width: '100%',
              overflow: 'hidden',
              whiteSpace: 'normal',
              wordWrap: 'break-word',
            }}
          >
            <div
              style={{
                color: '#afafaf',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
                display: 'block',
              }}
            >
              Didn't find what you're looking for? Try searching for another keyword .
            </div>
          </div>
        </>
      )}
      <div className="filter-listing mt-14 ">
        {menu.length ? (
          menu.map((item: OutletMenuItem) => (
            <div key={item.id}>
              {/* {Math.max(menu?.IrRating?.total)} */}
              <ThumbnailLandscape item={item} />
            </div>
          ))
        ) : (
          <div
            style={{
              width: '100%', // Ensure the container takes up the full width of its parent
              overflow: 'hidden', // Ensure content doesn't overflow the container
              whiteSpace: 'normal', // Ensure text wraps normally
              wordWrap: 'break-word', // Break long words to ensure they don't overflow
            }}
          >
            <div
              style={{
                color: '#afafaf',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
                display: 'block',
              }}
            >
              Looking for something specific? Try searching for a keyword.
              {/* <span
                style={{
                  fontWeight: 700,
                  color: brandColor,
                }}
                onClick={() => props.setOpenSearchBox(true)}
              >
                here
              </span>{' '}
              or check out our
              <span
                style={{
                  fontWeight: 700,
                  color: brandColor,
                }}
                onClick={() => props.setFullMenu(true)}
              >
                {' '}
                full menu
              </span> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterListing;
