import { GET } from '../generics/base-service';
import { IOrganization } from '../interfaces/models/organization';

export function fetchOrganizationInformation(id: string): Promise<IOrganization> {
  const url = `/api/v1/Organization/${id}`;
  return GET<IOrganization>(url);
}
export function fetchAllOrganization(): Promise<any[]> {
  const url = `/api/v1/Organization/search`;
  return GET<any[]>(url);
}
