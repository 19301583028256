// Function to get the current date in the format yyyy-mm-dd
export function getCurrentDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

// Function to get the date 30 days from today in the format yyyy-mm-dd
export function getMaxFutureDate() {
  const today = new Date();
  today.setDate(today.getDate() + 30); // Add 30 days to today's date
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function getConvertedDate(date: string) {
  const inputDateString = date;
  const parts = inputDateString.split(/[- :]/); // Split the string by '-', ' ', and ':'
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Months are 0-based in JavaScript (0 = January, 1 = February, etc.)
  const day = parseInt(parts[2], 10);
  const hours = parseInt(parts[3], 10);
  const minutes = parseInt(parts[4], 10);

  const dateObject = new Date(year, month, day, hours, minutes);

  const formattedDateString =
    dateObject.toDateString() + ' ' + dateObject.toLocaleTimeString() + ' GMT+0700 (Cambodia Standard Time)';

  return formattedDateString;
}

export function formatCustomDate(inputDateStr: string) {
  const inputDate = new Date(inputDateStr);

  // Define month names
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // Extract date components
  const month = monthNames[inputDate.getMonth()];
  const day = inputDate.getDate();
  const year = inputDate.getFullYear();
  const hours = inputDate.getHours();
  const minutes = inputDate.getMinutes();

  // Determine AM/PM
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Format minutes with leading zero
  const formattedMinutes = minutes.toString().padStart(2, '0');

  // Create the formatted date string
  const formattedDateString = `${month} ${day} ${year} ${formattedHours}:${formattedMinutes} ${ampm}`;

  return formattedDateString;
}

export const displayEstimationRange = (minutes: number): string => {
  if (minutes <= 1) return 'Next minutes';
  else if (minutes <= 2) return 'Next 2 mins';
  else if (minutes <= 3) return 'Next 2 - 3 mins';
  else if (minutes <= 4) return 'Next 3 - 4 mins';
  else if (minutes <= 5) return 'Next 3 - 5 mins';
  else if (minutes <= 10) return '5-10 mins';
  else if (minutes <= 20) return '15-20 mins';
  else if (minutes <= 30) return '20-30 mins';
  else if (minutes <= 50) return '40-50 mins';
  else if (minutes <= 60) return '50-60 mins';
  else if (minutes <= 120) return '1-2 hours';
  else if (minutes <= 180) return '2-3 hours';
  else if (minutes <= 240) return '3-4 hours';
  else if (minutes <= 300) return '4-5 hours';
  else if (minutes <= 360) return '5-6 hours';
  else if (minutes <= 420) return '6-7 hours';
  else if (minutes <= 480) return '7-8 hours';
  else if (minutes <= 540) return '8-9 hours';
  else if (minutes <= 600) return '9-10 hours';
  else if (minutes <= 660) return '10-11 hours';
  else if (minutes <= 720) return '11-12 hours';
  else if (minutes <= 1440) return '12-24 hours';
  else if (minutes <= 2880) return '1-2 days';
  else if (minutes <= 4320) return '2-3 days';
  else if (minutes <= 5760) return '3-4 days';
  else if (minutes <= 7200) return '4-5 days';
  else if (minutes <= 8640) return '5-6 days';
  else if (minutes <= 10080) return '6-7 days';
  else if (minutes <= 11520) return '7-8 days';
  else if (minutes <= 12960) return '8-9 days';
  else if (minutes <= 14400) return '9-10 days';
  else if (minutes <= 15840) return '10-11 days';
  else if (minutes <= 17280) return '11-12 days';
  else if (minutes <= 18720) return '12-13 days';
  else if (minutes <= 20160) return '13-14 days';
  else if (minutes <= 21600) return '14-15 days';
  else if (minutes <= 23040) return '15-16 days';
  else if (minutes <= 24480) return '16-17 days';
  else if (minutes <= 25920) return '17-18 days';
  else if (minutes <= 27360) return '18-19 days';
  else if (minutes <= 28800) return '19-20 days';
  else if (minutes <= 30240) return '20-21 days';
  else if (minutes <= 31680) return '21-22 days';
  else if (minutes <= 33120) return '22-23 days';
  else if (minutes <= 34560) return '23-24 days';
  else if (minutes <= 36000) return '24-25 days';
  else if (minutes <= 37440) return '25-26 days';
  else if (minutes <= 38880) return '26-27 days';
  else if (minutes <= 40320) return '27-28 days';
  else if (minutes <= 41760) return '28-29 days';
  else if (minutes <= 43200) return '29-30 days';
  else return '30+ days';
};
