import { Icon } from '@iconify/react';
import { useEffect, useRef, useState } from 'react';
import ChargesSection from '../ChargesSection';
import { AddOn } from 'interfaces/models/menu';
import { CART_REQUEST_ADDED, CART_REQUEST_EMPTY } from 'constants/carts';
import { Carts, Item } from 'interfaces/models/carts';
import { OrderStatus } from 'enums/status';
import { calculateDiscountedPrice } from 'cores/components';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { generatePathHelpForHomePage, getSpotId } from 'helper/path';
import { getSpotById } from 'helper/spot';
import { RootState } from 'store/configureStore';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { storePreviousOrder } from 'helper/order-summary';
import { createOrder } from 'actions/order';
import { useCalculateCharge } from 'hooks/useCalculateCharges';
import { LoadingSpinner } from '../LoadingSpinner';
import { Oval } from 'react-loader-spinner';
import { t } from 'i18next';
import OutletBusyDialog from '../outletBusyDialog';

type QuickOrderProps = {
  brandColor: string;
  price: number;
  outlet: any;
  onClose: () => void;
  menu: any;
  quantity: number;
  addOns: AddOn[];
  isValid: boolean;
  setIsSubmitting: () => void;
  specialRequest: string;
};
export const QuickOrderConfirmation = ({
  brandColor,
  price,
  outlet,
  onClose,
  menu,
  quantity,
  addOns,
  isValid,
  setIsSubmitting,
  specialRequest,
}: QuickOrderProps) => {
  const navigate = useNavigate();
  const search = useLocation().search;

  const refBottomSheet = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { outletId, orgId } = useParams();

  const spotId = getSpotId();
  const spot = getSpotById(spotId);

  const { subTotal, totalSum } = useCalculateCharge(price);

  const [loading, setLoading] = useState(false);
  const { items } = useAppSelector((state: RootState) => state.carts);
  const [quickOrderItems, setQuickOrderItem] = useState<Item[]>([]);

  const outlets = useAppSelector((state: RootState) => state.outlets.list);
  const filteredOutlet = outlets.filter((x) => x.id === outletId);
  const [isOutletBusy, setIsOutletBusy] = useState(filteredOutlet[0].orderHoursOverrideStatus === 3);
  const QuickOrder = () => {
    setIsSubmitting();
    if (menu && isValid && isValid === true) {
      let type = CART_REQUEST_ADDED;
      const payload: Carts = {
        price: price,
        status: OrderStatus.Open,
        items: {
          name: menu.name,
          menuId: menu.id,
          description: menu.description,
          specialRequest: specialRequest,
          quantity,
          addOns,
          imageUrl: menu.image.imageUrl,
          price: menu.basicPrice,
          promotion: menu.promotion,
          discountedPrice: menu.promotion
            ? calculateDiscountedPrice(menu.promotion.value, menu.promotion.discountType, menu.basicPrice)
            : 0,
        },
      };
      if (quantity > 0) {
        dispatch({ type, payload });
        setLoading(true);
      }
    } else {
      onClose();
    }
  };
  useEffect(() => {
    if (items.length > 0) {
      setQuickOrderItem(items);
    }
  }, [items]);

  useEffect(() => {
    if (quickOrderItems.length > 0) {
      onClickSubmitOrder();
    }
  }, [quickOrderItems]);

  const onClickSubmitOrder = () => {
    localStorage.setItem('menuListingScrollPosition', '0');
    if (spotId && outlet && outletId) {
      if (quickOrderItems && quickOrderItems.length) {
        storePreviousOrder(items);
        const param = {
          spotId: spotId,
          outletId,
          body: {
            items: quickOrderItems,
            status: 0,
            price: outlet?.areChargesIncluded ? totalSum : subTotal,
            discountedprice: price,
          },
        };
        Promise.resolve(dispatch(createOrder(param)))
          .then(() => {
            successAction();
            dispatch({ type: CART_REQUEST_EMPTY });
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };
  const successAction = () => {
    if (outletId && orgId) {
      const path = generatePathHelpForHomePage('/order-summary', { outletId, orgId }, search);
      navigate(path.pathname + path.search);
    }
    setLoading(false);
  };
  return (
    <div className="bottom-sheet-wrapper" ref={refBottomSheet}>
      {isOutletBusy && <OutletBusyDialog setIsOutletBusy={(isOpen: boolean) => setIsOutletBusy(isOpen)} />}

      <div className="bottom-sheet-content padding">
        <div className="navigation-wrapper pickup-information">
          <div
            className={`title quick-order-header-title`}
            style={{
              color: brandColor,
            }}
          >
            <p>{t('please_confirm_your_quick_order')}</p>
          </div>
          <div className="quick-order-header-confirmation">
            {t('are_you_sure_you_want_to_order')}{' '}
            <span
              className="menu-name"
              style={{
                color: brandColor,
              }}
            >
              {menu.name}.
            </span>
          </div>
          <div className="quick-order-divider"></div>
          <div className="flex-row space-between total-amount-text">
            <div>{t('order_details')}</div>
          </div>
          <div className="flex-col quick-order-pt-15">
            <div className="flex-row space-between quick-order-addons">
              <div className="col">
                <div>
                  <span className="quick-order-quantity">{quantity}X</span> {menu.name}
                </div>
                {addOns?.map((addon, index) => (
                  <div key={index}>- {addon.name}</div>
                ))}
                <div
                  style={{
                    fontSize: '13px',
                    color: '#7b7b7b',
                    fontStyle: 'italic',
                    marginTop: '5px',
                  }}
                >
                  {specialRequest}
                </div>
              </div>
              <div className="quick-order-price">$ {price.toFixed(2)}</div>
            </div>
          </div>
          <ChargesSection totalPrice={price} />
          <div className="quick-order-button-container">
            <div
              className="btn primary pd-12 flex-row space-between submit-order-pickup quick-order-button-close"
              onClick={() => onClose()}
            >
              <div className="flex-row center column-gap-10">
                <span>{t('cancel')}</span>
              </div>
              <div className="flex-row column-gap-16">
                <Icon icon="maki:cross" />
              </div>
            </div>
            <div
              className="btn primary pd-12 flex-row space-between submit-order-pickup"
              onClick={() => QuickOrder()}
              style={{
                backgroundColor: brandColor,
                boxShadow: `0px 4px 4px ${brandColor}40`,
                borderColor: brandColor,
              }}
            >
              <div className="flex-row center ">
                <span>{t('order_now')}</span>
              </div>
              {!loading ? (
                <div className="flex-row column-gap-16">
                  <img src="/assets/icons/next-white.svg" alt="" />
                </div>
              ) : (
                <Oval
                  height={15}
                  width={15}
                  color="#FFFF"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor={brandColor ? brandColor : '#FFB26B'}
                  strokeWidth={5}
                  strokeWidthSecondary={5}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
