import { Item } from 'interfaces/models/carts';
import { OutletMenuItem, Promotion } from 'interfaces/models/menu';
import { useEffect } from 'react';

import { isEditingCart } from '../../../helper/cart';

type TypeProps = {
  item: OutletMenuItem | null;
  isOutofStock?: boolean;
  quantity?: number;
};
export function calculateDiscountedPrice(value: number, discountType: number, basicPrice: number) {
  if (discountType === 1) {
    let price = basicPrice - value;
    price < 0 ? (price = basicPrice) : '';
    return price;
  } else if (discountType === 0) {
    const removeprice = (basicPrice * value) / 100;
    return basicPrice - removeprice;
  } else {
    return basicPrice;
  }
}

const DiscountPrice = ({ item, isOutofStock, quantity }: TypeProps) => {
  if (item?.promotion && (item.promotion.discountType == 0 || item.promotion.discountType == 1)) {
    return (
      <>
        <div className="price">
          ${' '}
          {(
            calculateDiscountedPrice(item?.promotion.value ?? 0, item?.promotion.discountType ?? 0, item.basicPrice) ||
            0
          ).toFixed(2)}
        </div>
        {!isOutofStock && quantity === 0 && (
          <div className="price thumbnail-old-pricing">$ {(item?.basicPrice || 0).toFixed(2)}</div>
        )}
      </>
    );
  } else {
    return <div className="price">$ {(item?.basicPrice || 0).toFixed(2)}</div>;
  }
};

export default DiscountPrice;
