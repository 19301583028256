import { HTMLInputTypeAttribute, useEffect, useState } from 'react';

import { AddOn, MenuOptionItem } from '../../../interfaces/models/menu';
import { useAppSelector } from 'hooks/redux';
import { Icon } from '@iconify/react';

type OptionAttributesProps = {
  name: string;
  defaultChecked?: boolean;
  item: MenuOptionItem;
  type: HTMLInputTypeAttribute;
  hasSoldOut?: boolean;
  _id: string;
  groupId: number;
  selectedValue?: string;
  //
  onClicked?: (param: MenuOptionItem) => void;
  onRemoved?: (param: MenuOptionItem) => void;
  setSelectedValue: (value: string) => void;
  maximum?: string;
  isMaxSelection?: boolean;
  disabledOptions?: any[];
  selectedOptions: any[];
  sequence: number;
};

const OptionAttributes = (props: OptionAttributesProps) => {
  const {
    defaultChecked = false,
    type = 'CHECKBOX',
    _id,
    onClicked,
    item,
    setSelectedValue,
    selectedValue,
    isMaxSelection,
    disabledOptions,
    groupId,
    selectedOptions,
    sequence,
  } = props;

  const { option, price, category } = item;
  const [checked, setChecked] = useState(defaultChecked);
  useEffect(() => {
    if (defaultChecked && onClicked) {
      onClicked(item);
    }
  }, [defaultChecked]);
  useEffect(() => {
    if (selectedOptions[groupId] && type === 'CHECKBOX') {
      setChecked(
        selectedOptions[groupId].some(
          (selectedOption: any) => selectedOption.option === option && selectedOption.sequence === sequence
        )
      );
    }
  }, [selectedOptions]);

  const { detail } = useAppSelector((state) => state.organization);
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#dd5f36';
  const clickedHappened = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (type === 'CHECKBOX') {
      BubbleSelection();
    } else {
      if (type === 'RADIO') {
        setSelectedValue(nameInput + _id);
      }

      const inputChecked = !checked;
      const value: MenuOptionItem = { index: _id, ...item };

      if (onClicked && inputChecked) {
        onClicked(value);
      }

      if (!inputChecked && props.onRemoved) {
        props.onRemoved(value);
      }

      setChecked(inputChecked);
    }
  };
  const BubbleSelection = () => {
    const inputChecked = !checked;

    const value: MenuOptionItem = { index: _id, ...item };
    if (!inputChecked && props.onRemoved) {
      props.onRemoved(value);
      if (onClicked && inputChecked) {
        onClicked(value);
      }
      setChecked(inputChecked);
    }
    const checkDisabledOption = disabledOptions?.includes(value.category);
    if (!checkDisabledOption) {
      if (onClicked && inputChecked) {
        onClicked(value);
      }
      setChecked(inputChecked);
    }
  };
  // const nameInput =
  //   type === 'RADIO' ? `RADIO-${category}` : type === 'BUBBLE' ? `RADIO-${category}-${_id}` : `${name}+-${_id}`;
  const nameInput = type === 'RADIO' ? `radio-${category}` : `CHECKBOX-${category}-${_id}`;
  // console.log(
  //   '🚀 ~ file: option-attributes.tsx:107 ~ OptionAttributes ~ nameInput:',
  //   type === 'BUBBLE' ? nameInput + _id : ''
  // );

  return (
    <>
      {/* {type === 'BUBBLE' && (
        <>
          <div
            id={nameInput + _id}
            className={`flex-row radioItem`}
            style={{
              padding: '10px',
              width: 'fit-content',
              height: '40px',
              background: `${checked === true ? brandColor : 'none'}`,
              border: `${checked === true ? '2px solid' : '2px solid'}`,
              borderColor: `${brandColor}`,
              transition: 'all 0.3s ease',
            }}
            onClick={clickedHappened}
          >
            {checked ? (
              <Icon icon="line-md:circle-to-confirm-circle-transition" color={'#fff'} width={20} />
            ) : (
              <Icon icon="line-md:confirm-circle-to-circle-transition" color={brandColor} width={20} />
            )}
            <p
              style={{ marginLeft: '5px', color: `${checked === true ? '#fff' : brandColor}`, whiteSpace: 'nowrap' }}
              className="labels"
            >
              <span style={{ whiteSpace: 'nowrap' }}>{option}</span> {price > 0 && `+$${price.toFixed(2)}`}
            </p>
          </div>
        </>
      )} */}
      {type === 'CHECKBOX' ? (
        <div className="flex-row space-between mt-15">
          {/* <div className="flex-row column-gap-10 "> */}
          {/* <input
              id={nameInput + _id}
              className="check-box"
              name={nameInput}
              type={type}
              defaultChecked={defaultChecked}
              value={`${checked}`}
              disabled={props.hasSoldOut ?? false}
              onClick={clickedHappened}
              style={{ accentColor: brandColor, width: '20px', height: '20px' }}
            />
            <label htmlFor={nameInput + _id} className="labels">
              {option}
            </label> */}
          {/* <label
              className="check-container"
              style={{
                ...(checked && ({ '--checkbox-color': brandColor } as React.CSSProperties)),
              }}
            >
              {option}
              <input
                id={nameInput + _id}
                name={nameInput}
                type={type}
                defaultChecked={defaultChecked}
                value={`${checked}`}
                disabled={props.hasSoldOut ?? false}
                onClick={clickedHappened}
              />
              <span
                className="check-checkmark"
                style={{
                  border: `1px solid ${brandColor}`,
                }}
              ></span>
            </label> */}
          <div
            id={nameInput + _id}
            className={`flex-row `}
            style={{
              background: 'none',
              transition: 'all 0.3s ease',
            }}
            onClick={clickedHappened}
          >
            {checked ? (
              <Icon icon="material-symbols:check-box-rounded" color={brandColor} width={25} />
            ) : (
              <Icon icon="material-symbols:check-box-outline-blank" color={brandColor} width={25} />
            )}
            <p style={{ marginLeft: '5px', whiteSpace: 'nowrap' }} className="labels">
              <span style={{ whiteSpace: 'nowrap' }}>{option}</span>
            </p>
          </div>
          {/* </div> */}
          {price > 0 && <div>+&nbsp;$&nbsp;{price ? price.toFixed(2) : 0}</div>}
        </div>
      ) : (
        <div className="flex-row space-between mt-15">
          <div className="flex-row column-gap-10 ">
            {/* <input
              id={nameInput + _id}
              className="radio"
              name={nameInput}
              type="radio"
              defaultChecked={defaultChecked}
              value={`${checked}`}
              disabled={props.hasSoldOut ?? false}
              onClick={clickedHappened}
              style={{ accentColor: brandColor, width: '20px', height: '20px', color: brandColor }}
            />
            <label htmlFor={nameInput + _id} className="labels">
              {option}
            </label> */}
            <label
              className="radio-container"
              style={{
                ...({ '--radio-color': brandColor } as React.CSSProperties),
              }}
            >
              {option}
              <input
                id={nameInput + _id}
                name={nameInput}
                className="radio"
                type="radio"
                defaultChecked={defaultChecked}
                value={`${checked}`}
                disabled={props.hasSoldOut ?? false}
                onClick={clickedHappened}
              />
              <span
                className="radio-checkmark"
                style={{
                  border: `1px solid ${brandColor}`,
                }}
              ></span>
            </label>
          </div>
          {price > 0 && <div>+&nbsp;$&nbsp;{price ? price.toFixed(2) : 0}</div>}
        </div>
      )}
      {/* {type === 'CHECKBOX' && (
        <div className="flex-row space-between mt-15">
          <div className="flex-row column-gap-10 ">
            <input
              id={nameInput + _id}
              className="check-box"
              name={nameInput}
              type={type}
              defaultChecked={defaultChecked}
              value={`${checked}`}
              disabled={props.hasSoldOut ?? false}
              onClick={clickedHappened}
              style={{ accentColor: brandColor }}
            />
            <label htmlFor={nameInput + _id} className="labels">
              {option}
            </label>
          </div>
          {price > 0 && <div>+${price ? price.toFixed(2) : 0}</div>}
        </div>
      )} */}
      {/* {type === 'RADIO' && (
        <div className="flex-row space-between mt-15">
          <div className="flex-row column-gap-10 ">
            <input
              id={nameInput + _id}
              className="check-box"
              name={nameInput}
              type="radio"
              defaultChecked={defaultChecked}
              value={`${checked}`}
              disabled={props.hasSoldOut ?? false}
              onClick={clickedHappened}
              style={{ accentColor: brandColor }}
            />
            <label htmlFor={nameInput + _id} className="labels">
              {option}
            </label>
          </div>
          {price > 0 && <div>+${price ? price.toFixed(2) : 0}</div>}
        </div>
      )} */}
      {/* {type === 'DROPDOWN' && (
        <option value={_id} disabled={props.hasSoldOut ?? false} selected={defaultChecked}>
          <span className="drop-down-text">{option}</span>
          {price > 0 && <span> (+${price ? price.toFixed(2) : 0})</span>}
        </option>
      )} */}
    </>
  );
};

export default OptionAttributes;
