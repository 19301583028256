import { callingWaiter } from 'actions/outlets';
import { MyImages } from 'cores/components';
import { PaymentType, PaymentName } from 'enums/payment-type';
import { PaymentMethods, PaymentTypeUnion } from 'interfaces/models/outlets';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
import { getSpotId } from '../../../helper/path';
import { Icon } from '@iconify/react';
import { RootState } from 'store/configureStore';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner } from '../LoadingSpinner';
import CryptoJS from 'crypto-js';
import {
  SPOT_CINEMA_COUNTER,
  SPOT_CINEMA_HALL,
  SPOT_CINEMA_SEAT,
  SPOT_DELIVERY,
  SPOT_DINE_IN,
  SPOT_QRTABLE,
  SPOT_RESERVATION_PREORDER,
  SPOT_TABLE_RESERVATION,
} from 'constants/spot';
import { getSpotById } from 'helper/spot';
import { FooterLogo } from '../FooterLogo';
import { getLatestOrder } from '../../../helper/order-summary';

declare class AbaPay {}
type TypProps = {
  cancelCalled: () => void;
  clickedSubmit: (param: PaymentTypeUnion, method: string, notes?: string) => void;
  totalSum?: number;
  cinemaInfo?: any;
  // setPaymentMethod?: (param: string) => void;
};

const SelectPaymentMethod = ({ cancelCalled, clickedSubmit, totalSum, cinemaInfo }: TypProps) => {
  const [list] = useState(getLatestOrder());
  const dispatch = useDispatch();
  const { outletId } = useParams();
  const spotId = getSpotId();
  const spot = getSpotById(spotId);
  const paymentMethods = useAppSelector((state: RootState) => state.outlets.list);
  const filteredList = paymentMethods.filter((item) => item.id === outletId);

  const [openNote, setOpenNote] = useState(false);
  const [note, setNote] = useState('');
  const { detail } = useAppSelector((state) => state.organization);
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const [paymentLoading, setPaymentLoading] = useState(false);

  const { t } = useTranslation();
  function clickPaymentMethod(type: PaymentTypeUnion, method: string, cashNote?: string) {
    console.log('🚀 ~ clickPaymentMethod ~ method:', method, type);
    const filteredList = list && list.filter((item) => item.outletId === outletId && item.outletSpotId === spotId);

    localStorage.setItem('paymentMethod', method);
    if (type === 'CASHWITHNOTE') {
      if (openNote) {
        clickedSubmit(type, method, cashNote);
      }
    } else {
      clickedSubmit(type, method);
    }
    switch (type) {
      case 'CASHWITHNOTE':
        if (openNote) {
          callForWaiter(type, cashNote);
          cancelCalled();
          setOpenNote(false);
        } else {
          setOpenNote(true);
        }

        break;
      case 'PAYONLINE':
        // if (method === 'ABAAKHPP') {
        //   const reqTime = +new Date();
        //   const merchantId: string | undefined = process.env.REACT_APP_MERCHANT_ID as string;
        //   const apiKey: string | undefined = process.env.REACT_APP_API_KEY_PAYMENT as string;
        //   const baseURL = 'https://checkout-sandbox.payway.com.kh/api/payment-gateway/v1/payments/purchase';
        //   const tranId = filteredList[0]?.orderTypeSequence;

        //   const amount = totalSum;
        //   const option = 'abapay_khqr';
        //   const success = `${process.env.REACT_APP_REDIRECT_SUCCESS}/org/${detail?.id}/outlet/${outletId}/rating-menu?spotId=${spotId}`;
        //   const hmac = CryptoJS.HmacSHA512(reqTime + merchantId + tranId + amount + option + success, apiKey);
        //   const signature = CryptoJS.enc.Base64.stringify(hmac);
        //   const _data = {
        //     continue_success_url: success,
        //     form_url: baseURL,
        //     tran_id: tranId,
        //     merchant_id: merchantId,
        //     req_time: reqTime,
        //     amount,
        //     hash: signature,
        //     payment_option: option,
        //     onSuccess: (data: any) => {},
        //     onError(data: any) {
        //       console.log(data);
        //     },
        //   };

        //   // eslint-disable-next-line no-undef
        //   const obj: any = new AbaPay();
        //   obj.checkout(_data);
        // } else {
        //   callForWaiter(type);
        // }
        callForWaiter(type);
        cancelCalled();

        break;

      default:
        callForWaiter(type);
        cancelCalled();
        break;
    }
  }
  const callForWaiter = (type: PaymentTypeUnion, cashNote?: string) => {
    const param = {
      type: 'Call for Bill',
      message: type === 'CASHWITHNOTE' && cashNote ? `${cashNote}` : '',
      paymentType: PaymentType[type],
    };
    if (
      outletId &&
      spotId &&
      spot.type != SPOT_CINEMA_COUNTER &&
      spot.type != SPOT_CINEMA_HALL &&
      spot.type != SPOT_CINEMA_SEAT
    ) {
      dispatch(callingWaiter(spotId, outletId, param));
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  function paymentMethodButton(type: string) {
    let name = '';
    let methodName: PaymentTypeUnion = 'CASH';
    let img;
    let paymentMethodOnline = '';
    let fee = 0;

    const handleClick = (type: PaymentTypeUnion, onlineMethod: string) => {
      clickPaymentMethod(type, onlineMethod);
    };
    switch (type) {
      case PaymentName.CASHWITHNOTE:
        name = t('cash_w_note');
        methodName = 'CASHWITHNOTE';
        img = <Icon icon="fluent:money-hand-24-regular" width={40} color={brandColor} />;
        paymentMethodOnline = '';
        break;
      case PaymentName.VISA_MASTER:
        paymentMethodOnline = '';
        name = 'Credit/Debit Card (Offline)';
        methodName = 'VISA_MASTER';
        img = (
          <img
            src="/assets/icons/creditdebitcard.png"
            style={{
              width: '40px',
              borderRadius: '0.5rem',
            }}
          />
        );
        break;
      case PaymentName.CARDONLINE:
        paymentMethodOnline = 'CARDONLINE';
        name = 'Credit/Debit Card (Online)';
        methodName = 'PAYONLINE';
        img = (
          <img
            src="/assets/icons/creditdebitcard.png"
            style={{
              width: '40px',
              borderRadius: '0.5rem',
            }}
          />
        );
        break;
      case PaymentName.WECHAT:
        paymentMethodOnline = 'WECHAT';
        name = 'WeChat';
        methodName = 'PAYONLINE';
        img = (
          <img
            src="/assets/icons/wechat.png"
            style={{
              width: '40px',
              borderRadius: '0.5rem',
            }}
          />
        );
        break;
      case PaymentName.ALIPAY:
        paymentMethodOnline = 'ALIPAY';
        name = 'Alipay';
        methodName = 'PAYONLINE';
        img = (
          <img
            src="/assets/icons/alipay.png"
            style={{
              width: '40px',
              borderRadius: '0.5rem',
            }}
          />
        );
        break;
      case PaymentName.ABA:
        paymentMethodOnline = 'ABAAKHPP';
        name = 'ABA KHQR';
        methodName = 'PAYONLINE';
        img = (
          <img
            src="/assets/icons/khqr-red.png"
            style={{
              width: '40px',
              borderRadius: '0.5rem',
            }}
          />
        );
        break;
      case PaymentName.CC:
        name = t('credit_card');
        methodName = 'CC';
        img = <Icon icon="majesticons:creditcard" width={40} color={brandColor} />;
        paymentMethodOnline = '';
        break;
      case PaymentName.QR:
        name = t('scan_khqr');
        methodName = 'QR';
        img = (
          <img
            src="/assets/icons/khqr-red.png"
            style={{
              width: '40px',
              borderRadius: '0.2rem',
            }}
          />
        );
        paymentMethodOnline = '';
        break;
      case PaymentName.BILL:
        name = t('bill_to_room');
        methodName = 'BILL';
        img = <Icon icon="uil:bill" width={40} color={brandColor} />;
        paymentMethodOnline = '';
        break;

      default:
        paymentMethodOnline = '';
        name = t('cash');
        methodName = 'CASH';
        img = <Icon icon="fluent:money-hand-24-regular" width={40} color={brandColor} />;
        break;
    }
    return (
      <button
        onClick={() => handleClick(methodName, paymentMethodOnline)}
        className=" column-gap-10 btn light-primary mt-15"
        style={{ padding: '10px 10px', borderColor: brandColor }}
      >
        <div
          key={name}
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {img}
          <div>
            <div style={{ color: '#474747', textAlign: 'left', fontSize: '15px', fontWeight: '550' }}>{name}</div>
            {paymentMethodOnline == 'ABAAKHPP' && (
              <div
                style={{ textAlign: 'left', fontSize: '13px', color: '#697386', fontWeight: '400', lineHeight: '14px' }}
              >
                Scan to pay with any banking app
              </div>
            )}
            {paymentMethodOnline == 'WECHAT' && (
              <div
                style={{ textAlign: 'left', fontSize: '13px', color: '#697386', fontWeight: '400', lineHeight: '14px' }}
              >
                Scan to pay with WeChat
              </div>
            )}
            {paymentMethodOnline == 'ALIPAY' && (
              <div
                style={{ textAlign: 'left', fontSize: '13px', color: '#697386', fontWeight: '400', lineHeight: '14px' }}
              >
                Scan to pay with any Alipay
              </div>
            )}
            {methodName == 'CASH' && (
              <div
                style={{ textAlign: 'left', fontSize: '13px', color: '#697386', fontWeight: '400', lineHeight: '14px' }}
              >
                Pay with cash
              </div>
            )}
            {methodName == 'CASHWITHNOTE' && (
              <div
                style={{ textAlign: 'left', fontSize: '13px', color: '#697386', fontWeight: '400', lineHeight: '14px' }}
              >
                Pay with cash and specify note
              </div>
            )}
            {(paymentMethodOnline === 'CARDONLINE' || paymentMethodOnline === 'VISA_MASTER') && (
              <div style={{ textAlign: 'left' }}>
                <img
                  src="/assets/icons/4cards.png"
                  style={{
                    width: '50%',
                  }}
                />
              </div>
            )}
          </div>
          {fee > 0 && (
            <span
              style={{
                color: brandColor,
                fontSize: '14px',
                fontWeight: '450',
                width: '23%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <span style={{ fontWeight: '550' }}>Fee:&nbsp;</span>${Number(fee).toFixed(2)}
            </span>
          )}
        </div>
      </button>
    );
  }
  const calculateThreePercent = (total: number): number => {
    const threePercent = (3 / 100) * total;
    return threePercent;
  };
  return (
    <div className="blur-background call-stuff-for-bill-wrapper opacity">
      <div className="overlay" onClick={cancelCalled}></div>
      {openNote ? (
        <div className="content">
          <div className="your-payment-method-txt">{t('specify_payment_note')}</div>
          <div
            style={{
              gap: '5px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: 'center',
            }}
          >
            <div style={{ gap: '5px', display: 'flex', flexDirection: 'column', width: '50%' }}>
              <button
                onClick={() => {
                  clickPaymentMethod('CASHWITHNOTE', '', 'Payment Note - 10$'), setNote('Payment Note - 10$');
                }}
                className=" btn light-primary "
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '10px 20px',
                  borderColor: brandColor,
                  color: brandColor,
                }}
              >
                <Icon icon="material-symbols:payments-outline" width={28} />
                <span style={{ width: '80%' }}>10$</span>
              </button>
              <button
                onClick={() => {
                  clickPaymentMethod('CASHWITHNOTE', '', 'Payment Note - 20$'), setNote('Payment Note - 20$');
                }}
                className=" btn light-primary "
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '10px 20px',
                  borderColor: brandColor,
                  color: brandColor,
                }}
              >
                <Icon icon="material-symbols:payments-outline" width={28} />
                <span style={{ width: '80%' }}>20$</span>
              </button>
              <button
                onClick={() => {
                  clickPaymentMethod('CASHWITHNOTE', '', 'Payment Note - 50$'), setNote('Payment Note - 50$');
                }}
                className=" btn light-primary "
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '10px 20px',
                  borderColor: brandColor,
                  color: brandColor,
                }}
              >
                <Icon icon="material-symbols:payments-outline" width={28} />
                <span style={{ width: '80%' }}>50$</span>
              </button>
              <button
                onClick={() => {
                  clickPaymentMethod('CASHWITHNOTE', '', 'Payment Note - 100$'), setNote('Payment Note - 100$');
                }}
                className=" btn light-primary "
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '10px 20px',
                  borderColor: brandColor,
                  color: brandColor,
                }}
              >
                <Icon icon="material-symbols:payments-outline" width={28} />
                <span style={{ width: '80%' }}>100$</span>
              </button>
            </div>
            <div
              style={{
                gap: '5px',
                display: 'flex',
                flexDirection: 'column',
                width: '50%',
              }}
            >
              <button
                onClick={() => {
                  clickPaymentMethod('CASHWITHNOTE', '', 'Payment Note - 10,000៛'), setNote('Payment Note - 10,000៛');
                }}
                className=" btn light-primary mt-35"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '10px 20px',
                  borderColor: brandColor,
                  color: brandColor,
                }}
              >
                <Icon icon="material-symbols:payments-outline" width={28} />
                <span style={{ width: '80%' }}>10,000៛</span>
              </button>
              <button
                onClick={() => {
                  clickPaymentMethod('CASHWITHNOTE', '', 'Payment Note - 20,000៛'), setNote('Payment Note - 20,000៛');
                }}
                className=" btn light-primary "
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '10px 20px',
                  borderColor: brandColor,
                  color: brandColor,
                }}
              >
                <Icon icon="material-symbols:payments-outline" width={28} />
                <span style={{ width: '80%' }}>20,000៛</span>
              </button>
              <button
                onClick={() => {
                  clickPaymentMethod('CASHWITHNOTE', '', 'Payment Note - 50,000៛'), setNote('Payment Note - 50,000៛');
                }}
                className=" btn light-primary "
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '10px 20px',
                  borderColor: brandColor,
                  color: brandColor,
                }}
              >
                <Icon icon="material-symbols:payments-outline" width={28} />
                <span style={{ width: '80%' }}>50,000៛</span>
              </button>
              <button
                onClick={() => {
                  clickPaymentMethod('CASHWITHNOTE', '', 'Payment Note - 100,000៛'), setNote('Payment Note - 100,000៛');
                }}
                className=" btn light-primary "
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '10px 20px',
                  borderColor: brandColor,
                  color: brandColor,
                }}
              >
                <Icon icon="material-symbols:payments-outline" width={28} />
                <span style={{ width: '80%' }}>100,000៛</span>
              </button>
            </div>
          </div>
          <div style={{ marginTop: '30px', marginBottom: '-3vh' }}>
            <FooterLogo />
          </div>
        </div>
      ) : (
        <div
          className="content"
          style={{
            maxHeight: '70vh',
            overflowY: 'scroll',
          }}
        >
          <div className="your-payment-method-txt">{t('your_payment_method')}</div>
          {filteredList[0]?.paymentMethods && filteredList[0]?.paymentMethods.length > 0 ? (
            filteredList[0].paymentMethods.map((item: PaymentMethods, index: number) => {
              if (item.name !== 'Bill to room' && spot.type != SPOT_DINE_IN)
                return <div key={index}>{paymentMethodButton(item.name)}</div>;
            })
          ) : (
            <>
              {spot.type != SPOT_RESERVATION_PREORDER && (
                <button
                  onClick={() => clickPaymentMethod('CASH', '')}
                  className="flex-row center column-gap-10 btn light-primary mt-35"
                  style={{ padding: '19px 0' }}
                >
                  <MyImages src="/assets/icons/wallet.svg" />
                  <span>Cash</span>
                </button>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectPaymentMethod;
