import { getSpotId } from 'helper/path';
import { getSpotById } from 'helper/spot';
import { useAppSelector } from 'hooks/redux';
import { IGroupMenu, OutletMenuItem } from 'interfaces/models/menu';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { RootState } from 'store/configureStore';
import ThumbnailLandscape from './thumbnail-landscape';
import ThumbnailHorizontal from './thumbnail-horizontal';
import { FooterLogo } from 'components/partials/FooterLogo';
import ThumbnailLandscapeNoImage from './thumbnail-landscape-no-image';

interface ListingSectionsProps {
  categoryDisplayed: (categoryName: string) => void;
  openSearchBox?: boolean;
}
const ListingSections = (props: ListingSectionsProps) => {
  const { categoryDisplayed, openSearchBox } = props;
  const groupMenu = useAppSelector((state: RootState) => state.menu.grouped);
  const sortedGroupMenu = groupMenu.sort((a: IGroupMenu, b: IGroupMenu) => a.sequence - b.sequence);
  const location = useLocation();
  const { name, category } = useAppSelector((state: RootState) => state.filter);

  const [spotId, setSpotId] = useState<string>('');
  const spotList = useAppSelector((state: RootState) => state.spot.list.filter((spot) => spot.id === spotId));
  const filteredSpot = spotList.find((spot) => spot.id === spotId);
  const spotIdd = getSpotId();
  const spot = getSpotById(spotIdd);
  const categoryRefs: any = {};

  groupMenu.forEach((item) => {
    const categoryName = item.category || 'No Category';
    categoryRefs[categoryName] = useRef(null);
  });
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const spotId = searchParams.get('spotId');
    setSpotId(spotId || '');
  }, [location]);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleItemClick = (item: IGroupMenu) => {
    setScrollPosition(window.scrollY);
    localStorage.setItem('menuListingScrollPosition', window.scrollY.toString());
  };

  const scrollToCategory = (categoryName: string) => {
    if (categoryRefs[categoryName] && categoryRefs[categoryName].current) {
      const element = categoryRefs[categoryName].current;
      const elementRect = element.getBoundingClientRect();
      const absoluteY = elementRect.top + window.scrollY;

      window.scrollTo({
        top: absoluteY - window.innerHeight / 4,
        behavior: 'smooth',
      });
    }
  };
  useEffect(() => {
    const storedScrollPosition = localStorage.getItem('menuListingScrollPosition');
    const isBack = localStorage.getItem('isBack');
    if (storedScrollPosition !== null && isBack && isBack.toString() === 'true' && !openSearchBox) {
      window.scrollTo(0, parseInt(storedScrollPosition));
      handleScroll();
      setTimeout(() => {
        localStorage.setItem('isBack', 'false');
        localStorage.removeItem('menuListingScrollPosition');
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (
      category.length > 0 &&
      category[0] !== 'special_deals' &&
      category[0] !== 'Previously Ordered - ធ្លាប់បញ្ជាទិញពីមុន'
    ) {
      scrollToCategory(category[0]);
    }
  }, [category]);

  useEffect(() => {
    return () => {
      sessionStorage.setItem('menuListingScrollPosition', scrollPosition.toString());
    };
  }, [scrollPosition]);

  const [currentCategory, setCurrentCategory] = useState<string | null>(null);

  const handleScroll = () => {
    const viewportCenter = window.scrollY + window.innerHeight / 5;
    for (const categoryName in categoryRefs) {
      const categoryRef = categoryRefs[categoryName].current;

      if (categoryRef) {
        const categoryRect = categoryRef.getBoundingClientRect();
        const categoryTop = categoryRect.top + window.scrollY;
        const categoryBottom = categoryRect.bottom + window.scrollY;

        if (categoryTop <= viewportCenter && categoryBottom >= viewportCenter) {
          setCurrentCategory(categoryName);
          categoryDisplayed(categoryName);
          break;
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [categoryRefs]);
  return (
    <>
      <div className="menu-content">
        <div className="all-time-favourite-wrapper">
          {sortedGroupMenu &&
            sortedGroupMenu.map((item: IGroupMenu, index: number) => (
              <>
                {item.category !== 'Previously Ordered - ធ្លាប់បញ្ជាទិញពីមុន' && (
                  <div
                    id={item.category || 'No Category'}
                    className={`item ${item.category === currentCategory ? 'current-category' : ''}`}
                    key={index}
                    onClick={() => handleItemClick(item)}
                    ref={categoryRefs[item.category || 'No Category']}
                  >
                    <div className="all-time-filter">
                      <div className="text-all-time-category">
                        {item.category !== 'undefined' ? item.category : 'No Category'}
                      </div>
                    </div>

                    {item.menu.length > 0 &&
                    item.menu.every((menu) => menu.itemCategory && menu.itemCategory.type === 'Landscape') ? (
                      <>
                        <div className="thumbnail-landscape-flex">
                          {item.menu.map((menu: OutletMenuItem, index: number) => {
                            if (
                              menu.image &&
                              menu.image.imageUrl !== '' &&
                              menu.image.imageUrl !== null &&
                              menu.image.imageUrl
                            ) {
                              return (
                                <div key={index}>
                                  <ThumbnailLandscape key={index} item={menu} index={index} />
                                </div>
                              );
                            } else {
                              // if (!menu.isOutOfStock)
                              return (
                                <div key={index}>
                                  <ThumbnailLandscapeNoImage key={index} item={menu} index={index} />
                                </div>
                              );
                            }
                          })}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="thumbnail-horizontal-flex">
                          {item.menu.map((menu: OutletMenuItem, index: number) => (
                            <div key={index}>
                              <ThumbnailHorizontal key={index} item={menu} />
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </>
            ))}
          <div style={{ paddingBottom: '20px' }}>
            <FooterLogo />
          </div>
        </div>
      </div>
    </>
  );
};
export default ListingSections;
