import { CART_REQUEST_EMPTY } from 'constants/carts';
import moment from 'moment';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getLatestOrder } from '../../helper/order-summary';
import { generatePathHelpForHomePage, getSpotId } from '../../helper/path';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/index';
import { RootState } from '../../store/configureStore';
import { PaymentMethods, socialMedias } from 'interfaces/models/outlets';
import { MyImages } from 'cores/components';
import ChargesSection from 'components/partials/ChargesSection';
import { FooterLogo } from 'components/partials/FooterLogo';
import DownloadReceipt from 'cores/components/DownloadReceipt';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { SPOT_CINEMA_COUNTER, SPOT_CINEMA_SEAT, SPOT_DINE_IN, SPOT_QRTABLE } from 'constants/spot';
import { getSpotById } from 'helper/spot';
import { PaymentType } from 'enums/payment-type';
import { Item } from 'interfaces/models/carts';
import { Helmet } from 'react-helmet-async';

export const CinemaOrderSummary = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const route = useParams();
  const { outletId, orgId } = route;
  const list = getLatestOrder();
  const spotId = getSpotId();
  const spot = getSpotById(spotId);

  const { t } = useTranslation();
  const latestOrder = getLatestOrder();
  const outlets = useAppSelector((state: RootState) => state.outlets.list);
  const discountedPriceSum = latestOrder[0].items.reduce((totalAmount, item) => {
    const basePrice = item.discountedPrice !== null && item.discountedPrice !== 0 ? item.discountedPrice : item.price;
    const itemPrice = (basePrice + (item.addOns?.reduce((acc, addon) => acc + addon.price, 0) || 0)) * item.quantity;
    const total = totalAmount + itemPrice;
    return total;
  }, 0);

  const { detail } = useAppSelector((state: RootState) => state.organization);
  const { socialMedias } = useAppSelector((state: RootState) => state.outlets.list[0]);
  const filteredOutlet = outlets.filter((x) => x.id === outletId);

  const { paymentMethods, averagePreparationTime, estimatedPreparationTime, orderHoursOverrideStatus } = useAppSelector(
    (state: RootState) => state.outlets.list[0]
  );
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const paymentMethod: string = localStorage.getItem('paymentMethod') ?? 'KHQR';
  const clickMakeOtherOrder = () => {
    dispatch({ type: CART_REQUEST_EMPTY });
    if (outletId && orgId) {
      const path = generatePathHelpForHomePage('/', { outletId, orgId }, search);
      navigate(path.pathname + path.search);
    }
  };

  const showSocials = (name: string, url: string) => {
    switch (name) {
      case 'Facebook':
        return <MyImages width="30px" src="/assets/icons/facebook.svg" onClick={() => window.open(url, '_blank')} />;
        break;
      case 'Instagram':
        return <MyImages width="30px" src="/assets/icons/instagram.svg" onClick={() => window.open(url, '_blank')} />;
        break;
      case 'Tripadvisor':
        return <MyImages width="30px" src="/assets/icons/tripadvisor.svg" onClick={() => window.open(url, '_blank')} />;
        break;
      case 'Google':
        return <MyImages width="30px" src="/assets/icons/google-fill.svg" onClick={() => window.open(url, '_blank')} />;
        break;
      case 'Tiktok':
        return <MyImages width="30px" src="/assets/icons/telegram.svg" onClick={() => window.open(url, '_blank')} />;

      case 'Telegram':
        return <MyImages width="30px" src="/assets/icons/tiktok.svg" onClick={() => window.open(url, '_blank')} />;

      default:
        return '';
        break;
    }
  };

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname + window.location.search}`,
      title: ` ${detail?.name} - QR Table Order Sumary`,
    });
    return () => {
      dispatch({ type: CART_REQUEST_EMPTY });
    };
  }, []);

  return (
    <>
      {detail && detail.name && (
        <Helmet>
          <title>{detail?.name}@nTnQR</title>
          <meta property="og:title" content={detail?.name} />
          <meta name="twitter:title" content={detail?.name} />
          {detail.image && detail.image.imageUrl ? (
            <link rel="icon" href={`${detail.image.imageUrl}`} />
          ) : (
            <>
              <link rel="apple-touch-icon" sizes="180x180" href="/assets/favicons/apple-touch-icon.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/assets/favicons/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/assets/favicons/favicon-16x16.png" />
            </>
          )}

          <link rel="manifest" href="/assets/favicons/site.webmanifest" />
        </Helmet>
      )}
      <div>
        <div className="content-wrapper">
          <div className="content-card">
            <div className="restaurant-profile">
              <img loading="lazy" src={detail?.image.imageUrl} alt={detail?.image.imageUrl} />
            </div>
            <section className="section" style={{ paddingTop: '48px' }}>
              <h2 className="title">
                {spot && spot.type != SPOT_CINEMA_COUNTER ? t('enjoy_movie') : t('please_proceed_to_seat')}
              </h2>
              {filteredOutlet[0].orderHoursOverrideStatus !== 3 &&
                filteredOutlet[0].averagePreparationTime !== null && (
                  <>
                    <div className="date mt-10">
                      Your order will be completed after {filteredOutlet[0].averagePreparationTime.minutes} minutes.
                    </div>
                  </>
                )}
              {filteredOutlet[0].orderHoursOverrideStatus === 3 &&
                filteredOutlet[0].estimatedPreparationTime !== null && (
                  <>
                    <div className="date mt-10">
                      Your order will be completed after{' '}
                      {filteredOutlet[0].estimatedPreparationTime?.hours > 0 &&
                        filteredOutlet[0].estimatedPreparationTime?.hours}{' '}
                      {filteredOutlet[0].estimatedPreparationTime?.hours > 0
                        ? filteredOutlet[0].estimatedPreparationTime?.hours > 1
                          ? 'hours'
                          : 'hour'
                        : ''}{' '}
                      {filteredOutlet[0].estimatedPreparationTime?.hours > 0 &&
                        filteredOutlet[0].estimatedPreparationTime?.minutes > 0 &&
                        ' and '}{' '}
                      {filteredOutlet[0].estimatedPreparationTime.minutes > 0 &&
                        filteredOutlet[0].estimatedPreparationTime.minutes + ' minutes'}
                      .
                    </div>
                  </>
                )}
              <div className="date mt-16">{moment().format('Do MMMM YYYY - hh:mm')}</div>

              <div className="socials mt-20">
                {socialMedias && socialMedias.length > 0 ? (
                  <>
                    <span>{t('connect_with_us')}:</span>
                    <div className="flex-row column-gap-10 center">
                      {socialMedias.map((item: socialMedias, index: number) => {
                        return (
                          <div key={index} className="mt-10">
                            {showSocials(item.name, item.url)}
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </section>
            {/* <section className="section">
            <div className="flex-row space-between total-amount-text">
              <div>{t('order_summary')}</div>
            </div>
            <div className="flex-col pt-16">
              <div className="flex-row space-between" style={{ paddingBottom: 10, paddingTop: 10 }}>
                <div className="col">{t('order_from')}</div>
                <div>{latestOrder[0].customer.firstname}</div>
              </div>
            </div>
            <div className="flex-col pt-16">
              <div className="flex-row space-between" style={{ paddingBottom: 10, paddingTop: 10 }}>
                <div className="col">{t('delivery_time')}</div>
                <div>{latestOrder[0].customer.firstname}</div>
              </div>
            </div>

            <div className="flex-col pt-16">
              <div className="flex-row space-between" style={{ paddingBottom: 10, paddingTop: 10, gap: '5px' }}>
                <div className="col" style={{ whiteSpace: 'nowrap' }}>
                  {t('delivery_address')}
                </div>
                <div style={{ textAlign: 'right' }}>{latestOrder[0].customer.firstname}</div>
              </div>
            </div>
          </section> */}
            <section className="section">
              {/* {spot && spot.type === 0 && ( */}
              <div className="flex-row center">
                {paymentMethods &&
                  paymentMethods.length > 0 &&
                  paymentMethods.map((item: PaymentMethods, index: number) => {
                    return (
                      // <div key={index} className="mt-10">
                      //   {item.name === 'KHQR' ? <MyImages width="120px" src={item.icon.imageUrl} /> : ''}
                      // </div>
                      <div key={index} className="mt-10">
                        {item.name === 'KHQR' ? (
                          <>
                            <div className="flex-row center" style={{ marginBottom: '10px' }}>
                              <p className="bold">{'KHQR'}</p>
                            </div>
                            <div className="flex-row center" style={{ marginBottom: '10px' }}>
                              <MyImages width="120px" src={item.icon.imageUrl} />
                            </div>
                            <div className="flex-row center" style={{ marginBottom: '5px' }}>
                              <p className="bold">{item.accountName}</p>
                            </div>
                            <div className="flex-row center" style={{ marginBottom: '5px' }}>
                              <p className="bold">{item.accountNumber}</p>
                            </div>
                            <div className="flex-row center" style={{ marginBottom: '30px' }}>
                              <p className="bold">$ {discountedPriceSum.toFixed(2)}</p>
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
              </div>
              {/* )} */}
              <div className="flex-row space-between total-amount-text">
                <div>{t('order_details')}</div>
              </div>
              {spot && spot.type != SPOT_CINEMA_COUNTER ? (
                <div className="flex-col pt-16">
                  <div className="flex-row space-between" style={{ paddingBottom: 10, paddingTop: 10 }}>
                    <div className="col">Hall and Seat #</div>
                    <div>{spot.spotName}</div>
                  </div>
                </div>
              ) : (
                <div className="flex-col pt-16">
                  <div className="flex-row space-between" style={{ paddingBottom: 10, paddingTop: 10 }}>
                    <div className="col">Hall #</div>
                    <div>{latestOrder[0].theatre?.hall}</div>
                  </div>
                  <div className="flex-row space-between" style={{ paddingBottom: 10 }}>
                    <div className="col">Seat #</div>
                    <div>{latestOrder[0].theatre?.seat}</div>
                  </div>
                  {latestOrder[0].customer.phoneNumber && (
                    <div className="flex-row space-between" style={{ paddingBottom: 10 }}>
                      <div className="col">Phone Number</div>
                      <div>+855&nbsp;{latestOrder[0].customer.phoneNumber}</div>
                    </div>
                  )}
                </div>
              )}
              <div className="flex-col pt-23">
                {latestOrder[0].items.map((item: Item, index: number) => {
                  const basePrice =
                    item.discountedPrice !== null && item.discountedPrice !== 0 ? item.discountedPrice : item.price;
                  const totalPrice =
                    (basePrice + (item.addOns?.reduce((acc, addon) => acc + addon.price, 0) || 0)) * item.quantity;
                  return (
                    <div
                      key={index}
                      className="flex-row space-between"
                      style={{ paddingBottom: 10, alignItems: 'flex-start' }}
                    >
                      <div className="col">
                        <div>
                          <span style={{ fontWeight: '600' }}>{item.quantity}X</span> {item.name}
                        </div>
                        {item.addOns?.map((addon, index) => (
                          <div key={index}>- {addon.name}</div>
                        ))}
                      </div>
                      <div style={{ whiteSpace: 'nowrap' }}>$ {totalPrice.toFixed(2)}</div>{' '}
                      {/* Ensure two decimal places */}
                    </div>
                  );
                })}
              </div>
              <ChargesSection totalPrice={Number(discountedPriceSum.toFixed(2))} />
              {spot && spot.type === 0 && (
                <div className="flex-row space-between pt-23 price-text">
                  <div>{t('payment_method')}</div>
                  {/* <div>{t('payment_method')}</div> */}

                  <div>{PaymentType[paymentMethod as keyof typeof PaymentType]}</div>
                </div>
              )}

              <div className="mt-33">
                {/* <img width={'100%'} src="/assets/images/ad.svg" alt="" /> Temporarily Removed */}
                <div
                  onClick={() => clickMakeOtherOrder()}
                  className="btn primary mt-35"
                  style={{
                    backgroundColor: brandColor,
                    borderColor: brandColor,
                    boxShadow: `0px 4px 4px ${brandColor}40`,
                  }}
                >
                  {t('place_another_order')}
                </div>
                <div className="flex-col pt-23">
                  <DownloadReceipt />
                </div>
              </div>
            </section>
          </div>
          <div style={{ marginTop: '20px', paddingBottom: '20px' }}>
            <FooterLogo />
          </div>
        </div>
      </div>
    </>
  );
};
