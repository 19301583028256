import axios from 'axios';
import { GET, POST } from 'generics/base-service';
import { RequestPayment, TCheckTransaction, TScanABAKHPPPayment } from 'interfaces/models/requestPayment';
import { toast } from 'react-toastify';

export async function paymentRequestService(params: RequestPayment): Promise<any> {
  // const url = 'https://corsproxy.io/?' + encodeURIComponent('https://ntn.davoit.com/request-payment');
  const url = 'https://test.e-bot.io/transaction/quote';
  try {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer 7f9ab479-aab2-ab14-c6ef5ff04e10`,
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      toast.error('Error in Payment Request Service');
      throw new Error('Network response was not ok');
    }

    const responseData = await response.text();
    return responseData;
  } catch (error) {
    console.error('Error in paymentRequestService:', error);
    toast.error('Error in Payment Request Service');
    throw error;
  }
}

export async function paymentRequestGenerateTransaction(params: TScanABAKHPPPayment): Promise<string> {
  const url = '/api/v1/Payment';
  return POST<any>(url, params);
}
export async function getPaymentsByOrderId(id: string): Promise<any> {
  const url = `/api/v1/Payment/${id}`;
  return GET<any>(url);
}
export async function checkTransactionByTransactionId(params: TCheckTransaction): Promise<any> {
  const url: string | undefined = (window as any).REACT_APP_ABAKHPP_URL_CHECK_TRANS as string;
  try {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      toast.error('Error in Payment Request Service');
      throw new Error('Network response was not ok');
    }

    const responseData = await response.text();
    return responseData;
  } catch (error) {
    console.error('Error in paymentRequestService:', error);
    toast.error('Error in Payment Request Service');
    throw error;
  }
}
