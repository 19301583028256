import { useAppSelector } from 'hooks/redux';
import { IGroupMenu, OutletMenuItem } from 'interfaces/models/menu';
import { RootState } from 'store/configureStore';
import ThumbnailLandscape from './thumbnail-landscape';
import ThumbnailHorizontal from './thumbnail-horizontal';
import { t } from 'i18next';
import { useEffect, useRef } from 'react';

interface PreviouslyOrderedProps {
  categoryDisplayed: (categoryName: string) => void;
}
const PreviouslyOrdered: React.FC<PreviouslyOrderedProps> = ({ categoryDisplayed }) => {
  const groupMenu = useAppSelector((state: RootState) => state.menu.grouped);
  const { category } = useAppSelector((state) => state.filter);
  const previousOrderRef = useRef<HTMLDivElement>(null);
  const handleItemClick = (item: IGroupMenu) => {
    localStorage.setItem('menuListingScrollPosition', window.scrollY.toString());
  };
  const sortedGroupMenu =
    groupMenu &&
    groupMenu.length > 0 &&
    useAppSelector((state: RootState) => state.menu.grouped).sort((a, b) => {
      if (a.category === 'Previously Ordered - ធ្លាប់បញ្ជាទិញពីមុន') {
        if (a.menu.length > 0 && b.menu.length > 0 && a.menu[0].itemCategory && b.menu[0].itemCategory)
          return a.menu[0].itemCategory.sequence - b.menu[0].itemCategory.sequence;
      }
      return 0;
    });
  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      // ref.current.scrollIntoView({ behavior: 'smooth' });
      const element = ref.current;
      const elementRect = element.getBoundingClientRect();
      const absoluteY = elementRect.top + window.scrollY;

      window.scrollTo({
        top: absoluteY - window.innerHeight,
        behavior: 'smooth',
      });
    }
  };
  useEffect(() => {
    if (category.length > 0 && category.find((cat) => cat === 'Previously Ordered - ធ្លាប់បញ្ជាទិញពីមុន')) {
      scrollToSection(previousOrderRef);
    }
  }, [category]);

  const handleScroll = () => {
    // Get the center of the viewport
    const viewportCenter = window.scrollY + window.innerHeight / 3;

    // Loop through your categories and find the closest category to the viewport center
    const categoryRef = previousOrderRef.current;

    if (categoryRef) {
      const categoryRect = categoryRef.getBoundingClientRect();
      const categoryTop = categoryRect.top + window.scrollY;
      const categoryBottom = categoryRect.bottom + window.scrollY;

      if (categoryTop <= viewportCenter && categoryBottom >= viewportCenter) {
        categoryDisplayed('Previously Ordered - ធ្លាប់បញ្ជាទិញពីមុន');
      }
    }
  };

  useEffect(() => {
    // Add a scroll event listener to the window
    window.addEventListener('scroll', handleScroll);

    // Cleanup by removing the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [previousOrderRef]);
  return (
    <>
      {sortedGroupMenu && sortedGroupMenu.length && (
        <div className="menu-content" ref={previousOrderRef} style={{ marginTop: '92px' }}>
          <div className="all-time-favourite-wrapper">
            {sortedGroupMenu.map((item: IGroupMenu, index: number) => (
              <>
                {
                  // category.length === 0 &&
                  item.category && item.category == 'Previously Ordered - ធ្លាប់បញ្ជាទិញពីមុន' && (
                    <div key={index} onClick={() => handleItemClick(item)}>
                      <div className="all-time-filter">
                        <div className="text-all-time-category">{t('previously_ordered')}</div>
                      </div>

                      {item.menu.length > 0 &&
                      item.menu.every((menu) => menu.itemCategory && menu.itemCategory.type === 'Landscape') ? (
                        <>
                          <div className="thumbnail-landscape-flex">
                            {item.menu.map((menu: OutletMenuItem, index: number) => (
                              <div key={index}>
                                <ThumbnailLandscape key={index} item={menu} index={index} />
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="thumbnail-horizontal-flex">
                            {item.menu.map((menu: OutletMenuItem, index: number) => (
                              <div key={index}>
                                {!menu?.isOutOfStock && <ThumbnailHorizontal key={index} item={menu} />}
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  )
                }
              </>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
export default PreviouslyOrdered;
