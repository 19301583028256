import { getRates } from 'actions/kess';
import { IDeliveryInfo } from 'interfaces/models/deliveryInfo';
import { IOutlets } from 'interfaces/models/outlets';
import { useGeolocated } from 'react-geolocated';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const handlePlaceSelectMap = (
  selectedOption: any,
  setClickedCoordinates: (value: google.maps.LatLngLiteral | null) => void,
  setDeliveryInfo: (value: IDeliveryInfo) => void,
  deliveryInfo: IDeliveryInfo,
  maximumDistanceInKilometers: number,
  list: IOutlets[],
  cartTotal: number,
  setIsDistanceError: (value: boolean) => void
) => {
  setClickedCoordinates({ lat: selectedOption.y, lng: selectedOption.x });
  calculateTravelTime(
    deliveryInfo,
    setDeliveryInfo,
    list,
    { lat: selectedOption.y, lng: selectedOption.x },
    maximumDistanceInKilometers,
    selectedOption.label || '',
    cartTotal,
    setIsDistanceError
  );
};

export const calculateTravelTime = async (
  deliveryInfo: IDeliveryInfo,
  setDeliveryInfo: (value: IDeliveryInfo) => void,
  list: IOutlets[],
  clickedCoordinates: google.maps.LatLngLiteral | null,
  maximumDistanceInKilometers: number,
  formattedCompleteAddress: string,
  price: number,
  setIsDistanceError: (value: boolean) => void
) => {
  const country = parseAddressComponents(formattedCompleteAddress)['country'] || 'N/A';
  const zipcode = parseAddressComponents(formattedCompleteAddress)['zipcode'] || 'N/A';
  const state = parseAddressComponents(formattedCompleteAddress)['state'] || 'N/A';
  const city = parseAddressComponents(formattedCompleteAddress)['city'] || 'N/A';
  const street2 = parseAddressComponents(formattedCompleteAddress)['street2'] || 'N/A';
  const street1 = parseAddressComponents(formattedCompleteAddress)['street1'] || 'N/A';

  if (!clickedCoordinates || list.length === 0) {
    return false;
  }

  const { longitude: longitudeOutlet, latititude: latitudeOutlet } = list[0].location; // Ensure location object exists

  // Calculate distance using Haversine formula
  const distanceInKilometers = calculateDistance(
    latitudeOutlet,
    longitudeOutlet,
    clickedCoordinates.lat,
    clickedCoordinates.lng
  );
  localStorage.setItem('deliveryDistance', JSON.stringify(distanceInKilometers));
  // Estimate travel time based on average speed
  const averageSpeedKmPerHour = 40; // Example average speed in km/h
  const travelTimeInMinutes = estimateTravelTime(distanceInKilometers, averageSpeedKmPerHour);
  // Calculate delivery charges
  let deliveryCharges = 0.75; // Base fare for 1 km radius
  const additionalDistanceInKilometers = distanceInKilometers - 1;
  if (additionalDistanceInKilometers > 0) {
    // Additional distance beyond 1 km, charge 2.5 cents per 100 meters
    const additionalDistanceIn100Meters = additionalDistanceInKilometers * 10;
    deliveryCharges += additionalDistanceIn100Meters * 0.025;
  }

  // Round the delivery charges to 2 decimal places
  deliveryCharges = Math.round(deliveryCharges * 100) / 100;
  if (maximumDistanceInKilometers >= distanceInKilometers && parseAddressComponents(formattedCompleteAddress)) {
    // sessionStorage.setItem('deliveryCharges', (rate + 0.1).toFixed(2));
    // localStorage.setItem('deliveryCharges', (rate + 0.1).toFixed(2));
    sessionStorage.setItem('deliveryCharges', (deliveryCharges + 0.1).toFixed(2));
    localStorage.setItem('deliveryCharges', (deliveryCharges + 0.1).toFixed(2));
    localStorage.setItem('deliveryInMinutes', travelTimeInMinutes.toString());
    const updatedDeliveryInfo = {
      ...deliveryInfo,
      address: formattedCompleteAddress,
      expectedDeliveryTime: calculateArrivalTime(travelTimeInMinutes).toISOString(),
      latitude: clickedCoordinates.lat,
      longitude: clickedCoordinates.lng,
      city: city,
      country: country,
      deliveryCharge: deliveryCharges + 0.1,
    };
    setIsDistanceError(false);
    setDeliveryInfo(updatedDeliveryInfo);

    // try {
    //   const res = await getRates({
    //     service: 'GetRates',
    //     params: {
    //       to_address: {
    //         name: deliveryInfo.firstName,
    //         company: deliveryInfo.firstName,
    //         street1: street1,
    //         street2: street2,
    //         city: city,
    //         state: state,
    //         zip: zipcode,
    //         country: country,
    //         phone: Number(12345),
    //         email: deliveryInfo.email || 'N/A',
    //         lat: `${clickedCoordinates.lat}`,
    //         lng: `${clickedCoordinates.lng}`,
    //       },
    //       from_address: {
    //         name: list[0].name,
    //         company: list[0].name,
    //         street1: 'N/A',
    //         street2: 'N/A',
    //         city: list[0].location.city || 'N/A',
    //         state: list[0].location.city || 'N/A',
    //         zip: 'N/A',
    //         country: list[0].location.country || 'N/A',
    //         phone: Number(list[0].contact.phoneNumber),
    //         email: list[0].contact.email,
    //         lat: `${latitudeOutlet}`,
    //         lng: `${longitudeOutlet}`,
    //       },
    //       parcels: [
    //         {
    //           length: '10',
    //           width: '10',
    //           height: '10',
    //           weight: '10',
    //         },
    //       ],
    //       total_amount: price,
    //       currency: 'USD',
    //       description: 'NTN_ORDER',
    //       carrier_code: 'Nham24',
    //     },
    //   });

    //   if (res && res.Result === 'OK') {
    //     const { data } = res;
    //     const { rate } = data[0];
    //     sessionStorage.setItem('deliveryCharges', (rate + 0.1).toFixed(2));
    //     localStorage.setItem('deliveryCharges', (rate + 0.1).toFixed(2));
    //     // sessionStorage.setItem('deliveryCharges', deliveryCharges.toFixed(2));
    //     // localStorage.setItem('deliveryCharges', deliveryCharges.toFixed(2));
    //     const updatedDeliveryInfo = {
    //       ...deliveryInfo,
    //       address: formattedCompleteAddress,
    //       expectedDeliveryTime: calculateArrivalTime(travelTimeInMinutes).toISOString(),
    //       latitude: clickedCoordinates.lat,
    //       longitude: clickedCoordinates.lng,
    //       city: city,
    //       country: country,
    //       deliveryCharge: rate + 0.1,
    //     };
    //     // Call the setDeliveryInfo function
    //     setDeliveryInfo(updatedDeliveryInfo);
    //     return true;
    //   }
    // } catch (error) {
    //   // Handle any errors here
    //   toast.error('Error fetching rates');
    // }
  } else {
    toast.dismiss();
    toast.warn(
      `Sorry, we do not deliver to this location. Please select a location within ${maximumDistanceInKilometers} km from the restaurant.`
    );
    setIsDistanceError(true);
    const updatedDeliveryInfo = {
      ...deliveryInfo,
      expectedDeliveryTime: '',
      address: '',
      latitude: 0,
      longitude: 0,
      city: deliveryInfo.city,
      country: deliveryInfo.address,
      deliveryCharge: 0,
    };

    // Call the setDeliveryInfo function
    setDeliveryInfo(updatedDeliveryInfo);
    return false;
  }
};

const calculateDistance = (lat1: any, lon1: any, lat2: any, lon2: any) => {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return distance;
};

const toRadians = (degrees: any) => {
  return degrees * (Math.PI / 180);
};

// const estimateTravelTime = (distance: any, averageSpeed: any) => {
//   const travelTimeInHours = distance / averageSpeed;
//   const travelTimeInMinutes = travelTimeInHours * 60;
//   return Math.ceil(travelTimeInMinutes); // Round up travel time to minutes
// };
const estimateTravelTime = (distance: number, averageSpeed: number) => {
  const travelTimeInHours = distance / averageSpeed;
  const travelTimeInMinutes = travelTimeInHours * 60;
  const totalTravelTime = Math.ceil(travelTimeInMinutes);
  const totalTimeWithPreparation = totalTravelTime + 15; // Add 15 minutes for preparation
  return totalTimeWithPreparation;
};

const calculateArrivalTime = (travelTimeInMinutes: any) => {
  const currentTime = new Date();
  const arrivalTimeInMs = currentTime.getTime() + travelTimeInMinutes * 60 * 1000;
  return new Date(arrivalTimeInMs);
};
type AddressComponents = {
  country?: string;
  zipcode?: string;
  state?: string;
  city?: string;
  street2?: string;
  street1?: string;
};
const parseAddressComponents = (address: string): AddressComponents => {
  // Split the address into components
  const addressComponents = address.split(',').map((component) => component.trim());

  // Define the order of components
  const componentOrder: (keyof AddressComponents)[] = ['country', 'zipcode', 'state', 'city', 'street2', 'street1'];

  // Initialize an object to store the components
  const components: AddressComponents = {};

  // Extract components based on their order
  for (let i = 0; i < componentOrder.length && i < addressComponents.length; i++) {
    components[componentOrder[i]] = addressComponents[addressComponents.length - 1 - i];
  }

  return components;
};
