import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { NETWORK_ERROR_CODE } from '../constants/error';
import * as constants from '../constants/http-method';

/**
 * @API_URL {string}
 * @example - URL for API
 */
const API_URL = process.env.REACT_APP_API_URL;
const BEARER_TOKEN = process.env.BEARER_TOKEN;

/**
 *
 * @param url -string
 * @returns {{Promise}}
 */
export function GET<T>(url: string): Promise<T> {
  return _sendRequest<T>({ method: constants.GET, url });
}
/**
 *
 * @param url -string
 * @returns {{Promise}}
 */
export function POST<T, P = any>(url: string, data: P): Promise<T> {
  return _sendRequest<T>({ method: constants.POST, url, data });
}
export function PUT<T, P = any>(url: string, data: P): Promise<T> {
  return _sendRequest<T>({ method: constants.PUT, url, data });
}
export function POST_KESS<T, P = any>(url: string, data: P): Promise<T> {
  return _sendRequestKess<T>({ method: constants.POST, url, data });
}
function _sendRequest<T>(config: AxiosRequestConfig): Promise<T> {
  config.headers = {
    'Content-Type': 'application/json',
    ...config.headers,
  };

  config.baseURL = (window as any).MY_APP_API_URL;

  return axios(config)
    .then((response: AxiosResponse<T>) => response.data)
    .catch((err) => {
      if (err.response == null) {
        err.errorCode = NETWORK_ERROR_CODE;
        throw err;
      } else {
        throw err.response.data;
      }
    });
}

function _sendRequestKess<T>(config: AxiosRequestConfig): Promise<T> {
  config.headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + (window as any).SHIPMENT_TOKEN,
    ...config.headers,
  };
  return axios(config)
    .then((response: AxiosResponse<T>) => response.data)
    .catch((err) => {
      if (err.response == null) {
        err.errorCode = NETWORK_ERROR_CODE;
        throw err;
      } else {
        throw err.response.data;
      }
    });
}
