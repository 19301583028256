import { useAppSelector } from 'hooks/redux';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { InputComponent } from 'cores/components';
import { IPickUpInfo } from 'interfaces/models/pickupInfo';
import { Oval } from 'react-loader-spinner';
import { IOutlets } from 'interfaces/models/outlets';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner } from '../LoadingSpinner';
import { getSpotId } from 'helper/path';
import { getSpotById } from 'helper/spot';
import { SPOT_CINEMA_COUNTER, SPOT_CINEMA_HALL } from 'constants/spot';
import { t } from 'i18next';
type CinemaInfoProps = {
  onClosed: () => void;
  brandColor: string;
  cinemaInfo: any;
  setCinemaInfo: (cinemaInfo: any) => void;
  onSubmitCinemaInfo: () => void;
};
const CinemaCounterInformation = ({
  onClosed,
  brandColor,
  cinemaInfo,
  setCinemaInfo,
  onSubmitCinemaInfo,
}: CinemaInfoProps) => {
  const refBottomSheet = useRef<HTMLDivElement>(null);
  const [isLoading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const spotId = getSpotId();
  const spot = getSpotById(spotId);
  const spotName = spot ? spot.spotName : '';
  const [cinemaRow, setCinemaRow] = useState('');
  const [cinemaSeat, setCinemaSeat] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  useEffect(() => {
    if (spot.type == SPOT_CINEMA_COUNTER) {
      setCinemaInfo({ ...cinemaInfo, hallNumber: '' });
    } else {
      setCinemaInfo({ ...cinemaInfo, hallNumber: spotName });
    }
    document.body.style.overflow = 'hidden';
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.body.style.overflow = 'unset';
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleClickOutside = (e: any) => {
    if (refBottomSheet.current && !refBottomSheet.current.contains(e.target)) {
      onClosed();
    }
  };
  const cinemaSeatandRowError = () => {
    if (cinemaRow === '' && cinemaSeat === '') {
      return <span className="error"> Please select cinema row and seat.</span>;
    } else if (cinemaRow === '') {
      return <span className="error"> Please select cinema row.</span>;
    } else if (cinemaSeat === '') {
      return <span className="error"> Please select cinema seat.</span>;
    } else {
      return '';
    }
  };
  const submitCinemaInfo = () => {
    setSubmitted(true);
    if (cinemaRow !== '' && cinemaSeat !== '' && cinemaInfo.phoneNumber.length >= 8 && cinemaInfo.hallNumber !== '') {
      setCinemaInfo({ ...cinemaInfo, seatNumber: `${cinemaRow}-${cinemaSeat}` });
      setIsFormValid(true);
    }
  };
  useEffect(() => {
    if (isFormValid) {
      onSubmitCinemaInfo();
      setLoading(true);
    }
  }, [isFormValid]);
  return (
    <>
      <div className="bottom-sheet-wrapper">
        <div className="bottom-sheet-content padding" ref={refBottomSheet}>
          <div className="navigation-wrapper pickup-information">
            <div className="title mb-25">{t('seat_info')}</div>
            <div className="pickup-information-item row-item">
              <div className="flex-row space-between">
                <div className="label">
                  {t('hall_number')} <span className="error">*</span>
                </div>
              </div>
              {spot.type == SPOT_CINEMA_HALL ? (
                <>
                  <InputComponent value={cinemaInfo.hallNumber} name="seat_number" disabled={true} />
                </>
              ) : (
                <>
                  <select
                    className="select"
                    onChange={(e) => setCinemaInfo({ ...cinemaInfo, hallNumber: e.target.value })}
                  >
                    <option value="" disabled selected>
                      {t('please_choose_hall')}
                    </option>
                    <option value="Hall 1">{t('hall')} 1</option>
                    <option value="Hall 2">{t('hall')} 2</option>
                    <option value="Hall 3">{t('hall')} 3</option>
                    <option value="Hall 4">{t('hall')} 4</option>
                    <option value="Hall 5">{t('hall')} 5</option>
                    <option value="Hall 6">{t('hall')} 6</option>
                    <option value="Hall 7">{t('hall')} 7</option>
                    <option value="Hall 8 (VIP)">{t('hall')} 8 (VIP)</option>
                  </select>
                  {submitted && cinemaInfo.hallNumber === '' ? (
                    <span className="error"> {t('please_choose_hall_number')}</span>
                  ) : (
                    ''
                  )}
                </>
              )}
            </div>
            <div className="pickup-information-item row-item">
              <div className=" flex-row space-between">
                <div className="label">
                  {t('seat_number')} <span className="error">*</span>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <select
                  onChange={(e) => setCinemaRow(e.target.value)}
                  style={{
                    width: '100%',
                    height: '50px',
                    padding: '10px',
                    borderRadius: '10px',
                    border: '1px solid #d3d4d5',
                  }}
                >
                  <option value="" disabled selected>
                    Choose Row
                  </option>
                  {Array.from({ length: 16 }, (_, i) => String.fromCharCode(65 + i)).map((letter) => (
                    <option key={letter} value={letter}>
                      {letter.toLocaleUpperCase()}
                    </option>
                  ))}
                </select>

                <select
                  onChange={(e) => setCinemaSeat(e.target.value)}
                  style={{
                    width: '100%',
                    height: '50px',
                    padding: '10px',
                    borderRadius: '10px',
                    border: '1px solid #d3d4d5',
                  }}
                >
                  <option value="" disabled selected>
                    Choose Seat #
                  </option>
                  {Array.from({ length: 24 }, (_, i) => i + 1).map((number) => (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  ))}
                </select>
              </div>
              {submitted ? cinemaSeatandRowError() : ''}
            </div>

            <div className="pickup-information-item row-item">
              <div className="flex-row space-between">
                <div className="label">
                  {t('mobile_number')} <span className="error">*</span>
                  {cinemaInfo.phoneNumber.length != 0 && cinemaInfo.phoneNumber.length < 8 ? (
                    <span className="error">{t('enter_valid_phone_number')}</span>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div className="flex-row space-between">
                <div className="phone-country-code">
                  <InputComponent
                    value=""
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setCinemaInfo({ ...cinemaInfo, phoneNumber: e.target.value })
                    }
                    placeholder="(855)"
                    disabled={true}
                  />
                </div>

                <InputComponent
                  name="phonenumber"
                  value={cinemaInfo.phoneNumber}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    e.target.value.length <= 9 ? setCinemaInfo({ ...cinemaInfo, phoneNumber: e.target.value }) : ''
                  }
                  placeholder="12 123 123"
                  type="number"
                />
              </div>
              {submitted && cinemaInfo.phoneNumber === '' ? (
                <span className="error"> Please input your phone number.</span>
              ) : (
                ''
              )}
            </div>
            <div
              className="btn primary pd-12 flex-row space-between submit-order-pickup"
              onClick={() => {
                // setSubmitted(true);

                // if (cinemaInfo.hallNumber && cinemaInfo.seatNumber) {
                //   onSubmitCinemaInfo();
                //   setLoading(true);
                // }
                submitCinemaInfo();
              }}
              style={{ backgroundColor: brandColor, boxShadow: `0px 4px 4px ${brandColor}40`, borderColor: brandColor }}
            >
              <div className="flex-row center column-gap-10">
                <span>{t('proceed_to_payment')}</span>
              </div>
              <div className="flex-row column-gap-16">
                {!isLoading && <img src="/assets/icons/next-white.svg" alt="" />}
                {isLoading && <LoadingSpinner />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CinemaCounterInformation;
