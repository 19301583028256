import { SET_LANGUAGE } from 'constants/localization';
import { MyImages } from 'cores/components';
import { Footer } from 'cores/components/layout/footer';
import { useAppSelector } from 'hooks/redux';
import { IGroupMenu } from 'interfaces/models/menu';
import _ from 'lodash';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Switch from 'react-switch';

type PropType = {
  onClose: () => void;
  grouped: IGroupMenu[];
  groupMenus: any;
  selectedCategory: string;
  setCategory: (param: string) => void;
  specialDeals: any;
  previousOrders: any;
};

const CategoryDrawer = (props: PropType) => {
  const { detail } = useAppSelector((state) => state.organization);
  const brandColor = (detail?.primaryColor && detail?.primaryColor.replace(/'/g, '')) || '#DD5F36';
  const [countdown, setCountdown] = useState(999999);
  const { t } = useTranslation();
  const drawerRef = useRef<HTMLDivElement>(null);
  const promotionGroup = useAppSelector((state) => state.menu.list);
  const promotions = _.groupBy(promotionGroup, 'promotion.name');
  const checkPreviousOrder =
    props.previousOrders.find((item: any) => item.category === 'Previously Ordered - ធ្លាប់បញ្ជាទិញពីមុន') || false;
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    const handleClickOutside = (event: MouseEvent) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
        props.onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    const intervalId = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    if (countdown === 0) {
      clearInterval(intervalId);
      props.onClose();
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('mousedown', handleClickOutside);
      clearInterval(intervalId);
    };
  }, [countdown, props]);

  const handleSelectCategory = (category: string) => {
    props.setCategory(category);
    props.onClose();
  };
  useEffect(() => {
    props.setCategory('');
  }, []);
  const sortedGroupMenu = props.grouped.sort((a: IGroupMenu, b: IGroupMenu) => a.sequence - b.sequence);

  return (
    <div className="bottom-sheet-wrapper">
      <div
        ref={drawerRef}
        className="bottom-sheet-content content padding"
        style={{ paddingBottom: '0px', overflow: 'unset' }}
      >
        <div className="navigation-wrapper pickup-information">
          <div
            className="language-title"
            style={{
              marginBottom: '5px',
            }}
          >
            <p
              style={{
                textTransform: 'uppercase',
                color: brandColor,
              }}
            >
              {t('menu')}
            </p>
          </div>
          <div
            style={{
              overflowY: 'auto',
              maxHeight: '70vh',
            }}
          >
            {checkPreviousOrder && (
              <div onClick={() => handleSelectCategory('Previously Ordered - ធ្លាប់បញ្ជាទិញពីមុន')}>
                <div
                  className="language-divider"
                  style={{
                    marginTop: '0px',
                    marginBottom: '14px',
                    borderColor: 'rgba(215, 215, 215, 0.30)',
                  }}
                ></div>
                <div className="pickup-information-item row-item">
                  <p
                    style={{
                      fontSize: '18px',
                      fontWeight: 400,
                    }}
                  >
                    {t('Previously Ordered - ធ្លាប់បញ្ជាទិញពីមុន')}
                  </p>
                </div>
              </div>
            )}
            {props.specialDeals &&
              props.specialDeals.length > 0 &&
              props.specialDeals.map((item: any, index: number) => {
                const promotion = promotions[item][0].promotion;
                let discountType = promotion.discountType;
                let discountValue = promotion.value;
                let discountName = promotion.name;
                let discount =
                  discountType === 0
                    ? `${discountValue}% OFF - ${discountName}`
                    : `$${discountValue} OFF - ${discountName}`;
                return (
                  <div key={index} onClick={() => handleSelectCategory(item)}>
                    <div
                      className="language-divider"
                      style={{
                        marginTop: '0px',
                        marginBottom: '14px',
                        borderColor: 'rgba(215, 215, 215, 0.30)',
                      }}
                    ></div>
                    <div className="pickup-information-item row-item">
                      <p
                        style={{
                          fontSize: '18px',
                          fontWeight: 400,
                        }}
                      >
                        {discount}
                      </p>
                    </div>
                  </div>
                );
              })}

            {sortedGroupMenu.map((item: IGroupMenu, index: number) => {
              if (item.category !== 'Previously Ordered - ធ្លាប់បញ្ជាទិញពីមុន')
                return (
                  <div key={index} onClick={() => handleSelectCategory(item.category)}>
                    <div
                      className="language-divider"
                      style={{
                        marginTop: '0px',
                        marginBottom: '14px',
                        borderColor: 'rgba(215, 215, 215, 0.30)',
                      }}
                    ></div>
                    <div className="pickup-information-item row-item">
                      <p
                        style={{
                          fontSize: '18px',
                          fontWeight: 400,
                        }}
                      >
                        {item.category}
                      </p>
                    </div>
                  </div>
                );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryDrawer;
