import { getMenuDetailsById, getMenuOptionsById } from 'actions/menu';
import AddOns from 'components/partials/AddOns';
import MenuInformation from 'components/partials/MenuInformation';
import { calculateDiscountedPrice } from 'cores/components';
import AddToCart from 'cores/components/AddToCart';
import { sum, sumBy } from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CART_REQUEST_ADDED, CART_UPDATE_ITEM } from '../../constants/carts';
import { GET_MENU_DETAILS_REQUEST } from '../../constants/menu';
import { InputComponent } from '../../cores/components/Input/index';
import { OrderStatus } from '../../enums/status';
import { getEditingIndex, getEditItemsFromCart, isDisabledAddToCartButton, isEditingCart } from '../../helper/cart';
import { getSpotId } from '../../helper/path';
import { getSelectorByKey } from '../../helper/selector';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/index';
import { Carts } from '../../interfaces/models/carts';
import { AddOn, MenuOptionItem } from '../../interfaces/models/menu';
import { IOutlets } from 'interfaces/models/outlets';
import { useTranslation } from 'react-i18next';
import Options from 'components/partials/Options';
import ReactGA from 'react-ga4';
import { getSpotById } from 'helper/spot';
import { SPOT_MENU_ONLY } from 'constants/spot';
import { Helmet } from 'react-helmet-async';

interface TypeProps {
  messageID: number;
  distance: number;
}

export const MenuDetails = (props: TypeProps) => {
  const menu = getSelectorByKey('menu').details;
  const isOutofStock = menu?.isOutOfStock;
  const request = getSelectorByKey('request');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id, outletId } = useParams();
  const isEditing = isEditingCart();
  const index = getEditingIndex();
  const spotId = getSpotId();
  const spot = getSpotById(spotId);
  const { list } = useAppSelector((state) => state.outlets);
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState<number>(getEditItemsFromCart()?.quantity ?? 1);
  const [addOns, setAddOns] = useState<AddOn[] | []>(getEditItemsFromCart()?.addOns ?? []);
  const { detail } = useAppSelector((state) => state.organization);

  const [specialInstruction, setInstruction] = useState<string>(getEditItemsFromCart()?.specialRequest ?? '');
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  let findOutletId = list.find((i) => i.id === outletId);
  const [currentOutlet, setCurrentOutlet] = useState<IOutlets>();
  const [isWithinOrderTime, setIsWithinOrderTime] = useState(false);
  const [isQuickOrder, setQuickOrder] = useState(false);
  useEffect(() => {
    if (menu && currentOutlet) {
      ReactGA.send({
        hitType: 'pageview',
        page: `${window.location.pathname + window.location.search}`,
        title: `${currentOutlet?.name} -  ${menu.name}`,
      });
      ReactGA.event({
        category: `view_item`,
        action: `view_item`,
        label: `view_item`, // optional
        // value: 99, // optional, must be a number
        // nonInteraction: true, // optional, true/false
        transport: 'xhr', // optional, beacon/xhr/image
      });
    }
    const currentOrderHours = currentOutlet?.orderHours || undefined;
    const isWithinHours = currentOrderHours ? isDisabledAddToCartButton(currentOrderHours) : false;
    const currentOverrideStatus = currentOutlet?.orderHoursOverrideStatus || 0;
    if (currentOverrideStatus === 0) {
      setIsWithinOrderTime(isWithinHours);
    } else if (currentOverrideStatus === 2) {
      setIsWithinOrderTime(false);
    } else if (currentOverrideStatus === 3) {
      setIsWithinOrderTime(isWithinHours);
    } else {
      setIsWithinOrderTime(true);
    }
  }, [currentOutlet]);

  useEffect(() => {
    setCurrentOutlet(findOutletId);
  }, [findOutletId]);

  useEffect(() => {
    if (id && id !== menu?.id && outletId) {
      dispatch(getMenuDetailsById(outletId, id));
    }
    calculateTotalPrice();
    // }, [quantity, request[GET_MENU_DETAILS_REQUEST], addOns]); --> This line causing infinite loop in E-BOT API
  }, [quantity, request[GET_MENU_DETAILS_REQUEST], addOns]);
  useEffect(() => {
    if (id && outletId) {
      dispatch(getMenuOptionsById(outletId, id));
    }
  }, [id, outletId]);
  const back = () => {
    localStorage.setItem('isBack', 'true');
    navigate(-1);
  };
  const clickAddItemToCart = () => {
    setIsSubmitting(false);
    setTimeout(() => {
      setIsSubmitting(true);
    }, 0);
    if (menu && isValid) {
      let type = CART_REQUEST_ADDED;
      const payload: Carts = {
        price: quantity * totalPrice,
        status: OrderStatus.Open,
        items: {
          name: menu.name,
          menuId: menu.id,
          description: menu.description,
          specialRequest: specialInstruction,
          quantity,
          addOns,
          imageUrl: menu.image.imageUrl,
          price: menu.basicPrice,
          promotion: menu.promotion,
          discountedPrice: menu.promotion
            ? calculateDiscountedPrice(menu.promotion.value, menu.promotion.discountType, menu.basicPrice)
            : 0,
        },
      };
      if (!isEditing && quantity > 0) {
        dispatch({ type, payload });
        ReactGA.event({
          category: `${menu.name}`,
          action: `add_to_cart`,
          transport: 'xhr', // optional, beacon/xhr/image
        });
      }

      if (isEditing) {
        type = CART_UPDATE_ITEM;
        payload['index'] = index;
        dispatch({ type, payload });
        ReactGA.event({
          category: `${menu.name}`,
          action: `Updated ${menu.name} in Cart`,
          transport: 'xhr', // optional, beacon/xhr/image
        });
      }

      setIsSubmitting(false);
      setQuantity(1);
      back();
    }
  };

  function changeAddOns(options: AddOn[], isValid: boolean) {
    if (menu)
      ReactGA.event({
        category: `${menu.name}`,
        action: `Added AddOns for ${menu.name}`,
        transport: 'xhr', // optional, beacon/xhr/image
      });
    setAddOns(options);
    setIsValid(isValid);
  }
  function calculateTotalPrice() {
    const price = sum([
      menu?.promotion
        ? calculateDiscountedPrice(menu.promotion.value, menu?.promotion.discountType, menu.basicPrice)
        : menu?.basicPrice ?? 0,
      sumBy(addOns, 'price'),
    ]);
    setTotalPrice(price);
  }
  const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  };
  // function changeOptions(addOns: AddOn[], isValid: boolean) {
  //   setAddOns(addOns);
  //   setIsValid(isValid);
  // }
  const handleSubmitFromQuickOrder = () => {
    setIsSubmitting(true);
  };
  return (
    <>
      {!request[GET_MENU_DETAILS_REQUEST] && (
        <>
          {detail && detail.name && (
            <Helmet>
              <title>{detail?.name}@nTnQR</title>
              <meta property="og:title" content={detail?.name} />
              <meta name="twitter:title" content={detail?.name} />
              {detail.image && detail.image.imageUrl ? (
                <link rel="icon" href={`${detail.image.imageUrl}`} />
              ) : (
                <>
                  <link rel="apple-touch-icon" sizes="180x180" href="/assets/favicons/apple-touch-icon.png" />
                  <link rel="icon" type="image/png" sizes="32x32" href="/assets/favicons/favicon-32x32.png" />
                  <link rel="icon" type="image/png" sizes="16x16" href="/assets/favicons/favicon-16x16.png" />
                </>
              )}

              <link rel="manifest" href="/assets/favicons/site.webmanifest" />
            </Helmet>
          )}
          <MenuInformation isWithinOrderTime={isWithinOrderTime} onChangeQuantity={setQuantity} />
          {spotId && spot && spot?.type != SPOT_MENU_ONLY && (
            <>
              {!isOutofStock && (
                <>
                  {isWithinOrderTime && (
                    <>
                      {/* <AddOns onValid={setIsValid} isSubmitting={isSubmitting} onChange={changeAddOns} /> */}
                      <Options onValid={setIsValid} isSubmitting={isSubmitting} onChange={changeAddOns} />
                      <div
                        className="attribute-wrapper row-item "
                        style={{ marginBottom: `${isSafari() ? '7rem' : '150px'}`, marginTop: '5px' }}
                      >
                        <div className="flex-row space-between">
                          <div className="add-on-text">{t('special_instructions')}</div>
                          <div className="option-text">{t('optional')}</div>
                        </div>

                        <InputComponent
                          value={specialInstruction}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => setInstruction(e.target.value)}
                          placeholder={`${t('add_notes')} (e.g.extra sauce, no onion, not spicy, less sugar...)`}
                        />
                      </div>
                    </>
                  )}
                  <AddToCart
                    specialRequest={specialInstruction}
                    setIsSubmitting={handleSubmitFromQuickOrder}
                    onClick={clickAddItemToCart}
                    quantity={quantity}
                    price={totalPrice}
                    messageID={props.messageID}
                    distance={props.distance}
                    menuDetails={menu}
                    addOns={addOns}
                    isValid={isValid}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
