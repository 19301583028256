import { SET_LANGUAGE } from 'constants/localization';
import { MyImages } from 'cores/components';
import { Footer } from 'cores/components/layout/footer';
import { useAppSelector } from 'hooks/redux';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Switch from 'react-switch';
import { FooterLogo } from '../FooterLogo';
type PropType = {
  onClose: () => void;
};
const LanguageSetting = (props: PropType) => {
  const { detail } = useAppSelector((state) => state.organization);
  const { lang } = useAppSelector((state) => state.localization);
  const brandColor = (detail?.primaryColor && detail?.primaryColor.replace(/'/g, '')) || '#DD5F36';
  const dispatch = useDispatch();
  const [englishActive, setEnglishActive] = useState(lang === 'en');
  const [khmerActive, setKhmerActive] = useState(lang === 'kh');
  const [changesSaved, setChangesSaved] = useState(false);
  const { t } = useTranslation();
  const drawerRef = useRef<HTMLDivElement>(null);
  const [countdown, setCountdown] = useState(999999);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    const handleClickOutside = (event: MouseEvent) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
        props.onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    const intervalId = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    if (countdown === 0) {
      clearInterval(intervalId);
      props.onClose();
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('mousedown', handleClickOutside);
      clearInterval(intervalId);
    };
  }, [countdown, props]);
  const handleToggle = (language: string) => {
    if (language === 'english') {
      setEnglishActive(true);
      setKhmerActive(false);
    } else if (language === 'khmer') {
      setEnglishActive(false);
      setKhmerActive(true);
    }
    setChangesSaved(false);
  };

  const handleSaveChanges = () => {
    if (!changesSaved) {
      dispatch({ type: SET_LANGUAGE, payload: englishActive ? 'en' : 'kh' });
      setChangesSaved(true);
    }
    props.onClose();
  };

  return (
    <>
      <div className="bottom-sheet-wrapper">
        <div className="bottom-sheet-content padding" ref={drawerRef} style={{ paddingBottom: '0px' }}>
          <div className="navigation-wrapper pickup-information ">
            <div className="language-title ">
              <p
                style={{
                  textTransform: 'uppercase',
                }}
              >
                {t('language_setting')}
              </p>
            </div>
            <div className="pickup-information-item row-item">
              <div className="flex-row space-between">
                <div className="flex-row space-between" style={{ gap: '10px' }}>
                  <MyImages src="/assets/icons/flags/britain-flag.png" width="25px" />
                  <div>{t('english')}</div>
                </div>
                <Switch
                  onChange={() => handleToggle('english')}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  checked={englishActive}
                  onColor={brandColor}
                  height={20}
                  width={40}
                  handleDiameter={15}
                />
              </div>
            </div>
            <div className="pickup-information-item row-item">
              <div className="flex-row space-between">
                <div className="flex-row space-between" style={{ gap: '10px' }}>
                  <MyImages src="/assets/icons/flags/cambo-flag.png" width="25px" />
                  <div>{t('khmer')}</div>
                </div>
                <Switch
                  onChange={() => handleToggle('khmer')}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  checked={khmerActive}
                  onColor={brandColor}
                  height={20}
                  width={40}
                  handleDiameter={15}
                />
              </div>
            </div>
            <div className="language-divider"></div>
            <div
              className="btn primary pd-12 flex-row"
              style={{
                backgroundColor: brandColor,
                boxShadow: `0px 4px 4px ${brandColor}40`,
                borderColor: brandColor,
                justifyContent: 'center',
              }}
              onClick={handleSaveChanges}
            >
              {t('save_changes')}
            </div>
          </div>
          <div style={{ paddingTop: '20px' }}>
            <FooterLogo />
          </div>
        </div>
      </div>
    </>
  );
};

export default LanguageSetting;
