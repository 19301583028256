import { createOrder } from 'actions/order';
import { calculateDiscountedPrice } from 'cores/components';
import OrderItem from 'pages/MenuListing/components/order-item';
import { useEffect, useRef, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import { generatePathHelpForHomePage, getSpotId } from '../../../helper/path';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux/index';
import { Item } from '../../../interfaces/models/carts';
import { RootState } from '../../../store/configureStore';
import { getSpotById } from 'helper/spot';
import {
  SPOT_CINEMA_COUNTER,
  SPOT_CINEMA_HALL,
  SPOT_CINEMA_SEAT,
  SPOT_DELIVERY,
  SPOT_DINE_IN,
  SPOT_HOTEL_ROOM_SERVICE,
  SPOT_PICKUP_TYPE,
  SPOT_QRTABLE,
  SPOT_RESERVATION_PREORDER,
  SPOT_TABLE_RESERVATION,
} from 'constants/spot';
import PickUpInformation from '../PickupInformation';
import { IPickUpInfo } from 'interfaces/models/pickupInfo';
import { ORDER_TYPES, ORDER_UPDATE_ITEM } from 'constants/order';
import { CART_REQUEST_EMPTY } from 'constants/carts';
import { useCalculatChargeDelivery, useCalculateCharge, useCalculatePickup } from 'hooks/useCalculateCharges';
import ChargesSection from '../ChargesSection';
import { getSelectorByKey } from 'helper/selector';
import { Icon } from '@iconify/react';
import DeliveryInformation from '../DeliveryInformation';
import { IDeliveryInfo } from 'interfaces/models/deliveryInfo';
import { storePreviousOrder } from 'helper/order-summary';
import { useTranslation } from 'react-i18next';
import DeliveryDetails from 'pages/MenuListing/components/delivery-details';
import { paymentRequestGenerateTransaction, paymentRequestService } from 'services/payment';
import { RequestPayment } from 'interfaces/models/requestPayment';
import { LoadingSpinner } from '../LoadingSpinner';
import SelectPaymentMethod from '../SelectPaymentMethod';
import ReactGA from 'react-ga4';
import ProceedToPayment from '../ProceedToPayment';
import moment from 'moment';
import { getConvertedDate } from 'helper/date';
import CinemaCounterInformation from '../CinemaCounterInformation';
import { PaymentTypeUnion } from 'interfaces/models/outlets';
import { OnlinePaymentMethods, PaymentOption, PaymentType } from 'enums/payment-type';
import { callingWaiter } from 'actions/outlets';
import { postShip } from 'actions/kess';
import { PostShipParam } from 'interfaces/models/postShip';
import { CreateOrderResponse } from 'interfaces/models/order';
import CryptoJS from 'crypto-js';
import OutletBusyDialog from '../outletBusyDialog';

declare class AbaPay {}

export interface TypeProps {
  isOpen: boolean;
  onClose: () => void;
}

export const BottomSheet = (props: TypeProps) => {
  const [reviewOrder, setReviewOrder] = useState(false);
  const { onClose, isOpen } = props;

  return (
    <>
      {isOpen && (
        <div className="bottom-sheet-wrapper">
          {/* {!reviewOrder && <YourOrder onClosed={onClose} onSubmit={() => setReviewOrder(true)} />} */}
          {!reviewOrder && (
            // <ReviewOrder
            //   clickAddOrder={() => [setReviewOrder(false), onClose()]}
            //   onClosed={() => setReviewOrder(false)}
            // />
            <ReviewOrder clickAddOrder={() => [setReviewOrder(false), onClose()]} onClosed={onClose} />
          )}
        </div>
      )}
    </>
  );
};

interface YourOrderProps {
  onClosed?: () => void;
  onSubmit?: () => void;
}

const YourOrder = (props: YourOrderProps) => {
  const { items, price } = useAppSelector((state: RootState) => state.carts);
  const refBottomSheet = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.body.style.overflow = 'unset';
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [items.length, price]);

  const handleClickOutside = (e: any) => {
    if (refBottomSheet.current && !refBottomSheet.current.contains(e.target)) {
      onClickClose();
    }
  };

  const onClickClose = () => {
    const { onClosed } = props;
    if (onClosed) {
      onClosed();
    }
  };

  const onClickSubmit = () => {
    const { onSubmit } = props;
    if (onSubmit && items.length) {
      onSubmit();
    }
  };

  return (
    <div className="bottom-sheet-content" ref={refBottomSheet}>
      <div className="header">
        <div className="flex-row space-between">
          <div className="bottom-sheet-title">Your Order</div>
          <div onClick={onClickClose}>
            <img src="/assets/icons/cross.svg" alt="" />
          </div>
        </div>
      </div>
      <div className="content">
        {items.length > 0 &&
          items.map((item: Item, index: number) => <OrderItem item={item} key={index} index={index} />)}
        <div className="flex-row center column-gap-7 mt-30" onClick={onClickClose}>
          <img src="/assets/icons/plus-circle.svg" alt="" />
          <div className="edit-text">{t('add_another_order')}</div>
        </div>
        <div className="sticky">
          <div className="btn primary mt-30 pd-12" onClick={() => onClickSubmit()}>
            <div>{t('save_changes')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ReviewOrder {
  onClosed: () => void;
  clickAddOrder: () => void;
}

const ReviewOrder = (props: ReviewOrder) => {
  const navigate = useNavigate();
  const routeParam = useParams();
  const { outletId, orgId } = useParams();
  const dispatch = useAppDispatch();
  const localDeliveryInfo = localStorage.getItem('deliveryInfo');
  const search = useLocation().search;
  const spotId = getSpotId();
  const spot = getSpotById(spotId);
  const { items, price } = useAppSelector((state: RootState) => state.carts);
  // const { list } = useAppSelector((state: RootState) => state.outlets);
  const [isLoading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [openPickUpInformation, setOpenPickUpInformation] = useState(false);
  const [openDeliveryInformation, setOpenDeliveryInformation] = useState(false);
  const [openCinemaInformation, setOpenCinemaInformation] = useState(false);

  const [openSelectPayment, setOpenSelectPayment] = useState(false);
  const [selectedPaymentType, setselectedPaymentType] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [isPaymentLinkReady, setisPaymentLinkReady] = useState(false);
  const [paymentLink, setPaymentLink] = useState('');
  const [isDeliveryEdit, setIsDeliveryEdit] = useState(false);
  const [pickupInfo, setPickupInfo] = useState<IPickUpInfo>({
    firstName: '',
    lastName: '',
    mobileNumber: '',
    email: '',
    notes: '',
    preffered_date: new Date(),
  });
  const [deliveryInfo, setDeliveryInfo] = useState<IDeliveryInfo>({
    address: '',
    country: '',
    city: '',
    longitude: 0,
    latititude: 0,
    latitude: 0,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    countryCode: '',
    email: '',
    notes: '',
    expectedDeliveryTime: '',
    deliveryCharge: 0,
  });
  const [cinemaInfo, setCinemaInfo] = useState({
    seatNumber: '',
    hallNumber: '',
    phoneNumber: '',
  });
  const { list } = getSelectorByKey('outlets');
  const outlet = list.find((i) => i.id === outletId);
  const filteredList = list.filter((item) => item.id === outletId);
  const { subTotal, totalSum } = useCalculateCharge(price);
  const { subTotalDelivery, totalSumDelivery } = useCalculatChargeDelivery(price);
  const { subTotalPickup, totalSumPickup } = useCalculatePickup(price);
  const { detail } = useAppSelector((state) => state.organization);
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const { t } = useTranslation();
  const [isOutletBusy, setIsOutletBusy] = useState(filteredList[0].orderHoursOverrideStatus === 3);
  const removeScrolled = () => {
    localStorage.setItem('menuListingScrollPosition', '0');
  };
  items.forEach(function (item) {
    if (item.promotion) {
      item.promotion.promotionId = item.promotion.id;
      item.promotion.promotionName = item.promotion.name;
      item.discountedPrice = calculateDiscountedPrice(item?.promotion.value, item?.promotion.discountType, item?.price);
    }

    return item;
  });
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [quantity]);
  useEffect(() => {
    if (localDeliveryInfo) {
      setDeliveryInfo({
        ...deliveryInfo,
        firstName: JSON.parse(localDeliveryInfo).firstName,
        email: JSON.parse(localDeliveryInfo).email,
        phoneNumber: JSON.parse(localDeliveryInfo).phoneNumber,
        address: JSON.parse(localDeliveryInfo).address,
        deliveryCharge: JSON.parse(localDeliveryInfo).deliveryCharge,
        expectedDeliveryTime: JSON.parse(localDeliveryInfo).expectedDeliveryTime,
      });
    }
  }, [localDeliveryInfo]);
  const updateOrder = (id: string, updatedProperties: Partial<CreateOrderResponse>) => {
    dispatch({
      type: ORDER_UPDATE_ITEM,
      payload: { id, ...updatedProperties },
    });
  };
  const onClickSubmitOrder = () => {
    removeScrolled();
    if (!isLoading && spotId && outletId) {
      setLoading(true);
      if (items.length) {
        storePreviousOrder(items);
        const param = {
          spotId: spotId,
          outletId,
          body: {
            items: items,
            status: 0,
            price: outlet?.areChargesIncluded ? totalSum : subTotal,
            discountedprice: price,
          },
        };
        Promise.resolve(dispatch(createOrder(param)))
          .then(() => {
            successAction();
            dispatch({ type: CART_REQUEST_EMPTY });
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };
  const onClickSubmitOrderHotelRoomService = () => {
    removeScrolled();
    if (!isLoading && spotId && outletId) {
      setLoading(true);
      if (items.length) {
        storePreviousOrder(items);
        const param = {
          spotId: spotId,
          outletId,
          body: {
            items: items,
            status: 0,
            price: outlet?.areChargesIncluded ? totalSum : subTotal,
            discountedprice: price,
          },
        };
        Promise.resolve(dispatch(createOrder(param)))
          .then(() => {
            dispatch({ type: CART_REQUEST_EMPTY });
            const { outletId, orgId } = routeParam;
            if (outletId && orgId) {
              const path = generatePathHelpForHomePage('/thank-you', { outletId, orgId }, search);
              navigate(path.pathname + path.search);
            }
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  const onFillPickupInfo = () => {
    setOpenPickUpInformation(true);
  };

  const onFillDeliveryInfo = () => {
    setIsDeliveryEdit(true);
    setOpenDeliveryInformation(true);
  };

  const onSubmitPickup = () => {
    removeScrolled();
    if (!isLoading && spotId && outletId) {
      setLoading(true);
      if (items.length) {
        storePreviousOrder(items);
        const param = {
          spotId: spotId,
          outletId,
          body: {
            items: items,
            status: 0,
            price: price,
            discountedPrice: price,
            customer: {
              firstname: pickupInfo.firstName,
              lastname: pickupInfo.lastName,
              phoneNumber: pickupInfo.mobileNumber,
            },
            orderType: ORDER_TYPES.PICKUP,
            preferedDeliveryDateTime: pickupInfo.preffered_date,
            notes: pickupInfo.notes,
          },
        };

        Promise.resolve(dispatch(createOrder(param)))
          .then(() => {
            const { outletId, orgId } = routeParam;
            if (outletId && orgId) {
              const path = generatePathHelpForHomePage('/order-summary-pickup', { outletId, orgId }, search);
              // save items to local storage for pickup order
              navigate(path.pathname + path.search);
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };
  const onSubmitPreOrder = (paymentType: string, method: string) => {
    removeScrolled();
    const selectedReservation = localStorage.getItem('SELECTED_RESERVATION');
    const selectedReservationLocalObject = selectedReservation ? JSON.parse(selectedReservation) : null;
    const existingPreOrder = localStorage.getItem('PREORDER_GROUPED');
    const existingPreOrderLocalObject = existingPreOrder ? JSON.parse(existingPreOrder) : [];
    const paymentMethod = method
      ? PaymentType[method as keyof typeof PaymentType]
      : PaymentType[paymentType as keyof typeof PaymentType];

    if (!isLoading && spotId && outletId) {
      setLoading(true);
      if (items.length) {
        storePreviousOrder(items);
        const param = {
          spotId: spotId,
          outletId,
          body: {
            paymentMethod: {
              name: paymentMethod,
            },
            items: items,
            status: 0,
            price: price,
            discountedPrice: price,
            customer: {
              firstname: selectedReservationLocalObject.CustomerName,
              lastname: selectedReservationLocalObject.CustomerName,
              phoneNumber: selectedReservationLocalObject.CustomerPhone,
            },
            orderType: ORDER_TYPES.RESERVATION,
            preferedDeliveryDateTime: new Date(
              getConvertedDate(
                `${selectedReservationLocalObject.ReserveDate} ${selectedReservationLocalObject.ReserveTime}`
              )
            ).toISOString(),
            notes: `TABLE RESERVATION with PRE-ORDER:
    Table Dining Date: ${
      (moment(selectedReservationLocalObject.ReserveDate).format('LL'),
      moment(`${selectedReservationLocalObject.ReserveTime}`, 'HH:mm').format('h:mm A'))
    }  - Number of People: ${selectedReservationLocalObject.GuestNumber}`,
          },
        };
        const preOrderLocalItem = {
          orderedItem: items,
          id: selectedReservationLocalObject.id,
        };
        existingPreOrderLocalObject.push(preOrderLocalItem);
        localStorage.setItem('PREORDER_GROUPED', JSON.stringify(existingPreOrderLocalObject));
        localStorage.setItem('RESERVATION_INFO_LOCAL', JSON.stringify(selectedReservationLocalObject));

        Promise.resolve(dispatch(createOrder(param)))
          .then((e) => {
            dispatch({ type: CART_REQUEST_EMPTY });
            if (paymentType !== 'PAYONLINE') {
              localStorage.setItem('paymentMethod', paymentType);
              const { outletId, orgId } = routeParam;
              if (outletId && orgId) {
                setTimeout(() => {
                  const path = generatePathHelpForHomePage('/pre-order-sumary', { outletId, orgId }, search);
                  navigate(path.pathname + path.search);
                }, 2000);
              }
            } else {
              const paymentOption = PaymentOption[method as keyof typeof PaymentOption];
              localStorage.setItem('paymentMethod', method);
              generateTransactionId(e.id, paymentOption, '/pre-order-sumary');
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };
  const onSubmitDelivery = (paymentType: string, method: string) => {
    const localStorageDeliveryParams = localStorage.getItem('getRatesParam');
    const deliveryParams = localStorageDeliveryParams ? JSON.parse(localStorageDeliveryParams) : null;
    const paymentMethod = method
      ? PaymentType[method as keyof typeof PaymentType]
      : PaymentType[paymentType as keyof typeof PaymentType];

    removeScrolled();
    if (!isLoading && spotId && outletId) {
      setLoading(true);
      if (items.length) {
        storePreviousOrder(items);
        const param = {
          spotId: spotId,
          outletId,
          body: {
            paymentMethod: {
              name: paymentMethod,
            },
            items: items,
            status: 0,
            price: totalSumDelivery,
            discountedPrice: totalSumDelivery,
            customer: {
              firstname: deliveryInfo.firstName,
              lastname: deliveryInfo.lastName,
              phoneNumber: deliveryInfo.phoneNumber,
            },
            deliveryDateTime: deliveryInfo.expectedDeliveryTime,
            deliveryCharge: deliveryInfo.deliveryCharge,
            deliveryContact: deliveryInfo,
            orderType: ORDER_TYPES.DELIVERY,
            notes: deliveryInfo.notes,
          },
        };
        Promise.resolve(dispatch(createOrder(param)))
          .then((e) => {
            if (paymentType !== 'PAYONLINE') {
              localStorage.setItem('paymentMethod', paymentType);
              const { outletId, orgId } = routeParam;
              if (outletId && orgId) {
                setTimeout(() => {
                  const path = generatePathHelpForHomePage('/order-summary-delivery', { outletId, orgId }, search);
                  navigate(path.pathname + path.search);
                }, 2000);
              }
            } else {
              const paymentOption = PaymentOption[method as keyof typeof PaymentOption];
              localStorage.setItem('paymentMethod', method);
              generateTransactionId(e.id, paymentOption, '/order-summary-delivery');
              // if (method === 'ABAAKHPP') {
              //   generateTransactionId(e.id, '/order-summary-cinema');
              // } else {
              //   ReactGA.event({
              //     category: `Requested Cash Payment`,
              //     action: `Requested Cash Payment`,
              //     transport: 'xhr', // optional, beacon/xhr/image
              //   });
              //   const path = '/order-summary-cinema';
              //   paymentRequest(e.id, path, method);
              // }
            }
            // setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  const onSubmitCinema = (paymentType: PaymentTypeUnion, method: string, paymentNotes?: string) => {
    const paymentMethod = method
      ? PaymentType[method as keyof typeof PaymentType]
      : PaymentType[paymentType as keyof typeof PaymentType];

    removeScrolled();
    if (!isLoading && spotId && outletId) {
      setLoading(true);
      if (items.length && cinemaInfo) {
        storePreviousOrder(items);
        const param = {
          spotId: spotId,
          outletId,
          body: {
            paymentMethod: {
              name: paymentMethod,
            },
            customer: {
              firstname: cinemaInfo.hallNumber,
              lastname: cinemaInfo.seatNumber,
              phoneNumber: cinemaInfo.phoneNumber,
            },
            items: items,
            status: 0,
            price: outlet?.areChargesIncluded ? totalSum : subTotal,
            discountedprice: price,
            orderType:
              spot.type == SPOT_CINEMA_SEAT
                ? ORDER_TYPES.CINEMA_SEAT
                : spot.type == SPOT_CINEMA_COUNTER
                ? ORDER_TYPES.CINEMA_COUNTER
                : ORDER_TYPES.CINEMA_HALL,
            theatre: {
              hall: cinemaInfo.hallNumber,
              seat: cinemaInfo.seatNumber,
            },
          },
        };
        Promise.resolve(dispatch(createOrder(param)))
          .then((e) => {
            if (spot.type == SPOT_CINEMA_COUNTER || spot.type == SPOT_CINEMA_HALL) {
              callForWaiter(paymentType, `${e.id}-${e.orderTypeSequence}`, paymentNotes);
            }
            if (paymentType !== 'PAYONLINE') {
              localStorage.setItem('paymentMethod', paymentType);
              const { outletId, orgId } = routeParam;
              if (outletId && orgId) {
                setTimeout(() => {
                  const path = generatePathHelpForHomePage('/order-summary-cinema', { outletId, orgId }, search);
                  navigate(path.pathname + path.search);
                }, 2000);
              }
            } else {
              const paymentOption = PaymentOption[method as keyof typeof PaymentOption];
              localStorage.setItem('paymentMethod', method);
              generateTransactionId(e.id, paymentOption, '/order-summary-cinema');
            }
            // setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };
  const generateTransactionId = (orderId: string, paymentOption: string, path: string) => {
    const paymentMethod = OnlinePaymentMethods[paymentOption as keyof typeof OnlinePaymentMethods];

    paymentRequestGenerateTransaction({
      amount: Number(totalSum.toFixed(2)),
      status: 0,
      orderIds: [orderId],
      paymentMethod: {
        name: paymentMethod,
        qrImage: {
          imageUrl: '',
          thumbUrl: '',
          width: 0,
          hight: 0,
        },
        accountName: '',
        accountNumber: '',
        chargePercentage: 0,
        icon: {
          imageUrl: '',
          thumbUrl: '',
          width: 0,
          hight: 0,
        },
      },
      transactionCharges: 0,
      remarks: '',
    }).then((res: any) => {
      setLoading(false);
      if (res && res?.id) {
        localStorage.setItem('TRANSACTION_ID', res.id);
        paymentABAKHPP(res.id, path, paymentOption);
      }
    });
  };
  const paymentRequest = (orderId: string, path: string, method: string) => {
    ReactGA.event({
      category: `Requested Online Payment`,
      action: `Requested Online Payment`,
      transport: 'xhr', // optional, beacon/xhr/image
    });
    localStorage.setItem('KESS_PAYMENT_METHOD', method);
    const chargePercentage = method === 'VISA_MASTER' ? 0.03 : 0;
    updateOrder(orderId, { paymentMethod: { name: method, chargePercentage: chargePercentage } });
    const baseUrl = window.location.protocol + '//' + window.location.host;
    if (totalSum && outletId && orgId && detail) {
      const redirectURL = generatePathHelpForHomePage(path, { outletId, orgId }, search);
      const totalAmount = parseFloat((spot.type == SPOT_DELIVERY ? totalSumDelivery : price).toFixed(2));
      let totalFee = totalAmount * 0.03;
      const paymentParams: RequestPayment = {
        body: detail.name,
        amount: Number(method === 'VISA_MASTER' ? (totalAmount + totalFee).toFixed(2) : totalAmount.toFixed(2)),
        fee: method === 'VISA_MASTER' ? parseFloat(totalFee.toFixed(2)) : 0,
        order_id: orderId,
        redirect_url: `${baseUrl}${redirectURL.pathname}${redirectURL.search}`,
        tip: 0,
        discount: 0,
        total: Number(method === 'VISA_MASTER' ? (totalAmount + totalFee).toFixed(2) : totalAmount.toFixed(2)),
        payment_method: method,
      };
      paymentRequestService(paymentParams)
        .then((responseData) => {
          window.location.href = responseData;
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error);
        });
    }
  };
  const paymentABAKHPP = (id: string, url: string, paymentOption: string) => {
    if (outletId && orgId) {
      const reqTime = +new Date();
      const merchantId: string | undefined = (window as any).REACT_APP_MERCHANT_ID as string;
      const apiKey: string | undefined = (window as any).REACT_APP_API_KEY_PAYMENT as string;
      const baseURL: string | undefined = (window as any).REACT_APP_ABAKHPP_URL_PURCHASE as string;
      const tranId = id;
      const redirectURL = generatePathHelpForHomePage(url, { outletId, orgId }, search);
      const amount = spot.type == SPOT_DELIVERY ? totalSumDelivery : totalSum;
      const option = paymentOption;
      const success = `${(window as any).REACT_APP_REDIRECT_SUCCESS}${redirectURL.pathname}${redirectURL.search}`;
      const hmac = CryptoJS.HmacSHA512(reqTime + merchantId + tranId + amount + option + success, apiKey);
      const signature = CryptoJS.enc.Base64.stringify(hmac);
      const _data = {
        continue_success_url: success,
        form_url: baseURL,
        tran_id: tranId,
        merchant_id: merchantId,
        req_time: reqTime,
        amount,
        hash: signature,
        payment_option: option,
        onSuccess: (data: any) => {},
        onError(data: any) {
          console.log(data);
        },
      };

      // eslint-disable-next-line no-undef
      const obj: any = new AbaPay();
      obj.checkout(_data);
    }
  };
  const successAction = () => {
    const { outletId, orgId } = routeParam;
    if (outletId && orgId) {
      const path = generatePathHelpForHomePage('/order-summary', { outletId, orgId }, search);
      navigate(path.pathname + path.search);
    }
    setLoading(false);
  };

  const handlePayment = (paymentType: PaymentTypeUnion, method: string, notes?: string) => {
    setselectedPaymentType(paymentType);
    setPaymentMethod(method);
    ReactGA.event({
      category: `Order Submitted for ${spot.type}`,
      action: `Order Submitted`,
      transport: 'xhr',
    });
    if (spot.type == SPOT_DELIVERY) {
      onSubmitDelivery(paymentType, method);
    } else if (spot.type == SPOT_PICKUP_TYPE) {
      onSubmitPickup();
    } else if (spot.type == SPOT_RESERVATION_PREORDER) {
      onSubmitPreOrder(paymentType, method);
    } else if (spot.type == SPOT_CINEMA_SEAT || spot.type == SPOT_CINEMA_COUNTER || spot.type == SPOT_CINEMA_HALL) {
      onSubmitCinema(paymentType, method, notes);
    } else {
      onClickSubmitOrder();
    }

    setOpenSelectPayment(!openSelectPayment);
  };

  const handleCinemaInfo = (CinemaType: String) => {
    if (CinemaType == 'COUNTER') {
      setOpenCinemaInformation(true);
    } else {
      setOpenSelectPayment(true);
    }
  };
  const callForWaiter = (type: PaymentTypeUnion, orderId: string, cashNote?: string) => {
    const param = {
      type: 'Call for Bill',
      message:
        type === 'CASHWITHNOTE' && cashNote
          ? `${
              cinemaInfo && (spot.type == SPOT_CINEMA_COUNTER || spot.type == SPOT_CINEMA_HALL)
                ? orderId +
                  ' - ' +
                  cinemaInfo.hallNumber.toUpperCase() +
                  ' - ' +
                  cinemaInfo.seatNumber.toUpperCase() +
                  ' - ' +
                  (cinemaInfo.phoneNumber ? '+855' + cinemaInfo.phoneNumber + ' - ' : '')
                : ''
            }${cashNote}`
          : `${
              cinemaInfo && (spot.type == SPOT_CINEMA_COUNTER || spot.type == SPOT_CINEMA_HALL)
                ? orderId +
                  ' - ' +
                  cinemaInfo.hallNumber.toUpperCase() +
                  ' - ' +
                  cinemaInfo.seatNumber.toUpperCase() +
                  ' - ' +
                  (cinemaInfo.phoneNumber ? '+855' + cinemaInfo.phoneNumber : '')
                : ''
            }`,
      paymentType: PaymentType[type],
    };
    if (outletId && spotId) {
      dispatch(callingWaiter(spotId, outletId, param));
    }
  };
  const PaymentFeeTotal = () => {
    if (spot.type == SPOT_DELIVERY) {
      return totalSumDelivery;
    } else if (spot.type == SPOT_PICKUP_TYPE) {
      return totalSumPickup;
    } else {
      return totalSum;
    }
  };
  const handleDeliveryEditAndSubmit = () => {
    if (isDeliveryEdit) {
      setIsDeliveryEdit(false);
    } else {
      setOpenSelectPayment(true);
    }
  };

  const [expandCharges, setExpandCharges] = useState(true);
  return (
    <>
      {openSelectPayment && !isDeliveryEdit && (
        <SelectPaymentMethod
          // setPaymentMethod={(param: string) => setPaymentMethod(param)}
          cinemaInfo={spot && spot.type == SPOT_CINEMA_COUNTER ? cinemaInfo : null}
          cancelCalled={() => setOpenSelectPayment(!openSelectPayment)}
          clickedSubmit={(param: PaymentTypeUnion, method: string, notes?: string) =>
            handlePayment(param, method, notes)
          }
          totalSum={PaymentFeeTotal()}
        />
      )}
      {isPaymentLinkReady && <ProceedToPayment url={paymentLink} />}

      <div className="bottom-sheet-review-order">
        {isOutletBusy && <OutletBusyDialog setIsOutletBusy={(isOpen: boolean) => setIsOutletBusy(isOpen)} />}

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="header">
          <div className="flex-row space-between">
            <img src="/assets/icons/back.svg" onClick={() => props.onClosed()} alt="" />
            {/* <div>
            <img src="/assets/icons/menu-hamburger-regular.svg" alt="" />
          </div> */}
          </div>
          <div className="mt-26 flex-row space-between">
            <div className="bottom-sheet-title">{t('review_order')}</div>

            <div className="flex-row column-gap-10" onClick={() => props.clickAddOrder()}>
              {/* <img src="/assets/icons/plus-circle.svg" alt="" /> */}
              <Icon icon="el:plus-sign" color={brandColor} width={20} />
              <div className="edit-text" style={{ color: brandColor }}>
                {t('add_order')}
              </div>
            </div>
          </div>
        </div>
        <div className="content" style={{ marginBottom: '120px' }}>
          {spot.type == SPOT_DELIVERY && <DeliveryDetails onFillDeliveryInfo={onFillDeliveryInfo} />}
          {items.length > 0 &&
            items.map((item: Item, index: number) => (
              <OrderItem index={index} item={item} quantityChange={setQuantity} key={index} />
            ))}
          {items.length > 0 ? (
            <>
              <div className="bottom-btn">
                <div className="padding">
                  <div
                    onClick={() => setExpandCharges(!expandCharges)}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '-15px',
                    }}
                  >
                    <Icon icon={expandCharges ? 'mingcute:up-line' : 'mingcute:down-line'} color="#000" width={30} />
                  </div>
                  <div style={{ marginBottom: '10px' }}>
                    {expandCharges && (
                      <ChargesSection totalPrice={price} deliveryCharge={deliveryInfo.deliveryCharge} />
                    )}
                  </div>
                  {spot.type == SPOT_DELIVERY && (
                    <button
                      disabled={isLoading}
                      className="btn primary pd-12 flex-row space-between contact-info"
                      onClick={() => setOpenSelectPayment(true)}
                      style={{
                        backgroundColor: brandColor,
                        borderColor: brandColor,
                        boxShadow: `0px 4px 4px ${brandColor}40`,
                      }}
                    >
                      <div className="flex-row center column-gap-10">
                        <span>{t('submit_order')}</span>
                      </div>
                      <div className="flex-row column-gap-16">
                        <div>$ {totalSumDelivery.toFixed(2)}</div>

                        {!isLoading && <img src="/assets/icons/next-white.svg" alt="" />}
                        {isLoading && <LoadingSpinner brandColor={brandColor} />}
                      </div>
                    </button>
                  )}
                  {(spot.type == SPOT_CINEMA_SEAT ||
                    spot.type == SPOT_CINEMA_COUNTER ||
                    spot.type == SPOT_CINEMA_HALL) && (
                    <button
                      disabled={isLoading}
                      className="btn primary pd-12 flex-row space-between contact-info"
                      onClick={() => handleCinemaInfo(spot.type == SPOT_CINEMA_SEAT ? 'SEAT' : 'COUNTER')}
                      style={{
                        backgroundColor: brandColor,
                        borderColor: brandColor,
                        boxShadow: `0px 4px 4px ${brandColor}40`,
                      }}
                    >
                      <div className="flex-row center column-gap-10">
                        <span>{t('submit_order')}</span>
                      </div>
                      <div className="flex-row column-gap-16">
                        <div>$ {totalSum.toFixed(2)}</div>

                        {!isLoading && <img src="/assets/icons/next-white.svg" alt="" />}
                        {isLoading && <LoadingSpinner brandColor={brandColor} />}
                      </div>
                    </button>
                  )}
                  {spot.type == SPOT_PICKUP_TYPE && (
                    <div
                      className="btn primary pd-12 flex-row space-between contact-info"
                      onClick={() => onFillPickupInfo()}
                      style={{
                        backgroundColor: brandColor,
                        borderColor: brandColor,
                        boxShadow: `0px 4px 4px ${brandColor}40`,
                      }}
                    >
                      <div className="flex-row center column-gap-10">
                        <span>{t('contact_information')}</span>
                      </div>
                      <div className="flex-row column-gap-16">
                        <div>$ {totalSumPickup.toFixed(2)}</div>

                        {!isLoading && <img src="/assets/icons/next-white.svg" alt="" />}
                        {isLoading && <LoadingSpinner brandColor={brandColor} />}
                      </div>
                    </div>
                  )}
                  {spot.type == SPOT_RESERVATION_PREORDER && (
                    <div
                      className="btn primary pd-12 flex-row space-between contact-info"
                      // onClick={() => onSubmitPreOrder()}
                      onClick={() => setOpenSelectPayment(true)}
                      style={{
                        backgroundColor: brandColor,
                        borderColor: brandColor,
                        boxShadow: `0px 4px 4px ${brandColor}40`,
                      }}
                    >
                      <div className="flex-row center column-gap-10">
                        <span>{t('submit_pre_order')}</span>
                      </div>
                      <div className="flex-row column-gap-16">
                        <div>$ {totalSumPickup.toFixed(2)}</div>

                        {!isLoading && <img src="/assets/icons/next-white.svg" alt="" />}
                        {isLoading && <LoadingSpinner brandColor={brandColor} />}
                      </div>
                    </div>
                  )}
                  {spot.type == SPOT_QRTABLE && (
                    <div
                      className="btn primary pd-12 flex-row space-between"
                      onClick={() => onClickSubmitOrder()}
                      style={{
                        backgroundColor: brandColor,
                        borderColor: brandColor,
                        boxShadow: `0px 4px 4px ${brandColor}40`,
                      }}
                    >
                      <div className="flex-row center column-gap-10">
                        <span>{t('submit_order')}</span>
                      </div>
                      <div className="flex-row column-gap-16">
                        <div>$ {totalSum.toFixed(2)}</div>

                        {!isLoading && <img src="/assets/icons/next-white.svg" alt="" />}
                        {isLoading && <LoadingSpinner brandColor={brandColor} />}
                      </div>
                    </div>
                  )}
                  {spot.type == SPOT_HOTEL_ROOM_SERVICE && (
                    <div
                      className="btn primary pd-12 flex-row space-between"
                      onClick={() => onClickSubmitOrderHotelRoomService()}
                      style={{
                        backgroundColor: brandColor,
                        borderColor: brandColor,
                        boxShadow: `0px 4px 4px ${brandColor}40`,
                      }}
                    >
                      <div className="flex-row center column-gap-10">
                        <span>{t('submit_order')}</span>
                      </div>
                      <div className="flex-row column-gap-16">
                        <div>$ {totalSum.toFixed(2)}</div>

                        {!isLoading && <img src="/assets/icons/next-white.svg" alt="" />}
                        {isLoading && <LoadingSpinner brandColor={brandColor} />}
                      </div>
                    </div>
                  )}
                </div>
                <div className="order-description">
                  <p>{t('order_message_1')}</p>
                </div>
              </div>
            </>
          ) : (
            <div className="order-summary-empty" style={{ marginTop: '2vh' }}>
              <h2 className="title mb-22">{t('no_order')}</h2>
              <button
                onClick={() => props.clickAddOrder()}
                className="btn primary"
                style={{
                  backgroundColor: brandColor,
                  borderColor: brandColor,
                  boxShadow: `0px 4px 4px ${brandColor}40`,
                }}
              >
                {t('order_now')}
              </button>
            </div>
          )}
        </div>
        {openPickUpInformation && (
          <PickUpInformation
            onSubmitPickup={onSubmitPickup}
            onClosed={() => setOpenPickUpInformation(false)}
            pickupInfo={pickupInfo}
            setPickupInfo={setPickupInfo}
            price={totalSumPickup}
            list={list}
          />
        )}
        {openCinemaInformation && (
          <CinemaCounterInformation
            onSubmitCinemaInfo={() => {
              setOpenSelectPayment(true);
              setOpenCinemaInformation(false);
            }}
            cinemaInfo={cinemaInfo}
            setCinemaInfo={setCinemaInfo}
            onClosed={() => setOpenCinemaInformation(false)}
            brandColor={brandColor}
          />
        )}

        {openDeliveryInformation && (
          <DeliveryInformation
            isSubmit={false}
            onSubmitDelivery={() => handleDeliveryEditAndSubmit()}
            onClosed={() => setOpenDeliveryInformation(false)}
            deliveryInfo={deliveryInfo}
            setDeliveryInfo={setDeliveryInfo}
            price={price}
            list={filteredList}
            isDeliveryEdit={isDeliveryEdit}
            setIsDeliveryEdit={setIsDeliveryEdit}
          />
        )}
      </div>
    </>
  );
};
