import OrderSummary from 'pages/OrderSummary';
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import Location from 'components/partials/Location';
import { ILocation } from 'interfaces/models/organization';
import { useAppSelector } from 'hooks/redux';
import { RootState } from 'store/configureStore';
import { getSpotById } from 'helper/spot';
import { generatePathHelpForHomePage, getSpotId } from 'helper/path';
import { set } from 'lodash';
import { SPOT_QRTABLE } from 'constants/spot';
import MenuListing from 'pages/MenuListing';
import { MenuDetails } from 'pages/MenuDetail';
import { PickupOrderSummary } from 'pages/PickUpOrderSummary';
import { DeliveryOrderSumary } from 'pages/DeliveryOrderSummary';
import { ThankYourForOrdering } from 'pages/ThankYouForOrder';
import RatingYourFood from 'pages/RatingFood';
import NotFoundPage from 'pages/NotFound';
import { useGeolocated } from 'react-geolocated';
import { useLocation, useNavigate } from 'react-router-dom';
import { TableReservationConfirmation } from 'pages/TableReservationConfirmation';
import { PreOrderSumary } from 'pages/PreOrderSummary';
import { fetchOutletByOutletId } from 'services/outlets';
import { ContactUs } from 'pages/ContactUs';
import { CinemaOrderSummary } from 'pages/CinemaOrderSummary';
import RatingOutlet from 'pages/RatingOutlet';
import SelectOrgPage from 'pages/SelectOrganization';

// Import your pages here

function Router() {
  const loc = useLocation();
  const currentPath = loc.pathname;
  const pathParts = currentPath.split('/');
  const outletIndex = pathParts.indexOf('outlet');
  const outletId = pathParts[outletIndex + 1];
  const navigate = useNavigate();
  const isOnline = navigator.onLine;
  const [openPopUp, setOpenPopUp] = useState(false);
  const [messageID, setMessageID] = useState<number>(0);
  const [distance, setDistance] = useState<number>(0);
  // const spotId = getSpotId();
  // const spotType = getSpotById(spotId);
  // const outlets = useAppSelector((state: RootState) => state.outlets.list);
  // const filteredOutlet = outlets.filter((x) => x.id === outletId);
  // const location = filteredOutlet[0] ? filteredOutlet[0].location : undefined;
  // const minDistanceInKMToPlaceOrder = filteredOutlet[0] ? filteredOutlet[0].minDistanceInKMToPlaceOrder : undefined;
  useEffect(() => {
    if (pathParts[2] === 'undefined') {
      fetchOutletByOutletId(outletId)
        .then((responseData) => {
          if (responseData) {
            const orgId = responseData.organiationId;
            const path = generatePathHelpForHomePage('/', { outletId, orgId }, loc.search);
            navigate(path.pathname + path.search);
          }
        })
        .catch((error) => {
          toast.error('Something went wrong');
          console.error('Error fetching outlet by org id', error);
        });
    }
  }, []);
  // useEffect(() => {
  //   if (
  //     spotType &&
  //     spotType.type === Number(SPOT_QRTABLE) &&
  //     location &&
  //     location.latititude !== 0 &&
  //     location.longitude !== 0 &&
  //     minDistanceInKMToPlaceOrder !== 0
  //   ) {
  //     // Get the user's current location
  //     if ('geolocation' in navigator) {
  //       const storedGeoLocation = localStorage.getItem('GEOLOCATION_PERMISSION') ? true : false;
  //       if (!storedGeoLocation) {
  //         setMessageID(1);
  //         setOpenPopUp(true);
  //       }

  //       navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //           const userCoords = {
  //             latitude: position.coords.latitude,
  //             longitude: position.coords.longitude,
  //           };
  //           localStorage.setItem('GEOLOCATION_PERMISSION', 'true');
  //           const calculatedDistance = calculateDistance(location, userCoords);
  //           setDistance(calculatedDistance);

  //           if (calculatedDistance > (minDistanceInKMToPlaceOrder || 0) * 1000) {
  //             setMessageID(2);
  //             setOpenPopUp(true);
  //             console.error('Error getting geolocation:OUT_OF_RANGE');
  //           } else {
  //             setMessageID(0);
  //             setOpenPopUp(false);
  //           }
  //         },
  //         (error) => {
  //           if (error.code === 1) {
  //             setMessageID(1);
  //             setOpenPopUp(true);
  //           }
  //         },
  //         { enableHighAccuracy: true }
  //       );
  //     }
  //   }
  // }, [location, minDistanceInKMToPlaceOrder, spotType]);

  const calculateDistance = (location: ILocation, userCoords: { latitude: number; longitude: number }): number => {
    const lat1 = location.latititude;
    const long1 = location.longitude;
    const lat2 = userCoords.latitude || 0;
    const long2 = userCoords.longitude || 0;

    const R = 6371000; // Earth's radius in meters
    // const R = 6371; // Earth's radius in kilometer
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (long2 - long1) * (Math.PI / 180);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return Math.round(d);
  };
  useEffect(() => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, [currentPath]);
  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="pop-up">
        {openPopUp && <Location setPopUp={setOpenPopUp} messageID={messageID} distance={distance} />}
      </div>
      <Routes>
        <Route path="/org/:orgId/outlet/:outletId/" element={<MenuListing />} />
        <Route
          path="/org/:orgId/outlet/:outletId/menu/:id"
          element={<MenuDetails messageID={messageID} distance={distance} />}
        />
        <Route path="/org/:orgId/outlet/:outletId/order-summary" element={<OrderSummary />} />
        <Route path="/org/:orgId/outlet/:outletId/contact-us" element={<ContactUs />} />
        <Route path="/org/:orgId/outlet/:outletId/order-summary-pickup" element={<PickupOrderSummary />} />
        <Route path="/org/:orgId/outlet/:outletId/order-summary-delivery" element={<DeliveryOrderSumary />} />
        <Route path="/org/:orgId/outlet/:outletId/order-summary-cinema" element={<CinemaOrderSummary />} />
        <Route
          path="/org/:orgId/outlet/:outletId/table-reservation-confirmation"
          element={<TableReservationConfirmation />}
        />
        <Route path="/org/:orgId/outlet/:outletId/pre-order-summary" element={<PreOrderSumary />} />
        <Route path="/org/:orgId/outlet/:outletId/thank-you" element={<ThankYourForOrdering />} />
        <Route path="/org/:orgId/outlet/:outletId/rating-menu" element={<RatingYourFood />} />
        <Route path="/org/:orgId/outlet/:outletId/rating-outlet" element={<RatingOutlet />} />
        {/* <Route path="*" element={<NotFoundPage />} /> */}
        <Route path="/org/:orgId/" element={<NotFoundPage />} />
        <Route path="*" element={<SelectOrgPage />} />
      </Routes>
    </React.Fragment>
  );
}

export default Router;
