import { SET_LANGUAGE } from 'constants/localization';
import { SPOT_HOTEL_ROOM_SERVICE, SPOT_MENU_ONLY, SPOT_QRTABLE } from 'constants/spot';
import { MyImages } from 'cores/components';
import { Footer } from 'cores/components/layout/footer';
import { getSpotId } from 'helper/path';
import { getSpotById } from 'helper/spot';
import { useAppSelector } from 'hooks/redux';
import { IGroupMenu } from 'interfaces/models/menu';
import _ from 'lodash';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Switch from 'react-switch';

type PropType = {
  onClose: () => void;
  openMenuLanguage: (val: boolean) => void;
  openCallWaiter: (val: boolean) => void;
  openWifi: (val: boolean) => void;
  goToOrderHistory: () => void;
  outlet: any;
};

const MenuShortcutDrawer = (props: PropType) => {
  const { detail } = useAppSelector((state) => state.organization);
  const brandColor = (detail?.primaryColor && detail?.primaryColor.replace(/'/g, '')) || '#DD5F36';
  const [countdown, setCountdown] = useState(999999);
  const { t } = useTranslation();
  const drawerRef = useRef<HTMLDivElement>(null);
  const { onClose, openMenuLanguage, openCallWaiter, openWifi, goToOrderHistory, outlet } = props;
  const spotId = getSpotId();
  const spot = getSpotById(spotId);
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    const handleClickOutside = (event: MouseEvent) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
        props.onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    const intervalId = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    if (countdown === 0) {
      clearInterval(intervalId);
      props.onClose();
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('mousedown', handleClickOutside);
      clearInterval(intervalId);
    };
  }, [countdown, props]);

  return (
    <div className="bottom-sheet-wrapper">
      <div
        ref={drawerRef}
        className="bottom-sheet-content content padding"
        style={{ paddingBottom: '0px', overflow: 'unset' }}
      >
        <div className="navigation-wrapper pickup-information">
          <div
            className="language-title"
            style={{
              marginBottom: '5px',
            }}
          >
            <p
              style={{
                textTransform: 'uppercase',
              }}
            >
              Shortcut Menu
            </p>
          </div>
          <div
            style={{
              overflowY: 'auto',
              maxHeight: '70vh',
            }}
          >
            <div className="overview-container-shortcut">
              <div className="overview-card-shortcut ">
                <div className="overview-card-content-column-shortcut">
                  <div
                    className="overview-card-content-row-item-shortcut overview-card-content-border-shortcut"
                    onClick={() => {
                      onClose();
                      openMenuLanguage(true);
                    }}
                    style={{ borderTop: '1px solid rgb(211 212 213 / 20%)' }}
                  >
                    <div className="overview-card-content-item-shortcut">
                      <img src="/assets/icons/overview/globe.svg" alt="" className="content-item-icon-shortcut" />
                      <span className="content-item-label-shortcut">{t('menu_language')}</span>
                    </div>
                    <img
                      src="/assets/icons/overview/arrowright.svg"
                      alt=""
                      className="content-item-right-icon-shortcut"
                    />
                  </div>
                  {spot &&
                    (spot.type == SPOT_QRTABLE || spot.type == SPOT_HOTEL_ROOM_SERVICE) &&
                    outlet?.wiFis &&
                    outlet?.wiFis.length > 0 && (
                      <div
                        className="overview-card-content-row-item-shortcut overview-card-content-border-shortcut"
                        onClick={() => {
                          onClose();
                          openWifi(true);
                        }}
                      >
                        <div className="overview-card-content-item-shortcut">
                          <img src="/assets/icons/overview/wifi.svg" alt="" className="content-item-icon-shortcut" />
                          <span className="content-item-label-shortcut">{t('wifi_password')}</span>
                        </div>
                        <img
                          src="/assets/icons/overview/arrowright.svg"
                          alt=""
                          className="content-item-right-icon-shortcut"
                        />
                      </div>
                    )}
                  {spot && spot.type == SPOT_QRTABLE && (
                    <div
                      className="overview-card-content-row-item-shortcut overview-card-content-border-shortcut"
                      onClick={() => {
                        onClose();
                        openCallWaiter(true);
                      }}
                    >
                      <div className="overview-card-content-item-shortcut">
                        <img src="/assets/icons/overview/bell.svg" alt="" className="content-item-icon-shortcut" />
                        <span className="content-item-label-shortcut">{t('call_service')}</span>
                      </div>
                      <img
                        src="/assets/icons/overview/arrowright.svg"
                        alt=""
                        className="content-item-right-icon-shortcut"
                      />
                    </div>
                  )}
                  {spot && spot.type != SPOT_MENU_ONLY && (
                    <div
                      className="overview-card-content-row-item-shortcut"
                      onClick={() => {
                        onClose();
                        goToOrderHistory();
                      }}
                    >
                      <div className="overview-card-content-item-shortcut">
                        <img src="/assets/icons/overview/receipt.svg" alt="" className="content-item-icon-shortcut" />
                        <span className="content-item-label-shortcut">{t('order_history')}</span>
                      </div>
                      <img
                        src="/assets/icons/overview/arrowright.svg"
                        alt=""
                        className="content-item-right-icon-shortcut"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuShortcutDrawer;
