import { SET_CATEGORY_LIST } from 'constants/category';
import {
  CREATE_MENU_FEED_BACK_SUCCESS,
  GET_MENU_LIST_REQUEST,
  GET_MENU_OPTIONS,
  GET_MENU_OPTIONS_REQUEST_SUCCESS,
  GROUP_MENU_LIST_REQUEST_SUCCESS,
} from 'constants/menu';
import { createListAction } from 'generics/action-creator';
import { ICategory } from 'interfaces/models/category';
import { MenuOption, OutletMenuItem } from 'interfaces/models/menu';
import _, { forEach, orderBy } from 'lodash';
import { Action, Dispatch } from 'redux';
import { feedBackMenu, getMenuByOutletId, getMenuDetailById, getMenuOptionByMenuId } from 'services/menu';
import { RootState } from 'store/configureStore';

import { PayloadAction } from '@reduxjs/toolkit';

import {
  CREATE_MENU_FEED_BACK,
  GET_MENU_DETAILS_REQUEST,
  GET_MENU_DETAILS_REQUEST_SUCCESS,
  GET_MENU_LIST_REQUEST_SUCCESS,
} from '../../constants/menu';
import { FeedBackMenuParam } from '../../interfaces/models/feedback';
import { AddOn } from '../../interfaces/models/menu';

export const getAllMenuByOutletId =
  (outletId: string): any =>
  (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const options = {
      serviceMethod: getMenuByOutletId,
      dispatch,
      getState,
      requestName: GET_MENU_LIST_REQUEST,
      successAction: getAllMenuSuccessAction,
    };

    return createListAction<OutletMenuItem[], string>(options, outletId);
  };

export const getMenuDetailsById =
  (outletId: string, menuId: string): any =>
  (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const options = {
      serviceMethod: getMenuDetailById,
      dispatch,
      getState,
      successAction: successActionOnMenuDetails,
      requestName: GET_MENU_DETAILS_REQUEST,
    };

    return createListAction<OutletMenuItem>(options, { outletId, menuId });
  };

function successActionOnMenuDetails(
  payload: OutletMenuItem,
  dispatch: Dispatch<Action>
): PayloadAction<OutletMenuItem> {
  const addOns = payload.addOns;
  payload.groupedAddOns = [];
  if (addOns && addOns.length) {
    const assignCategoryIfNull = addOns.map((item: AddOn) => {
      item.category = !item.category ? 'Other Add Ons' : item.category;
      return item;
    });
    const orderAddOn = orderBy(assignCategoryIfNull, 'sequence', 'asc');
    const groupedAddOn = _.groupBy(orderAddOn, 'category');
    payload.groupedAddOns = Object.keys(groupedAddOn).map((key: string) => ({
      name: key,
      description: groupedAddOn[key][0].description,
      addOns: orderBy(groupedAddOn[key], 'sequence', 'asc'),
    }));
  }

  return { type: GET_MENU_DETAILS_REQUEST_SUCCESS, payload };
}

function getAllMenuSuccessAction(
  response: OutletMenuItem[],
  dispatch: Dispatch<Action>
): PayloadAction<OutletMenuItem[]> {
  if (response.length) {
    const groupedMenu = _.chain(response)
      .groupBy((item) => item.itemCategory?.name ?? 'Others')
      .value();

    // Check if PREVIOUS_ORDERED_ITEMS is in localStorage and has items
    const previousOrderedItems = localStorage.getItem('PREVIOUS_ORDERED_ITEMS');
    if (previousOrderedItems) {
      const orderedItems = JSON.parse(previousOrderedItems);
      if (Array.isArray(orderedItems) && orderedItems.length > 0) {
        const orderedItemIds = orderedItems.map((item) => item.menuId);

        // Search for items in the response with matching menuIds
        const orderAgainItems = response.filter((item) => orderedItemIds.includes(item.id));

        if (orderAgainItems.length > 0) {
          const previousOrderedCategory = 'Previously Ordered - ធ្លាប់បញ្ជាទិញពីមុន';
          groupedMenu[previousOrderedCategory] = [...(groupedMenu[previousOrderedCategory] || []), ...orderAgainItems];
        }
      }
    }

    const menu = Object.keys(groupedMenu).map((key: string) => {
      const menuItems = groupedMenu[key];
      return {
        category: key,
        sequence:
          key === 'Others'
            ? Math.max(...response.map((item) => item.itemCategory?.sequence || 0)) + 10
            : menuItems[0].itemCategory?.sequence ?? 0,
        menu: menuItems,
      };
    });

    // Sort the menu array to ensure "Others" category appears at the end
    menu.sort((a, b) => {
      if (a.category === 'Previously Ordered - ធ្លាប់បញ្ជាទិញពីមុន') return -1;
      if (b.category === 'Previously Ordered - ធ្លាប់បញ្ជាទិញពីមុន') return 1;
      if (a.category === 'Others') return 1;
      if (b.category === 'Others') return -1;
      return a.category.localeCompare(b.category);
    });

    const category = Object.keys(groupedMenu).map((name: string) => ({ name }));

    dispatch({ type: SET_CATEGORY_LIST, payload: category });
    dispatch({ type: GROUP_MENU_LIST_REQUEST_SUCCESS, payload: menu });
  }

  return { type: GET_MENU_LIST_REQUEST_SUCCESS, payload: response };
}
export const getMenuOptionsById =
  (outletId: string, menuId: string): any =>
  (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const options = {
      serviceMethod: getMenuOptionByMenuId,
      dispatch,
      getState,
      successAction: successActionOnMenuOptions,
      requestName: GET_MENU_OPTIONS,
    };

    return createListAction<MenuOption>(options, { outletId, menuId });
  };

function successActionOnMenuOptions(payload: MenuOption, dispatch: Dispatch<Action>): PayloadAction<MenuOption> {
  const menuOptions: any = [payload];
  const uniqueIds = new Set<string>();
  const filteredMenuOptions = menuOptions[0].filter((option: any) => {
    if (!uniqueIds.has(option.id)) {
      uniqueIds.add(option.id);
      return option;
    }
    return false;
  });
  return { type: GET_MENU_OPTIONS_REQUEST_SUCCESS, payload: filteredMenuOptions };
}

// function getMenuOptionsSuccessAction(
//   response: OutletMenuItem[],
//   dispatch: Dispatch<Action>
// ): PayloadAction<OutletMenuItem[]> {
//   if (response.length) {
//     const groupedMenu = _.chain(response)
//       .groupBy((item) => item.itemCategory?.name ?? 'Others')
//       .value();

//     // Check if PREVIOUS_ORDERED_ITEMS is in localStorage and has items
//     const previousOrderedItems = localStorage.getItem('PREVIOUS_ORDERED_ITEMS');
//     if (previousOrderedItems) {
//       const orderedItems = JSON.parse(previousOrderedItems);
//       if (Array.isArray(orderedItems) && orderedItems.length > 0) {
//         const orderedItemIds = orderedItems.map((item) => item.menuId);

//         // Search for items in the response with matching menuIds
//         const orderAgainItems = response.filter((item) => orderedItemIds.includes(item.id));

//         if (orderAgainItems.length > 0) {
//           const previousOrderedCategory = 'Previously Ordered - ធ្លាប់បញ្ជាទិញពីមុន';
//           groupedMenu[previousOrderedCategory] = [...(groupedMenu[previousOrderedCategory] || []), ...orderAgainItems];
//         }
//       }
//     }

//     const menu = Object.keys(groupedMenu).map((key: string) => {
//       const menuItems = groupedMenu[key];
//       return {
//         category: key,
//         menu: menuItems,
//       };
//     });

//     // Sort the menu array to ensure "Others" category appears at the end
//     menu.sort((a, b) => {
//       if (a.category === 'Previously Ordered - ធ្លាប់បញ្ជាទិញពីមុន') return -1;
//       if (b.category === 'Previously Ordered - ធ្លាប់បញ្ជាទិញពីមុន') return 1;
//       if (a.category === 'Others') return 1;
//       if (b.category === 'Others') return -1;
//       return a.category.localeCompare(b.category);
//     });

//     const category = Object.keys(groupedMenu).map((name: string) => ({ name }));

//     dispatch({ type: SET_CATEGORY_LIST, payload: category });
//     dispatch({ type: GROUP_MENU_LIST_REQUEST_SUCCESS, payload: menu });
//   }

//   return { type: GET_MENU_LIST_REQUEST_SUCCESS, payload: response };
// }
export const submitFeedBackMenu =
  (params: FeedBackMenuParam[]): any =>
  (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const successAction = (): PayloadAction<any> => {
      return { type: CREATE_MENU_FEED_BACK_SUCCESS, payload: params };
    };

    const options = {
      serviceMethod: feedBackMenu,
      dispatch,
      getState,
      successAction,
      requestName: CREATE_MENU_FEED_BACK,
    };

    return createListAction<any, FeedBackMenuParam[]>(options, params);
  };
