import { CART_REQUEST_EMPTY } from 'constants/carts';
import moment from 'moment';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getLatestOrder } from '../../helper/order-summary';
import { generatePathHelpForHomePage, getSpotId } from '../../helper/path';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/index';
import { RootState } from '../../store/configureStore';
import { PaymentMethods, socialMedias } from 'interfaces/models/outlets';
import { MyImages } from 'cores/components';
import ChargesSection from 'components/partials/ChargesSection';
import { FooterLogo } from 'components/partials/FooterLogo';
import DownloadReceipt from 'cores/components/DownloadReceipt';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { SPOT_DINE_IN, SPOT_QRTABLE } from 'constants/spot';
import { getSpotById } from 'helper/spot';
import { PaymentType } from 'enums/payment-type';
import { POST_TAB_REQUEST } from 'constants/tabs';
import { checkTransactionByTransactionId } from 'services/payment';
import CryptoJS from 'crypto-js';
import { ADD_PAID_ORDERS, ORDER_UPDATE_ITEM } from 'constants/order';
import { CreateOrderResponse } from 'interfaces/models/order';
import { updateOrderPaymentDetails } from 'services/order';
import { Helmet } from 'react-helmet-async';
import { displayEstimationRange } from 'helper/date';

export const ThankYourForOrdering = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const route = useParams();
  const { outletId, orgId } = route;
  const list = getLatestOrder();
  const spotId = getSpotId();
  const spot = getSpotById(spotId);
  const filteredList = list.filter((item) => item.outletId === outletId && item.outletSpotId === spotId);
  const { paidOrders } = useAppSelector((state) => state.orders);
  const filteredUnPaidOrders: CreateOrderResponse[] = filteredList
    .map((item) => {
      if (!paidOrders.includes(item.id)) {
        return { ...item };
      } else {
        return null;
      }
    })
    .filter((item): item is CreateOrderResponse => item !== null);
  const { t } = useTranslation();
  // Calculate the total of all items
  const discountedPriceSum =
    filteredUnPaidOrders.length > 0
      ? filteredUnPaidOrders.reduce((total, item) => {
          const price = item.discountedPrice && item.discountedPrice !== 0 ? item.discountedPrice : item.price;
          return total + price;
        }, 0)
      : 0;
  const { detail } = useAppSelector((state: RootState) => state.organization);
  const { socialMedias } = useAppSelector((state: RootState) => state.outlets.list[0]);
  const { paymentMethods } = useAppSelector((state: RootState) => state.outlets.list[0]);
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const paymentMethod: string = localStorage.getItem('paymentMethod') ?? 'KHQR';
  const outlets = useAppSelector((state: RootState) => state.outlets.list);
  const filteredOutlet = outlets.filter((x) => x.id === outletId);
  console.log('🚀 ~ ThankYourForOrdering ~ filteredOutlet:', filteredOutlet);

  const getTransactionIdFromLocalStorage = () => {
    return localStorage.getItem('TRANSACTION_ID');
  };
  const showSocials = (name: string, url: string) => {
    switch (name) {
      case 'Facebook':
        return <MyImages width="30px" src="/assets/icons/facebook.svg" onClick={() => window.open(url, '_blank')} />;
        break;
      case 'Instagram':
        return <MyImages width="30px" src="/assets/icons/instagram.svg" onClick={() => window.open(url, '_blank')} />;
        break;
      case 'Tripadvisor':
        return <MyImages width="30px" src="/assets/icons/tripadvisor.svg" onClick={() => window.open(url, '_blank')} />;
        break;
      case 'Google':
        return <MyImages width="30px" src="/assets/icons/google-fill.svg" onClick={() => window.open(url, '_blank')} />;
        break;
      case 'Tiktok':
        return <MyImages width="30px" src="/assets/icons/telegram.svg" onClick={() => window.open(url, '_blank')} />;

      case 'Telegram':
        return <MyImages width="30px" src="/assets/icons/tiktok.svg" onClick={() => window.open(url, '_blank')} />;

      default:
        return '';
        break;
    }
  };

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname + window.location.search}`,
      title: ` ${detail?.name} - QR Table Order Sumary`,
    });
    if (filteredUnPaidOrders.length > 0) {
      filteredUnPaidOrders.map((item) => {
        if (item.paymentMethod === null)
          updateOrder(item.id, {
            paymentMethod: { name: PaymentType[paymentMethod as keyof typeof PaymentType], chargePercentage: 0 },
          });
        updateOrderPaymentDetails({
          orderid: item.id,
          body: {
            paymentMethod: {
              name: PaymentType[paymentMethod as keyof typeof PaymentType],
              accountName: '',
              accountNumber: '',
              chargePercentage: 0,
            },
            paymentStatus: '0',
          },
        });
      });
    }
    return () => {
      dispatch({ type: CART_REQUEST_EMPTY });
    };
  }, []);
  const checkTransaction = (id: string) => {
    const reqTime = +new Date();
    const merchantId: string | undefined = (window as any).REACT_APP_MERCHANT_ID as string;
    const apiKey: string | undefined = (window as any).REACT_APP_API_KEY_PAYMENT as string;
    const tranId = id;
    const hmac = CryptoJS.HmacSHA512(reqTime + merchantId + tranId, apiKey);
    const signature = CryptoJS.enc.Base64.stringify(hmac);
    checkTransactionByTransactionId({
      req_time: reqTime.toString(),
      merchant_id: merchantId,
      tran_id: tranId,
      hash: signature,
    }).then((res: any) => {
      const response = JSON.parse(res);
      if (response) {
        if (response.status === 0) {
          filteredUnPaidOrders.map((item) => {
            if (item.paymentMethod === null)
              updateOrder(item.id, {
                paymentMethod: { name: PaymentType[paymentMethod as keyof typeof PaymentType], chargePercentage: 0 },
              });
            updateOrderPaymentDetails({
              orderid: item.id,
              body: {
                paymentMethod: {
                  name: PaymentType[paymentMethod as keyof typeof PaymentType],
                  accountName: '',
                  accountNumber: '',
                  chargePercentage: 0,
                },
                paymentStatus: '0',
              },
            });
          });
          filteredUnPaidOrders.map((order) => {
            editOrder(order);
          });
        }
      }
    });
  };
  const editOrder = (order: Partial<CreateOrderResponse>) => {
    dispatch({
      type: ADD_PAID_ORDERS,
      payload: { id: order.id, order },
    });
  };
  const clickMakeOtherOrder = () => {
    if (getTransactionIdFromLocalStorage() !== null) {
      checkTransaction(getTransactionIdFromLocalStorage() as string);
    } else {
      filteredUnPaidOrders.map((item) => {
        if (item.paymentMethod === null)
          updateOrder(item.id, {
            paymentMethod: { name: PaymentType[paymentMethod as keyof typeof PaymentType], chargePercentage: 0 },
          });
        updateOrderPaymentDetails({
          orderid: item.id,
          body: {
            paymentMethod: {
              name: PaymentType[paymentMethod as keyof typeof PaymentType],
              accountName: '',
              accountNumber: '',
              chargePercentage: 0,
            },
            paymentStatus: '0',
          },
        });
      });
      filteredUnPaidOrders.map((order) => {
        editOrder(order);
      });
    }
    dispatch({ type: POST_TAB_REQUEST, payload: null });
    dispatch({ type: CART_REQUEST_EMPTY });
    if (outletId && orgId) {
      const path = generatePathHelpForHomePage('/', { outletId, orgId }, search);
      navigate(path.pathname + path.search);
    }
  };
  const updateOrder = (id: string, updatedProperties: Partial<CreateOrderResponse>) => {
    dispatch({
      type: ORDER_UPDATE_ITEM,
      payload: { id, ...updatedProperties },
    });
  };
  return (
    <>
      {detail && detail.name && (
        <Helmet>
          <title>{detail?.name}@nTnQR</title>
          <meta property="og:title" content={detail?.name} />
          <meta name="twitter:title" content={detail?.name} />
          {detail.image && detail.image.imageUrl ? (
            <link rel="icon" href={`${detail.image.imageUrl}`} />
          ) : (
            <>
              <link rel="apple-touch-icon" sizes="180x180" href="/assets/favicons/apple-touch-icon.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/assets/favicons/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/assets/favicons/favicon-16x16.png" />
            </>
          )}

          <link rel="manifest" href="/assets/favicons/site.webmanifest" />
        </Helmet>
      )}
      <div>
        <div className="content-wrapper">
          <div className="content-card">
            <div className="restaurant-profile">
              <img loading="lazy" src={detail?.image.imageUrl} alt={detail?.image.imageUrl} />
            </div>
            <section className="section" style={{ paddingTop: '48px' }}>
              <h2 className="title">
                {spot && spot.type != SPOT_QRTABLE ? t('thank_you_for_staying') : t('thank_you_for_dining')}
              </h2>
              {filteredOutlet[0].orderHoursOverrideStatus !== 3 &&
                filteredOutlet[0].averagePreparationTime !== null && (
                  <>
                    <div className="date mt-10">
                      Your order will be completed after{' '}
                      {displayEstimationRange(filteredOutlet[0].averagePreparationTime.minutes)}.
                    </div>
                  </>
                )}
              {filteredOutlet[0].orderHoursOverrideStatus === 3 &&
                filteredOutlet[0].estimatedPreparationTime !== null && (
                  <>
                    <div className="date mt-10">
                      Your order will be completed after{' '}
                      {filteredOutlet[0].estimatedPreparationTime?.hours > 0 &&
                        filteredOutlet[0].estimatedPreparationTime?.hours}{' '}
                      {filteredOutlet[0].estimatedPreparationTime?.hours > 0
                        ? filteredOutlet[0].estimatedPreparationTime?.hours > 1
                          ? 'hours'
                          : 'hour'
                        : ''}{' '}
                      {filteredOutlet[0].estimatedPreparationTime?.hours > 0 &&
                        filteredOutlet[0].estimatedPreparationTime?.minutes > 0 &&
                        ' and '}{' '}
                      {filteredOutlet[0].estimatedPreparationTime.minutes > 0 &&
                        filteredOutlet[0].estimatedPreparationTime.minutes + ' minutes'}
                      .
                    </div>
                  </>
                )}
              <div className="date mt-16">{moment().format('Do MMMM YYYY - hh:mm')}</div>

              <div className="socials mt-20">
                {socialMedias && socialMedias.length > 0 ? (
                  <>
                    <span>{t('connect_with_us')}:</span>
                    <div className="flex-row column-gap-10 center">
                      {socialMedias.map((item: socialMedias, index: number) => {
                        return (
                          <div key={index} className="mt-10">
                            {showSocials(item.name, item.url)}
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </section>
            <section className="section">
              {/* {spot && spot.type === 0 && ( */}
              <div className="flex-row center">
                {filteredOutlet[0] &&
                  filteredOutlet[0].paymentMethods.length > 0 &&
                  filteredOutlet[0].paymentMethods.map((item: PaymentMethods, index: number) => {
                    return (
                      // <div key={index} className="mt-10">
                      //   {item.name === 'KHQR' ? <MyImages width="120px" src={item.icon.imageUrl} /> : ''}
                      // </div>
                      <div key={index} className="mt-10">
                        {item.name === 'KHQR' ? (
                          <>
                            <div className="flex-row center" style={{ marginBottom: '10px' }}>
                              <p className="bold">{'KHQR'}</p>
                            </div>
                            <div className="flex-row center" style={{ marginBottom: '10px' }}>
                              <MyImages width="120px" src={item.icon.imageUrl} />
                            </div>
                            <div className="flex-row center" style={{ marginBottom: '5px' }}>
                              <p className="bold">{item.accountName}</p>
                            </div>
                            <div className="flex-row center" style={{ marginBottom: '5px' }}>
                              <p className="bold">{item.accountNumber}</p>
                            </div>
                            <div className="flex-row center" style={{ marginBottom: '30px' }}>
                              <p className="bold">$ {discountedPriceSum.toFixed(2)}</p>
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
              </div>
              {/* )} */}
              <div className="flex-row space-between total-amount-text">
                <div>{t('order_details')}</div>
              </div>
              <div className="flex-col pt-23">
                {filteredUnPaidOrders.map((order, orderIndex) => (
                  <div key={orderIndex} className="order-container">
                    {order.items.map((item, itemIndex) => {
                      const basePrice =
                        item.discountedPrice !== null && item.discountedPrice !== 0 ? item.discountedPrice : item.price;

                      // Calculate the total price by considering quantity and addons
                      const totalPrice =
                        (basePrice + (item.addOns?.reduce((acc, addon) => acc + addon.price, 0) || 0)) * item.quantity;
                      return (
                        <div
                          key={itemIndex}
                          className="flex-row space-between"
                          style={{ paddingBottom: 10, alignItems: 'flex-start' }}
                        >
                          <div className="col">
                            <div>
                              {item.quantity}x {item.name}
                            </div>
                            {item.addOns?.map((a, index) => (
                              <div key={index}>- {a.name}</div>
                            ))}
                          </div>
                          <div style={{ whiteSpace: 'nowrap' }}>$ {totalPrice.toFixed(2)}</div>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <ChargesSection totalPrice={Number(discountedPriceSum.toFixed(2))} />
              {spot && spot.type === 0 && (
                <div className="flex-row space-between pt-23 price-text">
                  <div style={{ whiteSpace: 'nowrap' }}>{t('payment_method')}</div>
                  {/* <div>{t('payment_method')}</div> */}

                  <div style={{ textAlign: 'right' }}>{PaymentType[paymentMethod as keyof typeof PaymentType]}</div>
                </div>
              )}

              <div className="mt-33">
                {/* <img width={'100%'} src="/assets/images/ad.svg" alt="" /> Temporarily Removed */}
                <div
                  onClick={() => clickMakeOtherOrder()}
                  className="btn primary mt-35"
                  style={{
                    backgroundColor: brandColor,
                    borderColor: brandColor,
                    boxShadow: `0px 4px 4px ${brandColor}40`,
                  }}
                >
                  {t('place_another_order')}
                </div>
                <div className="flex-col pt-23">
                  <DownloadReceipt />
                </div>
              </div>
            </section>
          </div>
          <div style={{ marginTop: '20px', paddingBottom: '20px' }}>
            <FooterLogo />
          </div>
        </div>
      </div>
    </>
  );
};
