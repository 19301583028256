import { MyImages } from 'cores/components';
import DiscountPrice, { calculateDiscountedPrice } from 'cores/components/DiscountPrice';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { find, get, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { OutletMenuItem } from '../../../interfaces/models/menu';
import { RootState } from '../../../store/configureStore';
import { QuantitySmall } from 'cores/components/Quantity/indexSmall';
import { getEditItemsFromCart } from 'helper/cart';
import { CART_UPDATE_QUANTITY } from 'constants/carts';
import { t } from 'i18next';
import { BadgePromotion } from './badgePromotion';
import { updateMenuDetails } from 'services/menu';

type TypeProps = {
  item: OutletMenuItem;
  index?: number;
};

const ThumbnailLandscapeNoImage = (props: TypeProps) => {
  const { index } = props;
  const { items, price } = useAppSelector((state: RootState) => state.carts);
  const { detail } = useAppSelector((state) => state.organization);
  const [sQuantity, setSQuantity] = useState(0);
  const { search } = useLocation();
  const { id, image, rating, name, description, isOutOfStock, basicPrice, promotion, stockExpectedDateTime } =
    props.item;
  const dispatch = useAppDispatch();
  const profileUrl = get(detail, ['image', 'imageUrl'], '');
  const imageUrl = get(image, ['imageUrl'], '');
  const [isProductOutOfstock, setIsProductOutOfstock] = useState(false);
  let targetIndex = items.findIndex((item) => item.menuId === id);
  useEffect(() => {
    findIfMenuIsCart();
    checkIfItemIsOutOfStock();
  }, [sQuantity, price, items]);
  useEffect(() => {}, [sQuantity]);

  function findIfMenuIsCart(): void {
    const item = find(items, { menuId: id });
    if (item && !isEmpty(item)) {
      setSQuantity(item.quantity);
    } else {
      setSQuantity(0);
    }
  }
  function quantityChanged(quantity: number) {
    const payload = { menuId: id, quantity, index: targetIndex };
    setSQuantity(quantity);
    dispatch({ type: CART_UPDATE_QUANTITY, payload });
  }
  function checkIfItemIsOutOfStock() {
    const dateAndTimeNow = new Date();
    if (isOutOfStock) {
      if (stockExpectedDateTime !== null && new Date(stockExpectedDateTime) > dateAndTimeNow) {
        setIsProductOutOfstock(true);
      } else {
        setIsProductOutOfstock(false);
        const payload = {
          ...props.item,
          isOutOfStock: false,
          stockExpectedDateTime: null,
        };
        updateMenuDetails(payload);
      }
    }
  }
  return (
    <Link
      to={`menu/${id}${search}`}
      className="text-link"
      style={{ padding: '0px 5px', display: 'block', width: '100%' }}
    >
      <div
        className="thumbnail-landscape-wrapper "
        style={{
          position: 'relative',
          width: '100%',
          gridTemplateColumns: 'repeat(6, 1fr)',
          gap: '2px',
          minHeight: '100px',
        }}
      >
        {isProductOutOfstock && <div className={'not-available-overlay'}></div>}
        <div className="thumbnail-right" style={{ gridColumn: 'span 4' }}>
          <div>
            <p className="title paragraph-truncate" style={{ marginBottom: '5px' }}>
              {name}
            </p>
            <p className="description paragraph-truncate" style={{ WebkitLineClamp: '4', marginBottom: '0px' }}>
              {description}
            </p>
          </div>
        </div>
        {/* <div
          className="flex-row flex-start thumbnail-pricing"
          style={{
            justifyContent: 'center',
            gridColumn: 'span 1',
            display: 'flex',
            alignContent: 'flex-end',
            alignItems: 'flex-end',
            paddingBottom: '12px',
            fontWeight: '500',
          }}
        >
          <span style={{ marginBottom: '5px' }}>
            <div className="price">
              ${' '}
              {(
                calculateDiscountedPrice(
                  props.item?.promotion.value ?? 0,
                  props.item?.promotion.discountType ?? 0,
                  props.item.basicPrice
                ) || 0
              ).toFixed(2)}
            </div>
          </span> */}
        {/* {sQuantity > 0 && <QuantitySmall startedWith={sQuantity} size="small" onChange={quantityChanged} />}
          {isOutOfStock && <p className="not-available">{t('not_available')}</p>} */}
        {/* </div> */}

        {isProductOutOfstock || sQuantity > 0 ? (
          <div
            style={{
              gridColumn: 'span 2',
              display: 'flex',
              flexDirection: 'column',
              padding: '12px',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
              paddingRight: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                alignItems: 'flex-end',
              }}
            >
              {promotion !== null && promotion && (
                <BadgePromotion
                  promotion={{
                    type: promotion.discountType,
                    value: promotion.value,
                    price: basicPrice,
                    color: detail?.primaryColor ? detail.primaryColor : '#000000',
                    rating: rating.value,
                    isLandscapeNoImage: true,
                  }}
                />
              )}
            </div>
            {!isProductOutOfstock ? (
              <>{sQuantity > 0 && <QuantitySmall startedWith={sQuantity} size="small" onChange={quantityChanged} />}</>
            ) : (
              <span>{isProductOutOfstock && <p className="not-available">{t('not_available')}</p>}</span>
            )}
          </div>
        ) : (
          <>
            {props.item?.promotion && (
              <div
                style={{
                  gridColumn: 'span 1',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '12px',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                  paddingRight: '0px',
                  paddingLeft: '0px',
                  minWidth: '46px',
                }}
              >
                <span
                  style={{
                    fontWeight: '500',
                  }}
                >
                  <div className="price">
                    ${' '}
                    {(
                      calculateDiscountedPrice(
                        props.item?.promotion.value ?? 0,
                        props.item?.promotion.discountType ?? 0,
                        props.item.basicPrice
                      ) || 0
                    ).toFixed(2)}
                  </div>
                </span>
              </div>
            )}

            <div
              style={{
                gridColumn: `${props.item?.promotion ? 'span 1' : ' span 2'} `,
                display: 'flex',
                flexDirection: 'column',
                padding: '12px',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  alignItems: 'flex-end',
                }}
              >
                {promotion !== null && promotion && (
                  <BadgePromotion
                    promotion={{
                      type: promotion.discountType,
                      value: promotion.value,
                      price: basicPrice,
                      color: detail?.primaryColor ? detail.primaryColor : '#000000',
                      rating: rating.value,
                      isLandscapeNoImage: true,
                    }}
                  />
                )}
              </div>
              <span
                style={{
                  textDecoration: `${props.item?.promotion ? 'line-through' : 'none'}`,
                  color: `${props.item?.promotion && 'darkgray'}`,
                  fontWeight: '500',
                }}
              >
                <div className="price">$ {props.item.basicPrice.toFixed(2)}</div>
              </span>
            </div>
          </>
        )}
      </div>
    </Link>
  );
};

export default ThumbnailLandscapeNoImage;
