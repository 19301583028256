import { PayloadAction } from '@reduxjs/toolkit';
import { CREATE_ORDER_REQUEST_SUCCESS, ORDER_UPDATE_ITEM, ADD_PAID_ORDERS } from '../../constants/order';
import { CreateOrderResponse } from '../../interfaces/models/order';

type StateType = {
  list: CreateOrderResponse[];
  paidOrders: string[]; // Change the type to string[]
};

const initialState: StateType = {
  list: [],
  paidOrders: [], // Ensure paidOrders is initialized as an empty array
};

export default function orders(state: StateType = initialState, action: PayloadAction<CreateOrderResponse>): StateType {
  switch (action.type) {
    case CREATE_ORDER_REQUEST_SUCCESS:
      return {
        ...state,
        list: state.list.concat(action.payload),
      };
    case ORDER_UPDATE_ITEM:
      const updatedList = state.list.map((order) =>
        order.id === action.payload.id ? { ...order, ...action.payload } : order
      );

      return {
        ...state,
        list: updatedList,
      };
    case ADD_PAID_ORDERS:
      const getOrderId = action.payload.id;
      console.log('Adding orderId to paidOrders:', getOrderId);

      // Create a new Set with unique IDs
      const uniquePaidOrders = new Set(state.paidOrders);

      // Add the new id to the Set
      uniquePaidOrders.add(getOrderId);

      return {
        ...state,
        paidOrders: Array.from(uniquePaidOrders), // Convert the Set back to an array
      };

    default:
      console.log('Returning default state:', state);
      return state;
  }
}
