import { useAppSelector } from 'hooks/redux';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { InputComponent } from 'cores/components';
import { MagnifyingGlass, Oval } from 'react-loader-spinner';
import { IOutlets } from 'interfaces/models/outlets';
import { IDeliveryInfo } from 'interfaces/models/deliveryInfo';
import MapComponent from 'cores/components/Map';
import { Icon } from '@iconify/react';
import AddressAutoComplete from 'cores/components/AddressAutoComplete';
import { toast } from 'react-toastify';
import { handlePlaceSelectMap } from 'helper/map';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CART_UPDATE_DELIVERY_DISTANCE, CART_UPDATE_DELIVERY_FEE, CART_UPDATE_ITEM } from 'constants/carts';
import ReactGA from 'react-ga4';
import OpenStreetMap from 'cores/components/OpenStreetMap';
import { includes, set } from 'lodash';
import { LoadingMagnfyingGlass } from '../LoadingMagnfyingGlass';
interface TypeProps {
  isSubmit: boolean;
  onClosed: () => void;
  deliveryInfo: IDeliveryInfo;
  setDeliveryInfo: (value: IDeliveryInfo) => void;
  onSubmitDelivery: () => void;
  price: number;
  list: IOutlets[];
  isDeliveryEdit: boolean;
  setIsDeliveryEdit: (value: boolean) => void;
}

const DeliveryInformation = (props: TypeProps) => {
  const {
    onClosed,
    deliveryInfo,
    setDeliveryInfo,
    price,
    onSubmitDelivery,
    list,
    isSubmit,
    isDeliveryEdit,
    setIsDeliveryEdit,
  } = props;
  const [isLoading, setLoading] = useState(false);
  const refBottomSheet = useRef<HTMLDivElement>(null);
  const { detail } = useAppSelector((state) => state.organization);
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const [autocompleteOptions, setAutocompleteOptions] = useState<any[]>([]);
  const [clickedCoordinates, setClickedCoordinates] = useState<google.maps.LatLngLiteral | null>(null);
  const maximumDistanceInKilometers = list[0]?.minDistanceInKMToPlaceOrder;
  const [isError, setError] = useState(false);
  const [isLoadingMap, setLoadingMap] = useState(false);
  const [getLocation, setGetLocation] = useState(false);
  const [isShowMap, setIsShowMap] = useState(false);
  const { outletId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cartTotal = useAppSelector((state) => state.carts.price);
  const [isDistanceError, setIsDistanceError] = useState(true);
  const [pinLoading, setPinLoading] = useState(false);
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e: any) => {
    if (refBottomSheet.current && !refBottomSheet.current.contains(e.target)) {
      onClosed();
    }
  };
  useEffect(() => {
    localStorage.removeItem('orderedTime');
    const localDeliveryInfo = localStorage.getItem('deliveryInfo');
    if (localDeliveryInfo) {
      setClickedCoordinates({
        lat: JSON.parse(localDeliveryInfo).latitude,
        lng: JSON.parse(localDeliveryInfo).longitude,
      });
      setDeliveryInfo({
        ...deliveryInfo,
        firstName: JSON.parse(localDeliveryInfo).firstName,
        email: JSON.parse(localDeliveryInfo).email,
        phoneNumber: JSON.parse(localDeliveryInfo).phoneNumber,
        address: JSON.parse(localDeliveryInfo).address,
        deliveryCharge: JSON.parse(localDeliveryInfo).deliveryCharge,
        expectedDeliveryTime: JSON.parse(localDeliveryInfo).expectedDeliveryTime,
      });
      const selectedOption = {
        label: JSON.parse(localDeliveryInfo).address,
        x: JSON.parse(localDeliveryInfo).longitude,
        y: JSON.parse(localDeliveryInfo).latitude,
      };
      handlePlaceSelectMap(
        selectedOption,
        setClickedCoordinates,
        setDeliveryInfo,
        deliveryInfo,
        maximumDistanceInKilometers,
        list,
        cartTotal,
        setIsDistanceError
      );
    }
  }, []);
  function onClickSubmitDeliveryOrder() {
    setLoading(true);
    ReactGA.event({
      category: `Created Delivery Details`,
      action: `Created Delivery Details`,
      label: `Created Delivery Details`, // optional
      // value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: 'xhr', // optional, beacon/xhr/image
    });
    localStorage.setItem('deliveryInfo', JSON.stringify(deliveryInfo));
    const deliveryChargesStr = localStorage.getItem('deliveryCharges');
    const deliveryDistanceStr = localStorage.getItem('deliveryDistance');
    const localDeliveryCharge = deliveryChargesStr ? parseFloat(deliveryChargesStr) : 0;
    const localDeliveryDistance = deliveryDistanceStr ? parseFloat(deliveryDistanceStr) : 0;
    if (
      deliveryInfo.firstName &&
      deliveryInfo.phoneNumber.length >= 8 &&
      deliveryInfo.address &&
      deliveryInfo.latitude &&
      deliveryInfo.latitude !== 0 &&
      deliveryInfo.longitude !== 0
    ) {
      if (onSubmitDelivery && isSubmit && !isDeliveryEdit) {
        onSubmitDelivery();
      } else {
        dispatch({
          type: CART_UPDATE_DELIVERY_DISTANCE,
          payload: { deliveryDistance: localDeliveryDistance },
        });
        dispatch({
          type: CART_UPDATE_DELIVERY_FEE,
          payload: { deliveryFee: localDeliveryCharge },
        });
        onSubmitDelivery();
        onClosed();
      }
      setLoading(false);
    } else {
      toast.dismiss();
      toast.warn('Please fill in all required fields');
      setLoading(false);
    }
  }

  function validateEmail(email: string) {
    // var re = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    var re = /^[\w%\+\-]+(\.[\w%\+\-]+)*@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    if (!email) return true;
    else return re.test(email);
  }
  const handlePlaceSelect = (selectedOption: string) => {
    handlePlaceSelectMap(
      selectedOption,
      setClickedCoordinates,
      setDeliveryInfo,
      deliveryInfo,
      maximumDistanceInKilometers,
      list,
      cartTotal,
      setIsDistanceError
    );
  };
  const handleOptionClick = (option: string) => {
    // setDeliveryInfo({ ...deliveryInfo, address: option });
    setAutocompleteOptions([]);
    handlePlaceSelect(option);
  };

  function LoadingSpinner() {
    return (
      <Oval
        height={20}
        width={20}
        color="#FFFF"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#FFB26B"
        strokeWidth={5}
        strokeWidthSecondary={5}
      />
    );
  }
  useEffect(() => {
    if (isSubmit) console.log('CHANGED!!!!');
  }, [deliveryInfo]);
  return (
    <>
      <div className="bottom-sheet-wrapper">
        <div className="bottom-sheet-content padding" ref={refBottomSheet}>
          <div className="navigation-wrapper pickup-information">
            <div className="title mb-25">{t('delivery_contact_information')}</div>
            <div className="pickup-information-item row-item">
              <div className="flex-row space-between">
                <div className="label">
                  {t('full_name')} <span className="error">*</span>
                </div>
              </div>
              <InputComponent
                value={deliveryInfo.firstName}
                // name="fullname"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setDeliveryInfo({ ...deliveryInfo, firstName: e.target.value })
                }
                placeholder="John"
              />
            </div>
            <div className="pickup-information-item row-item">
              <div className=" flex-row space-between">
                <div className="label">
                  {t('your_address')} <span className="error">*</span>
                </div>
              </div>
              <div className="address-input-container">
                <div className="address-input">
                  <AddressAutoComplete
                    deliveryInfo={deliveryInfo}
                    setDeliveryInfo={setDeliveryInfo}
                    setAutocompleteOptions={setAutocompleteOptions}
                    brandColor={`${brandColor}`}
                    setIsLoading={setLoadingMap}
                    isLoading={isLoadingMap}
                    isDistanceError={isDistanceError}
                  />
                </div>
                <div
                  className="icon-button"
                  style={{
                    backgroundColor: `${deliveryInfo.firstName !== '' ? brandColor : '##dfdfdf'}`,
                    boxShadow: `0px 4px 4px ${deliveryInfo.firstName !== '' ? brandColor : '##dfdfdf'}40`,
                    borderColor: brandColor,
                  }}
                  onClick={() => {
                    if (deliveryInfo.firstName) {
                      setIsShowMap(!isShowMap);
                      if (!isShowMap) {
                        setPinLoading(true);
                      }
                    }
                  }}
                >
                  {pinLoading ? <LoadingSpinner /> : <Icon icon="mingcute:location-3-line" width={30} color="white" />}
                </div>
              </div>
              {!isError ? (
                <>
                  {isShowMap && (
                    <>
                      <OpenStreetMap
                        coordinates={clickedCoordinates}
                        setDeliveryInfo={setDeliveryInfo}
                        deliveryInfo={deliveryInfo}
                        setCoordinates={setClickedCoordinates}
                        setError={setError}
                        price={cartTotal}
                        setIsDistanceError={setIsDistanceError}
                        setPinLoading={(value: boolean) => setPinLoading(value)}
                      />
                    </>
                  )}
                  {isLoadingMap ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <LoadingMagnfyingGlass brandColor={brandColor} />
                    </div>
                  ) : (
                    <>
                      {autocompleteOptions.length > 0 && (
                        <>
                          <div className="autocomplete-list">
                            {autocompleteOptions.map((option, index) => {
                              if (index < 5)
                                return (
                                  <div
                                    key={index}
                                    className="autocomplete-option fade-in-bottom"
                                    onClick={() => handleOptionClick(option)}
                                  >
                                    {option.label}
                                  </div>
                                );
                            })}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <p
                  style={{
                    color: '#828587',
                    textAlign: 'center',
                    fontSize: '0.9rem',
                    paddingTop: '0.5rem',
                  }}
                >
                  Error fetching OpenStreetMaps API. Please try again later.
                </p>
              )}
            </div>
            <div className="pickup-information-item row-item">
              <div className="flex-row space-between">
                <div className="label">
                  {t('mobile_number')} <span className="error">*</span>{' '}
                  {deliveryInfo.phoneNumber.length != 0 && deliveryInfo.phoneNumber.length < 8 ? (
                    <span className="error">({t('enter_valid_number')})</span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="flex-row space-between">
                <div className="phone-country-code">
                  <InputComponent
                    value=""
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setDeliveryInfo({ ...deliveryInfo, phoneNumber: e.target.value })
                    }
                    placeholder="(855)"
                    disabled={true}
                  />
                </div>

                <InputComponent
                  // name="phoneNumber"
                  value={deliveryInfo.phoneNumber}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    e.target.value.length <= 9 ? setDeliveryInfo({ ...deliveryInfo, phoneNumber: e.target.value }) : ''
                  }
                  placeholder="12 123 123"
                  type="number"
                />
              </div>
            </div>
            <div className="pickup-information-item row-item">
              <div className=" flex-row space-between">
                <div className="label">
                  {t('email_optional')}
                  {deliveryInfo.email.length > 0 && !validateEmail(deliveryInfo.email) ? (
                    <span className="error"> ({t('enter_valid_email')})</span>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <InputComponent
                // name="email"
                value={deliveryInfo.email}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setDeliveryInfo({ ...deliveryInfo, email: e.target.value })
                }
                placeholder="customer@example.com"
              />
            </div>
            <div className="pickup-information-item row-item">
              <div className=" flex-row space-between">
                <div className="label">{t('notes')}</div>
              </div>

              <InputComponent
                value={deliveryInfo.notes}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setDeliveryInfo({ ...deliveryInfo, notes: e.target.value })
                }
                placeholder="Notes"
              />
            </div>

            <div
              className="btn primary pd-12 flex-row space-between submit-order-pickup"
              onClick={() => onClickSubmitDeliveryOrder()}
              style={{
                backgroundColor: brandColor,
                boxShadow: `0px 4px 4px ${brandColor}40`,
                borderColor: brandColor,
                ...(!isSubmit ? { justifyContent: 'center' } : {}), // Conditionally add justifyContent
              }}
            >
              <div className="flex-row center column-gap-10">
                <span>{isSubmit ? t('submit_order') : t('save_changes')}</span>
              </div>
              {isSubmit && (
                <div className="flex-row column-gap-16">
                  <div>$ {price.toFixed(2)}</div>

                  {!isLoading && <img src="/assets/icons/next-white.svg" alt="" />}
                  {isLoading && <LoadingSpinner />}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveryInformation;
