import React, { CSSProperties, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import default styles
import { hexToRgba } from 'helper/rgba';
import { HeaderImages } from 'interfaces/models/outlets';

interface ImageSliderProps {
  images: HeaderImages[];
  brandColor?: string;
}

const ImageSlider: React.FC<ImageSliderProps> = ({ images, brandColor }) => {
  const rgbaColor = brandColor ? hexToRgba(brandColor, 0.5) : 'rgba(0, 0, 0, 0.5)';

  const onErrorLoadingImage = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = '/assets/icons/no-images.svg';
  };

  const arrowStyles: CSSProperties = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: rgbaColor,
    border: 'none',
    borderColor: 'transparent',
    borderRadius: '50%',
  };

  const [autoPlay, setAutoPlay] = useState(true);

  return (
    <div>
      <style>
        {`
          .custom-carousel .control-dots {
            bottom: 20px; /* Adjust this value to move the indicators higher or lower */
          }

          .custom-carousel .control-dots .dot {
            background: #fff9;
            width: 10px;
            height: 10px;
            margin: 0 8px;
            border-radius: 50%;
          }
        `}
      </style>
      <Carousel
        axis="horizontal"
        autoPlay={autoPlay}
        infiniteLoop={true}
        swipeable={true}
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        showIndicators={true}
        onSwipeStart={() => setAutoPlay(false)}
        className="custom-carousel"
      >
        {images.map((image: any, index: number) => (
          <div className="restaurant-wallpaper" style={{ height: '200px' }} key={index}>
            <img
              className="wallpaper"
              loading="lazy"
              src={image.image.imageUrl}
              onError={onErrorLoadingImage}
              style={{ transition: 'opacity 1s ease-in-out' }}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ImageSlider;
