import { POST, PUT } from 'generics/base-service';
import { CreateOrderResponse, OrderItem } from 'interfaces/models/order';

type CreateParam = {
  spotId: string;
  outletId: string;
  body: OrderItem;
};

type UpdateParam = {
  orderid: string;
  body: {
    paymentMethod: {
      name: string;
      accountName: string;
      accountNumber: string;
      chargePercentage: number;
    };
    paymentStatus: string;
  };
};

export function createOrderByMenuBySpotId(params: CreateParam): Promise<CreateOrderResponse> {
  const { spotId, outletId, body } = params;
  const url = `/api/v1/Order/${outletId}/${spotId}`;

  return POST<CreateOrderResponse>(url, body);
}
export function updateOrderPaymentDetails(params: UpdateParam): Promise<CreateOrderResponse> {
  const { orderid, body } = params;
  const url = `/api/v1/Order/${orderid}/updatepayment`;

  return PUT<CreateOrderResponse>(url, body);
}
