import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { t } from 'i18next';
import { displayEstimationRange } from 'helper/date';

type ImagesType = {
  [key: string]: string;
};

const images: ImagesType = {
  grocery: '/assets/images/grocery.gif',
  stew: '/assets/images/stew.gif',
  paperBag: '/assets/images/paper-bag.gif',
  scooter: '/assets/images/scooter.gif',
  delivered: '/assets/images/delivered.gif',
};

interface CountDownType {
  deliveryTime: string;
  createdTime: string;
}

const DeliveryCountDownImages = (props: CountDownType) => {
  const [range, setRange] = useState('');
  const orderTime = localStorage.getItem('orderedTime');
  const [intialTime, setInitialTime] = useState(0);
  const time = moment(props.deliveryTime).diff(moment(), 'minutes');
  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = moment();
      const timeDifference = moment(props.deliveryTime).diff(currentTime, 'minutes');

      setInitialTime(timeDifference);

      if (orderTime === null) {
        localStorage.setItem('orderedTime', timeDifference.toString());
      }

      const orderTimeDiff = parseInt(orderTime || '20', 10);
      const percentage = Math.floor(((orderTimeDiff - timeDifference) / orderTimeDiff) * 100);
      console.log('🚀 ~ interval ~ percentage:', percentage);

      if (percentage >= 100) setRange('delivered');
      else if (percentage >= 60) setRange('scooter');
      else if (percentage >= 50 && percentage < 60) setRange('paperBag');
      else if (percentage >= 20 && percentage < 50) setRange('stew');
      else if (percentage < 20) setRange('grocery');
    }, 1000);

    return () => clearInterval(interval);
  }, [props.deliveryTime, orderTime]);

  const imageToShow = images[range as keyof ImagesType];
  let orderStatus = 'Preparing your order...';

  if (range === 'delivered') {
    orderStatus = 'your_order_delivered';
  } else if (range === 'scooter') {
    orderStatus = 'your_order_on_the_way';
  } else if (range === 'paperBag') {
    orderStatus = 'waiting_order_picked_up';
  } else if (range === 'stew') {
    orderStatus = 'preparing_your_order';
  }

  return (
    <>
      {intialTime > 0 && (
        <p
          style={{
            fontSize: '0.9rem',
            color: '#7C7C7C',
            paddingTop: '1vh',
          }}
        >
          {t('estimated_delivery_time')}
        </p>
      )}
      <p
        style={{
          fontSize: '1.5rem',
          fontWeight: '450',
        }}
      >
        {intialTime > 0 && displayEstimationRange(intialTime)}
      </p>
      <img width="100vw" src={imageToShow} />
      <p
        style={{
          fontSize: '0.9rem',
          color: '#7C7C7C',
          paddingTop: '1vh',
          textAlign: 'center',
        }}
      >
        {t(orderStatus)}
      </p>
    </>
  );
};

export default DeliveryCountDownImages;
