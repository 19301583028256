import { useAppSelector } from 'hooks/redux';
import { OutletMenuItem } from 'interfaces/models/menu';
import _ from 'lodash';
import { createRef, useEffect, useRef } from 'react';
import ThumbnailHorizontal from './thumbnail-horizontal';

interface GroupedPromotionsProps {
  categoryDisplayed: (categoryName: string) => void;
}
const GroupedPromotions: React.FC<GroupedPromotionsProps> = ({ categoryDisplayed }) => {
  const promotionGroup = useAppSelector((state) => state.menu.list);
  const groupMenus = _.groupBy(promotionGroup, 'promotion.name');
  const { category } = useAppSelector((state) => state.filter);

  const promoRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});

  Object.keys(groupMenus).forEach((key) => {
    if (!promoRefs.current[key]) {
      promoRefs.current[key] = createRef();
    }
  });

  const discountType = (type: number, value: number) => {
    switch (type) {
      case 0:
        return value + '% OFF - ';
      case 1:
        return '$' + value + ' OFF - ';
      default:
        return '';
    }
  };

  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      const element = ref.current;
      const elementRect = element.getBoundingClientRect();
      const absoluteY = elementRect.top + window.scrollY;
      window.scrollTo({
        top: absoluteY - window.innerHeight / 4.2,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (category.length > 0) {
      const categoryToScroll = category[0];
      if (promoRefs.current[categoryToScroll]) {
        scrollToSection(promoRefs.current[categoryToScroll]);
      }
    }
  }, [category]);

  const handleScroll = () => {
    const viewportCenter = window.scrollY + window.innerHeight / 5;

    Object.keys(promoRefs.current).forEach((key) => {
      const categoryRef = promoRefs.current[key];
      if (categoryRef && categoryRef.current) {
        const categoryRect = categoryRef.current.getBoundingClientRect();
        const categoryTop = categoryRect.top + window.scrollY;
        const categoryBottom = categoryRect.bottom + window.scrollY;

        if (categoryTop <= viewportCenter && categoryBottom >= viewportCenter) {
          categoryDisplayed(key);
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [promoRefs]);

  return (
    <div className="menu-content-promo">
      <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        {Object.keys(groupMenus).map((key, index: number) => {
          const array = groupMenus[key];
          if (array.length && key !== 'undefined')
            return (
              <div key={index} ref={promoRefs.current[key]}>
                <div className="all-time-filter" key={index}>
                  <div className="text-all-time-category">
                    <span style={{ whiteSpace: 'nowrap' }}>
                      {discountType(
                        array[0].promotion && array[0].promotion.discountType,
                        array[0].promotion && array[0].promotion.value
                      )}
                    </span>
                    {key}
                  </div>
                </div>
                <div className="item thumbnail-horizontal-flex">
                  {groupMenus &&
                    groupMenus[key].map((item: OutletMenuItem, index: number) => (
                      <div key={index}>{<ThumbnailHorizontal key={item.outletId} item={item} />}</div>
                    ))}
                </div>
              </div>
            );
        })}
      </div>
    </div>
  );
};

export default GroupedPromotions;
