import _, { capitalize, isEmpty, xor } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { FILTER_CHANGE_ON_CATEGORY } from '../../../constants/Filter';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux/index';
import { RootState } from '../../../store/configureStore';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { SPOT_QRTABLE } from 'constants/spot';
import CategoryDrawer from './category-drawer';
import { IGroupMenu, OutletMenuItem } from 'interfaces/models/menu';

type Props = {
  setCategory: (param: string) => void;
  setOpenCategoryDrawer: (param: boolean) => void;
  // setSelectedTab: (param: string) => void;
  selectedCategory: string;
  selectedTab: string;
  spot: any;
  grouped: IGroupMenu[];
  groupMenus: any;
};
const FilterCategorySelect: React.FunctionComponent<Props> = ({
  setCategory,
  selectedCategory,
  selectedTab,
  spot,
  setOpenCategoryDrawer,
  grouped,
  groupMenus,
}) => {
  console.log('🚀 ~ selectedCategory:', groupMenus);
  const promotionGroup = useAppSelector((state) => state.menu.list);
  const promotions = _.groupBy(promotionGroup, 'promotion.name');
  // const grouped = useAppSelector((state: RootState) => state.menu.grouped);
  const { detail } = useAppSelector((state) => state.organization);
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const categoryListRef = useRef<HTMLDivElement | null>(null); // Ref for the category list container
  const { t } = useTranslation();
  useEffect(() => {
    if (categoryListRef.current) {
      const selectedCategoryElement = categoryListRef.current.querySelector(`[data-category="${selectedCategory}"]`);
      if (selectedCategoryElement instanceof HTMLElement) {
        const scrollLeft =
          selectedCategoryElement.offsetLeft -
          (categoryListRef.current.clientWidth - selectedCategoryElement.clientWidth) / 2;
        categoryListRef.current.scrollTo({
          left: scrollLeft,
          behavior: 'smooth',
        });
      }
    }
  }, [selectedCategory]);

  const promotionNameWithDiscount = (selectedCategory: string) => {
    const promotion = promotions[selectedCategory];
    if (promotion && promotion.length > 0 && selectedCategory) {
      return promotion[0].promotion.discountType === 0
        ? `${promotion[0].promotion.value}% OFF - ${promotion[0].promotion.name}`
        : `$${promotion[0].promotion.value} OFF - ${promotion[0].promotion.name}`;
    }
    return t(selectedCategory);
  };
  return (
    <>
      <div className="filter-list" ref={categoryListRef} style={{ width: '100%' }}>
        {/* {groupMenus && groupMenus[0] && (
              <p
                onClick={() => setCategory('special_deals')}
                style={{
                  color: selectedCategory === 'special_deals' ? brandColor : '#a9a9a9',
                }}
                data-category="special_deals"
              >
                Special deals
              </p>
            )} */}
        <button
          onClick={() => setOpenCategoryDrawer(true)}
          style={{
            background: 'none',
            border: '1px solid #e0e0e0',
            width: '100%',
            padding: '10px',
            borderRadius: '10px',
            fontSize: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              color: 'black',
            }}
          >
            {promotionNameWithDiscount(selectedCategory)}
          </span>
          <Icon icon="lucide:chevron-down" width={26} color="black" />
        </button>

        {/* {grouped.map((item, index) => (
              <>
                {item.category !== 'Previously Ordered - ធ្លាប់បញ្ជាទិញពីមុន' && (
                  <>
                    <div
                      style={{
                        padding: '10px',
                        borderBottom: selectedCategory === item.category ? '5px solid ' + brandColor : 'none',
                      }}
                    >
                      <p
                        key={index}
                        onClick={() => setCategory(item.category)}
                        style={{
                          color: selectedCategory === item.category ? brandColor : '#a9a9a9',
                        }}
                        data-category={item.category}
                      >
                        {item.category}
                      </p>
                    </div>
                  </>
                )}
              </>
            ))} */}
      </div>
    </>
  );
};

export default FilterCategorySelect;
