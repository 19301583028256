import React, { useEffect, useRef, useState } from 'react';
import { IDeliveryInfo } from 'interfaces/models/deliveryInfo';
import { useAppSelector } from 'hooks/redux';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { useParams } from 'react-router-dom';
interface TypeProps {
  deliveryInfo: IDeliveryInfo;
  setDeliveryInfo: (value: IDeliveryInfo) => void;
  setAutocompleteOptions: (value: any[]) => void;
  brandColor: string;
  setIsLoading: (value: boolean) => void;
  isLoading: boolean;
  isDistanceError: boolean;
}

const AddressAutoComplete = (props: TypeProps) => {
  const {
    deliveryInfo,
    setDeliveryInfo,
    setAutocompleteOptions,
    brandColor,
    setIsLoading,
    isLoading,
    isDistanceError,
  } = props;
  const [searchQuery, setSearchQuery] = useState('');
  const [isError, setError] = useState(false);
  const { list } = useAppSelector((state) => state.outlets);
  const { outletId } = useParams();
  const outlet = list.find((i) => i.id === outletId);
  const location = outlet?.location;
  const outletLocation = location ? ` ${location.city}, ${location.country}` : '';
  const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleSearch = async (e: string) => {
    setError(false);
    setSearchQuery(e);
    try {
      const provider = new OpenStreetMapProvider();
      const results = await provider.search({ query: `${e + outletLocation}` });
      setAutocompleteOptions(results);
    } catch (error) {
      console.error('Error in handleSearch:', error);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSearchQuery(deliveryInfo.address);
  }, [deliveryInfo.address]);
  const handleInputChange = (value: string) => {
    setIsLoading(true);
    setSearchQuery(value);
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      handleSearch(value);
    }, 1000);
  };
  useEffect(() => {
    if (isDistanceError) {
      if (deliveryInfo.address && deliveryInfo.latitude !== 0 && deliveryInfo.longitude !== 0) {
        setSearchQuery(deliveryInfo.address);
      } else {
        setSearchQuery('');
      }
    } else {
      setSearchQuery(deliveryInfo.address);
    }
  }, [isDistanceError]);
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row',
          gap: '0.5rem',
          width: '100%',
        }}
      >
        <input
          disabled={deliveryInfo.firstName === ''}
          id="autocomplete-input"
          value={searchQuery}
          className="input"
          placeholder="Enter your address"
          style={{ boxSizing: `border-box`, width: `100%` }}
          autoComplete="off"
          onChange={(e) => handleInputChange(e.target.value)}
        />
        {/* {isLoading ? (
          <LoadingSpinner brandColor={brandColor} />
        ) : (
          <div
            onClick={() => handleSearch(searchQuery)}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: brandColor,
              boxShadow: `0px 4px 4px ${brandColor}40`,
              border: `1px solid ${brandColor}`,
              borderRadius: '0.5rem',
              padding: '0.5rem',
              cursor: 'pointer',
            }}
          >
            <Icon icon="ph:magnifying-glass" width={30} color="white" />
          </div>
        )} */}
      </div>
    </>
  );
};

export default AddressAutoComplete;
