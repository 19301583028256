import { Icon } from '@iconify/react';
import { MyImages } from 'cores/components';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import 'styles/components/_location.scss';
type TypeProps = {
  outletDetails: any;
  closedWifi: () => void;
};
const WifiDetails = (props: TypeProps) => {
  const outletDetails = props.outletDetails;
  const wifi = outletDetails.wiFis && outletDetails.wiFis.length > 0 && outletDetails.wiFis[0];
  const [messageID, setMessageID] = useState('');
  const [countdown, setCountdown] = useState(999999);
  const copyToClipboard = (text: string) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };
  const modalRef = useRef<HTMLDivElement>(null);
  const handleCopyWifiName = () => {
    if (wifi) {
      copyToClipboard(wifi.name);
      setMessageID('wifi_name_copied');
    }
  };

  const handleCopyWifiPassword = () => {
    if (wifi) {
      copyToClipboard(wifi.password);
      setMessageID('wifi_password_copied');
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        props.closedWifi();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    const intervalId = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    if (countdown === 0) {
      clearInterval(intervalId);
      props.closedWifi();
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('mousedown', handleClickOutside);
      clearInterval(intervalId);
    };
  }, [countdown, props]);
  return (
    <div className="location-background">
      <div className="location-container" ref={modalRef}>
        <div className="body">
          <div className="content">
            <MyImages width="80px" src="/assets/icons/hotspot.gif" />
            <p className="title">{t('free_wifi')}</p>
            <p className="sub-title">{t('heres_our_password')}</p>
            <div
              className="sub-title"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                fontSize: '12px',
                margin: '-10px',
                color: '#ff9b00',
              }}
            >
              {t(messageID)}
            </div>
            <div className="wifi-details">
              <span className="sub-title">
                {t('wifi')}: {wifi.name}
              </span>
              <Icon icon="bi:copy" className="icon" color="#545454" width={20} onClick={handleCopyWifiName} />
            </div>

            <div className="wifi-details">
              <span className="sub-title">
                {t('password')}: {wifi.password}
              </span>
              <Icon icon="bi:copy" className="icon" color="#545454" width={20} onClick={handleCopyWifiPassword} />
            </div>
          </div>
        </div>
        <div className="footer" onClick={() => props.closedWifi()}>
          <button>{t('close')}</button>
        </div>
      </div>
    </div>
  );
};

export default WifiDetails;
