import { Icon } from '@iconify/react';
import { MyImages } from 'cores/components';
import { getSelectorByKey } from 'helper/selector';
import { useAppSelector } from 'hooks/redux';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'styles/components/_location.scss';
type TOutletIsBusy = {
  setIsOutletBusy: (param: boolean) => void;
};
const OutletBusyDialog = ({ setIsOutletBusy }: TOutletIsBusy) => {
  const { outletId } = useParams();
  const { list } = getSelectorByKey('outlets');
  const filteredList = list.filter((item) => item.id === outletId);
  const estimatedTime =
    filteredList[0]?.estimatedPreparationTime !== null ? filteredList[0]?.estimatedPreparationTime : null;
  return (
    <div className="location-background" style={{ zIndex: '1' }}>
      <div className="location-container">
        <div className="body">
          <div className="content">
            <MyImages width="80px" src="/assets/icons/cooking.png" />
            <p className="title">Quick Reminder!</p>
            <p className="sub-title">
              The business is experiencing a large amount of orders. Your order may be delayed and will take{' '}
              {estimatedTime && estimatedTime?.hours > 0 && estimatedTime?.hours}{' '}
              {estimatedTime && estimatedTime?.hours > 0 ? (estimatedTime?.hours > 1 ? 'hours' : 'hour') : ''}{' '}
              {estimatedTime && estimatedTime?.hours > 0 && estimatedTime?.minutes > 0 && ' and '}{' '}
              {estimatedTime && estimatedTime.minutes > 0 && estimatedTime.minutes + ' minutes'}.
            </p>
            <div
              className="sub-title"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                fontSize: '12px',
                margin: '-10px',
                color: '#ff9b00',
              }}
            ></div>
          </div>
        </div>
        <div className="footer">
          <button onClick={() => setIsOutletBusy(false)}>{t('close')}</button>
        </div>
      </div>
    </div>
  );
};

export default OutletBusyDialog;
